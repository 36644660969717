import React, { useState, useEffect, useRef } from "react";
import {
  Backdrop,
  CircularProgress,
  Button,
  Breadcrumbs,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import AADHeader from "./components/AADHeader.js";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { BiSolidCalendar } from "react-icons/bi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IoCloudUploadOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import "./table.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import dayjs from "dayjs";
import Papa from "papaparse"; // For parsing CSV files
import * as XLSX from "xlsx"; // For parsing Excel files

const StartNewSurvey = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fromDate: null,
    toDate: null,
    emailAddresses: "",
  });
  const companyid = localStorage.getItem("companyid");
  const [errors, setErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [isToDateEnabled, setIsToDateEnabled] = useState(false);
  const [minToDate, setMinToDate] = useState(null);
  const [emailContent, setEmailContent] = useState(
    "We hope this email finds you well.\n\nAs part of our commitment to sustainability and reducing our carbon footprint, we are conducting a survey to better understand the commuting patterns of our employees. The data collected will help us estimate the emissions generated by employee commutes and identify opportunities to support more sustainable travel options.\n\nPlease take a few minutes to complete the survey linked below. The survey will ask questions about your usual mode of transportation to and from work, the distance you travel, frequency of your commutes, and some information about working from home. Rest assured, all responses will be kept confidential and will only be used for the purpose of calculating commute-related emissions.\n"
  );
  const [email, setEmail] = useState(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const renderHTMLContent = () => {
    return { __html: emailContent };
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFileChange = (e) => {
    console.log(e);
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension === "csv") {
      // Parse CSV
      Papa.parse(file, {
        complete: (results) => {
          const emailColumn = results.data.map((row) => row[0]); // Assuming emails are in the first column
          console.log(emailColumn);
          setFormData({ ...formData, emailAddresses: emailColumn });
          setEmail(emailColumn);
        },
        header: false, // Set to true if your CSV has a header row
      });
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      // Parse Excel
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const emailColumn = jsonData.map((row) => row[0]); // Assuming emails are in the first column
        console.log(emailColumn);
        setFormData({ ...formData, emailAddresses: emailColumn });
        setEmail(emailColumn);
      };
      reader.readAsArrayBuffer(file);
    } else {
      console.error("Unsupported file type");
      setErrors({ ...errors, file: "Invalid file type. Please upload a CSV or Excel file." });
    }
  };

  const handleIconClick = () => {
    // Programmatically click the hidden file input when the icon is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const handleDateChange = (date, setter) => {
    if (!date) {
      return; // Exit early if date is null or undefined
    }
    if (setter === setFromDate) {
      if (toDate && date > toDate) {
        setToDate(null);
      }
      setFromDate(date);
      setIsToDateEnabled(true);
      setMinToDate(date);
    } else if (setter === setToDate) {
      setToDate(date);
    } else if (setter === setExpiryDate) {
      setExpiryDate(date);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    // if (!surveyName) formErrors.surveyName = "Survey Name is required";
    if (!fromDate) formErrors.fromDate = "From Date is required";
    if (!toDate) formErrors.toDate = "To Date is required";
    if (!expiryDate) formErrors.expiryDate = "Survey Expiry Date is required";
    // ... other validations
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const validateEmailAddresses = () => {
    const newErrors = {};
    if (!formData.emailAddresses) newErrors.emailAddresses = "Email address is required";

    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    const newSurvey = {
      // surveyName: formData.surveyName,
      fromDate: fromDate,
      toDate: toDate,
      expiryDate: expiryDate,
      // ... other fields
    };

    const existingSurveys = JSON.parse(localStorage.getItem("savedSurveys")) || [];
    const updatedSurveys = [...existingSurveys, newSurvey];
    localStorage.setItem("savedSurveys", JSON.stringify(updatedSurveys));

    setFormData({
      fromDate: "",
      toDate: "",
      // ... other fields
    });

    setFromDate(null);
    setToDate(null);
    // navigate("/nextPage");
  };

  const createToken = (
    companyId,
    startDate,
    endDate,
    expirationDate,
    surveyStartDate,
    username
  ) => {
    // Step 1: Concatenate the data into a single string
    const dataString = `${companyId}:${startDate}:${endDate}:${expirationDate}:${surveyStartDate}:${username}`;

    // Step 2: Optionally encode the data string using Base64 encoding
    const token = btoa(dataString); // Base64 encoding

    // Step 3: Return or use the token
    return token;
  };

  const handleShare = async () => {
    if (!validateForm()) return;
    const newErrors = validateEmailAddresses();
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    // Assume fromDate is your date input
    // Format the date using dayjs
    const formattedFromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedToDate = dayjs(toDate).format("YYYY-MM-DD");
    const formattedExpiryDate = dayjs(expiryDate).format("YYYY-MM-DD");
    const formattedExpiryDate1 = dayjs(expiryDate).format("DD-MM-YYYY");
    const useremail = localStorage.getItem("userName");

    const today = new Date();
    const formattedDate = dayjs(today).format("YYYY-MM-DD");
    const token = createToken(
      companyid,
      formattedFromDate,
      formattedToDate,
      formattedExpiryDate,
      formattedDate,
      useremail
    );
    console.log("Generated Token:", token);

    // You can store the token in localStorage or sessionStorage
    localStorage.setItem("linkToken", token);
    const link = `${process.env.REACT_APP_BASE_URL}Employee-Commute-Survey?token=${token}`;
    localStorage.setItem("linkUrl", link);

    const decodedString = atob(token);
    console.log("Decoded String:", decodedString);
    // Display the decoded data
    setIsShareModalOpen(false);

    // Wait for the modal to close
    let emailToField;

    // Check if email is a string or an array
    if (typeof email === "string") {
      //emailToField = [email]; // Convert string to array
      emailToField = email ? email.split(",").map((e) => e.trim()) : [];
    } else if (Array.isArray(email)) {
      emailToField = email; // Keep as array if it's already an array
    }
    let res = await fetch(`${process.env.REACT_APP_BASE_API_URL}saveSurvey`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        username: username,
      },
      body: JSON.stringify({
        companyId: companyid,
        token: token,
        surveyFromDate: formattedFromDate,
        surveyToDate: formattedToDate,
        expirationDate: formattedExpiryDate,
        emailContent: emailContent,
        emailSubject: "Please Complete the Employee Commute Survey by " + formattedExpiryDate1,
        emailFrom: "no-reply@ecoprism.com",
        emailTo: emailToField,
        surveyLink: link,
      }),
    });
    //let resJson = await res.json();
    if (res.status === 200) {
      const responseJson = await res.json();
      if (responseJson.success === false) {
        setLoading(false);
        console.log(responseJson);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
    <p style="font-size: 16px;">${responseJson.data}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else if (responseJson.status === 400) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
               <p style="font-size: 16px;">${responseJson.title}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else {
        await new Promise((resolve) => setTimeout(resolve, 100));
        Swal.fire({
          icon: "success",
          html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">The survey form has been shared successfully!</p>',
          iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          confirmButtonText: "Continue",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/employee-commute-data");
          }
        });
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
        <p style="font-size: 16px;">Error in creating site location</p>`,
        iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
      });
    }
    // Show success popup and redirect on confirmation
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handlePreviewClick = () => {
    setShowPreview(true);
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="row mb-4">
            <div className="col">
              <div className="d-flex flex-column steps__containt-hader">
                <h1 className="title">Employee Commute Survey</h1>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/forms-apps">
                    Forms & Apps
                  </Link>
                  <Link underline="hover" color="inherit" href="/employee-commute-data">
                    Employee Commute Survey
                  </Link>
                  <Typography color="text.primary">Start the survey</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row g-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="formlable" htmlFor="fromDate">
                      Survey Period
                    </label>
                    <div className="row g-4">
                      <div className="col-md-6">
                        <DatePicker
                          id="fromDate"
                          value={fromDate}
                          onChange={(date) => {
                            handleDateChange(date, setFromDate);
                            setErrors((prevErrors) => ({ ...prevErrors, fromDate: "" }));
                          }}
                          className={`calendar-form form-control ${
                            errors.fromDate ? "is-invalid" : ""
                          }`}
                          placeholder="From"
                          format="YYYY-MM-DD"
                          suffixIcon={<BiSolidCalendar />}
                        />
                        {errors.fromDate && (
                          <div className="invalid-feedback">{errors.fromDate}</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          id="toDate"
                          value={toDate}
                          onChange={(date) => {
                            handleDateChange(date, setToDate);
                            setErrors((prevErrors) => ({ ...prevErrors, toDate: "" }));
                          }}
                          className={`calendar-form form-control ${
                            errors.toDate ? "is-invalid" : ""
                          }`}
                          placeholder="To"
                          format="YYYY-MM-DD"
                          suffixIcon={<BiSolidCalendar />}
                          disabled={!isToDateEnabled}
                          disabledDate={(current) => current && current < fromDate}
                        />
                        {errors.toDate && <div className="invalid-feedback">{errors.toDate}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="formlable" htmlFor="toDate">
                      Survey Expiry Date
                    </label>
                    <DatePicker
                      id="expiryDate"
                      value={expiryDate}
                      onChange={(date) => {
                        handleDateChange(date, setExpiryDate);
                        setErrors((prevErrors) => ({ ...prevErrors, expiryDate: "" }));
                      }}
                      className={`calendar-form form-control ${
                        errors.expiryDate ? "is-invalid" : ""
                      }`}
                      placeholder="Select the survey expiry"
                      format="YYYY-MM-DD"
                      suffixIcon={<BiSolidCalendar />}
                      popupClassName="custom-expiry-calendar"
                    />
                    {errors.expiryDate && (
                      <div className="invalid-feedback">{errors.expiryDate}</div>
                    )}
                  </div>
                  <div className="text-end">
                    <Button
                      className={`preview-button ${showPreview ? "active-preview-button" : ""}`}
                      variant="text"
                      onClick={handlePreviewClick}
                    >
                      Preview form
                    </Button>
                  </div>
                </div>
                <div className="col-md-6">
                  {showPreview && (
                    <div style={{ border: "1px solid #ADA9A9", opacity: "1" }}>
                      <div className="zoom-in-container">
                        <img
                          src="./assets/img/formsapps/zoom-in.svg"
                          alt="Preview of the survey form"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={handleOpenModal}
                        />
                      </div>
                      <img
                        src="./assets/img/formsapps/ec-survey-form.png"
                        alt="Preview of the survey form"
                        className="survey-img"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="forms-buttons-container">
                <Button
                  className="outline-btn"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />
                  }
                  onClick={() => navigate("/employee-commute-data")}
                >
                  Back
                </Button>
                <Button
                  className="next-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (validateForm()) {
                      setIsShareModalOpen(true); // Show the new modal if the form is valid
                    }
                  }}
                >
                  Share
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal className="reports-modal" show={openModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <Modal.Title>Survey Form Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img
              src="./assets/img/formsapps/ec-survey-form.png"
              alt="Zoomed-in preview of the survey form"
              className="survey-img"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            className="outline-btn"
            size="medium"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="reports-modal share-modal"
        show={isShareModalOpen}
        onHide={() => setIsShareModalOpen(false)}
        centered
      >
        <Modal.Header className="justify-content-between">
          <Modal.Title>Share Survey Form</Modal.Title>
          <Button
            variant="link"
            className="close-modal-button"
            onClick={() => setIsShareModalOpen(false)}
            style={{ padding: "0", minWidth: "0" }}
          >
            <IoIosCloseCircleOutline style={{ color: "white", fontSize: "24px" }} />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label className="formlable" htmlFor="emailAddress">
                Email address
              </label>
              <div className="input-field">
                <input
                  type="text"
                  className={`form-control ${errors.emailAddresses ? "is-invalid" : ""}`}
                  id="emailAddress"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => {
                    setFormData({ ...formData, emailAddresses: e.target.value });
                    setEmail(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, emailAddresses: "" }));
                  }}
                />
                <div className="input-field-append" onClick={handleIconClick}>
                  <IoCloudUploadOutline className="upload-icon" />
                </div>
                {errors.emailAddresses && (
                  <div className="invalid-feedback">{errors.emailAddresses}</div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }} // Hide the input element
                  accept=".csv, .xlsx, .xls"
                  onChange={handleFileChange}
                />
              </div>
              <div className="file-format-text">Only XLSX and CSV format files</div>
            </div>

            <div className="form-group">
              <label className="formlable" htmlFor="emailContent">
                Email content
              </label>
              <textarea
                type="text"
                className="form-control"
                id="emailContent"
                onChange={(e) => setEmailContent(e.target.value)}
                placeholder=""
                value={emailContent}
                rows="6"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline-btn"
            variant="outlined"
            onClick={() => setIsShareModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="next-button"
            onClick={() => {
              handleShare();
            }}
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StartNewSurvey;
