import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button, Breadcrumbs, Typography, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import AADHeader from "./components/AADHeader.js";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Tooltip } from "@mui/material";
import { BlobServiceClient } from "@azure/storage-blob";
import "./table.css";

const RepositoryAddLink = () => {
  const navigate = useNavigate();
  const companyid = localStorage.getItem("companyid");
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    linkName: "",
    linkCategory: "",
    url: "",
    fileName: "",
    fileCategory: "",
  });
  const [errors, setErrors] = useState({});

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const validateForm = () => {
    let formErrors = {};

    if (formData.itemType === "file") {
      if (!formData.fileName) {
        formErrors.fileName = "Name of the file is required";
      }
      if (!formData.fileCategory) {
        formErrors.fileCategory = "Category is required";
      }
      if (!formData.file) {
        formErrors.file = "Please upload a file";
      }
    } else if (formData.itemType === "link") {
      const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;

      if (!formData.linkName) {
        formErrors.linkName = "Name of the Link is required";
      }
      if (!formData.linkCategory) {
        formErrors.linkCategory = "Category is required";
      }
      if (!formData.url || !urlRegex.test(formData.url)) {
        formErrors.url = "A valid URL is required";
      }
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    let newEntry;

    if (formData.itemType === "file") {
      newEntry = {
        fileName: formData.fileName,
        fileCategory: formData.fileCategory,
        file: formData.file,
      };
      uploadFile("ecoprismfilecontainer", companyid, formData.file);
    } else if (formData.itemType === "link") {
      newEntry = {
        linkName: formData.linkName,
        linkCategory: formData.linkCategory,
        url: formData.url,
      };
    }

    const existingEntries = JSON.parse(localStorage.getItem("savedEntries")) || [];
    const updatedEntries = [...existingEntries, newEntry];
    localStorage.setItem("savedEntries", JSON.stringify(updatedEntries));
    try {
      const weblink = localStorage.getItem('weblink');
      
      let response = await fetch("https://ecoprismapi.azurewebsites.net/add-files", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          sas_link: weblink,
          name:formData.fileName,
          category:formData.fileCategory,
          company_id: companyid,
          weblinks: [ "https://sustainability.atmeta.com/" ]
        }),
      });
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setFormData({
            linkName: "",
            linkCategory: "",
            url: "",
            fileName: "",
            fileCategory: "",
            file: null,
            itemType: "", // Set "file" as the default
          });
        } else if (responseJson.status == 400) {
          setFormData({
            linkName: "",
            linkCategory: "",
            url: "",
            fileName: "",
            fileCategory: "",
            file: null,
            itemType: "", // Set "file" as the default
          });
        } else {
          // Success condition
          setFormData({
            linkName: "",
            linkCategory: "",
            url: "",
            fileName: "",
            fileCategory: "",
            file: null,
            itemType: "", // Set "file" as the default
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    

    // Reset the form after saving
    

    Swal.fire({
      icon: "success",
      html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">The data has been saved successfully!</p>',
      iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
      confirmButtonText: "Continue",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/repository");
      }
    });
  };

  // Define separate categories for link and file
  const linkOptions = [
    { label: "Policy", value: "policy" },
    { label: "Governance structure", value: "governance_structure" },
    { label: "Governance document", value: "governance_document" },
    { label: "Annual Report", value: "annual_report" },
    { label: "ESG/Sustainability Report", value: "esg_sustainability_report" },
    { label: "GRI Report", value: "gri_report" },
    { label: "SASB Report", value: "sasb_report" },
    { label: "CDP Report", value: "cdp_report" },
    { label: "TCFD Report", value: "tcfd_report" },
    { label: "Integrated Report", value: "integrated_report" },
    { label: "ESG Strategy", value: "esg_strategy" },
    { label: "Roadmap", value: "roadmap" },
  ];

  const fileOptions = [
    { label: "Policy", value: "policy" },
    { label: "Governance structure", value: "governance_structure" },
    { label: "Governance document", value: "governance_document" },
    { label: "Annual Report", value: "annual_report" },
    { label: "ESG/Sustainability Report", value: "esg_sustainability_report" },
    { label: "GRI Report", value: "gri_report" },
    { label: "SASB Report", value: "sasb_report" },
    { label: "CDP Report", value: "cdp_report" },
    { label: "TCFD Report", value: "tcfd_report" },
    { label: "Integrated Report", value: "integrated_report" },
    { label: "ESG Strategy", value: "esg_strategy" },
    { label: "Roadmap", value: "roadmap" },
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, file });
    // Clear the file error when a file is selected
    if (file) {
      setErrors((prevErrors) => ({ ...prevErrors, file: "" }));
    }
  };

  async function uploadFile(containerName, folderName, file) {
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;
        console.log(connectionString);
        const blobServiceClient = new BlobServiceClient(connectionString);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobName = `${folderName}/${file.name}`;
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const uploadResponse = await blockBlobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type },
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`File "${file.name}" uploaded successfully.`);
          console.log(`Blob URL: ${blobClient.url}`);
          localStorage.setItem('weblink', blobClient.url);
        }
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  const handleFileDelete = () => {
    setFormData({ ...formData, file: null }); // Reset the file state
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("drag-over"); // Optionally add a CSS class for visual feedback
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("drag-over"); // Remove visual feedback
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("drag-over"); // Remove visual feedback

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setFormData({ ...formData, file: files[0] }); // Set the dropped file
    }
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="row mb-4">
            <div className="col">
              <div className="d-flex flex-column steps__containt-hader">
                <h1 className="title">Add Files/ Links</h1>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" disabled>
                    Company
                  </Link>
                  <Link underline="hover" color="inherit" href="/repository">
                    Repository
                  </Link>
                  <Typography color="text.primary">Add more</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="form-part add-repo">
            <form>
              <div className="row mb-4">
                <div className="col">
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="fileRadio"
                        name="itemType"
                        value="file"
                        checked={formData.itemType === "file"}
                        onChange={(e) => setFormData({ ...formData, itemType: e.value })}
                      />
                      <label htmlFor="fileRadio" className="ml-2 mb-0 formlable">
                        File
                      </label>

                      <RadioButton
                        inputId="linkRadio"
                        name="itemType"
                        value="link"
                        checked={formData.itemType === "link"}
                        onChange={(e) => setFormData({ ...formData, itemType: e.value })}
                        className="ml-4"
                      />
                      <label htmlFor="linkRadio" className="ml-2 mb-0 formlable">
                        Link
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {formData.itemType === "link" && (
                <div className="row link-row g-5">
                  <div className="col-md-6">
                    {/* Name of the Link */}
                    <div className="form-group">
                      <label className="formlable" htmlFor="linkName">
                        Name of the Link<span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.linkName ? "is-invalid" : ""}`}
                        id="linkName"
                        placeholder="Enter name of the link"
                        value={formData.linkName}
                        onChange={(e) => {
                          setFormData({ ...formData, linkName: e.target.value });
                          setErrors((prevErrors) => ({ ...prevErrors, linkName: "" }));
                        }}
                      />
                      {errors.linkName && <div className="invalid-feedback">{errors.linkName}</div>}
                    </div>

                    <div className="form-group">
                      <label className="formlable" htmlFor="linkCategory">
                        Category<span className="mark-form">*</span>
                      </label>
                      <Dropdown
                        id="linkCategory"
                        className={`form-control wd-100 ${errors.linkCategory ? "is-invalid" : ""}`}
                        value={formData.linkCategory}
                        options={linkOptions}
                        placeholder="Select category"
                        required
                        invalid
                        onChange={(e) => {
                          setFormData({ ...formData, linkCategory: e.value });
                          setErrors((prevErrors) => ({ ...prevErrors, linkCategory: "" }));
                        }}
                      />
                      {errors.linkCategory && (
                        <div className="invalid-feedback">{errors.linkCategory}</div>
                      )}
                    </div>

                    {/* URL */}
                    <div className="form-group">
                      <label className="formlable" htmlFor="url">
                        URL<span className="mark-form">*</span>
                      </label>
                      <input
                        type="url"
                        className={`form-control ${errors.url ? "is-invalid" : ""}`}
                        id="url"
                        placeholder="Enter the URL"
                        value={formData.url}
                        onChange={(e) => {
                          setFormData({ ...formData, url: e.target.value });
                          setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
                        }}
                      />
                      {errors.url && <div className="invalid-feedback">{errors.url}</div>}
                    </div>
                  </div>
                </div>
              )}

              {formData.itemType === "file" && (
                <div className="row file-row g-5">
                  <div className="col-md-6">
                    {/* Name of the Link */}
                    <div className="form-group">
                      <label className="formlable" htmlFor="fileName">
                        Name of the File<span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.fileName ? "is-invalid" : ""}`}
                        id="fileName"
                        placeholder="Enter name of the file"
                        value={formData.fileName}
                        onChange={(e) => {
                          setFormData({ ...formData, fileName: e.target.value });
                          setErrors((prevErrors) => ({ ...prevErrors, fileName: "" })); // Clear error when the field is updated
                        }}
                      />
                      {errors.fileName && <div className="invalid-feedback">{errors.fileName}</div>}
                    </div>

                    <div className="form-group">
                      <label className="formlable" htmlFor="fileCategory">
                        Category<span className="mark-form">*</span>
                      </label>
                      <Dropdown
                        id="fileCategory"
                        className={`form-control wd-100 ${errors.fileCategory ? "is-invalid" : ""}`}
                        value={formData.fileCategory}
                        options={fileOptions}
                        placeholder="Select category"
                        required
                        invalid
                        onChange={(e) => {
                          setFormData({ ...formData, fileCategory: e.value });
                          setErrors((prevErrors) => ({ ...prevErrors, fileCategory: "" }));
                        }}
                      />
                      {errors.fileCategory && (
                        <div className="invalid-feedback">{errors.fileCategory}</div>
                      )}
                    </div>

                    {/* Upload File */}
                    <div className="form-group">
                      <label className="formlable" htmlFor="fileUpload">
                        Upload File<span className="mark-form">*</span>
                      </label>
                      <label
                        htmlFor="fileUpload"
                        className="file-upload-box"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                      >
                        <input
                          type="file"
                          id="fileUpload"
                          onChange={handleFileChange}
                          className="file-input"
                        />
                        <div className="upload-icon">
                          <img src="./assets/img/reports/upload-file.svg" className="" />
                        </div>
                        <p>
                          <span>Browse</span> or drag and drop here
                        </p>
                      </label>

                      {/* Show file name and delete icon if file is selected */}
                      {formData.file && (
                        <div className="file-preview">
                          <span className="file-name">{formData.file.name}</span>
                          <Tooltip title="Delete" arrow>
                            <img
                              src="./assets/img/reports/delete-repo.svg"
                              alt="Delete Icon"
                              className="delete-icon actions-icon"
                              onClick={handleFileDelete}
                            />
                          </Tooltip>
                        </div>
                      )}

                      {errors.file && <div className="invalid-feedback d-block">{errors.file}</div>}
                    </div>
                  </div>
                </div>
              )}

              <div className="forms-buttons-container">
                <Button
                  className="next-button"
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Submit
                </Button>
                <Button
                  className="outline-btn"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/repository")}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepositoryAddLink;
