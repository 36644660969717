import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import LoginHeader from "./components/LoginHeader.js";
import AADHeader from "./components/AADHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appInsights } from "./AppInsights";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
// const useStyles = makeStyles((theme) => containerTheme(theme));

export default function Home() {
  const username = localStorage.getItem("userName");
  // const classes = useStyles();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [news, setNews] = useState(false);
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [responseConfig, setResponseConfig] = useState({});

  // Truncate text function
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  // Chat page click
  const handleChatClick = () => {
    navigate("/ChatPage");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  async function fetchNews() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch("https://ecoprismapi.azurewebsites.net/news", requestOptions);
      const data = await response.json();
      if (data.success == false) {
        setLoading(false);
      } else if (data.status == 400) {
        setLoading(false);
      } else {
        setLoading(false);
        console.log(data);
        setNews(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchNews();
  }, []);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [value, onChange] = useState(new Date());
  return (
    <div id="wrapper">
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div className="col-md-12 flex mt-50">
            <div class="col-md-4 pd-0">
              <p className="home-head">Pick up where you left Off </p>
            </div>
            <div class="col-md-8 pd-0">
              <p className="home-head">Favourites</p>
            </div>
          </div>
          <div className="col-md-12 flex">
            <div class="col-md-4 pd-0">
              <div class="hometab-text flex mb-25">
                <div class="col-md-10">
                  <p className="mb-0">Company Details</p>
                </div>
                <div class="col-md-2">
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div class="hometab-text flex mb-25">
                <div class="col-md-10">
                  <p className="mb-0">Site 1 Purchased Electricity Configuration</p>
                </div>
                <div class="col-md-2">
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </div>
            <div class="col-md-8 flex pd-0">
              <div class="col-md-4">
                <div className="inner-card">
                  <div className="inner-img">
                    <img src="./assets/img/home/Reports.png" className="wd-25"></img>
                  </div>
                  <div className="inner-text">
                    <p className="home-text">Report History</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div className="inner-card">
                  <div className="inner-img">
                    <img src="./assets/img/home/Dashboard.png" className="wd-25"></img>
                  </div>
                  <div className="inner-text">
                    <p className="home-text">Scope 2 Dashboard</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div className="inner-card">
                  <div className="inner-img">
                    <img src="./assets/img/home/FormsApps.png" className="wd-25"></img>
                  </div>
                  <div className="inner-text">
                    <p className="home-text">Internal Reporting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 flex">
            <div class="col-md-4 pd-0">
              <p className="home-head">News</p>
            </div>
            <div class="col-md-8 pd-0"></div>
          </div>
          <div className="col-md-12 pd-0">
            <Carousel responsive={responsive} autoPlaySpeed={4000} autoPlay={true} infinite={true}>
              {news &&
                news.map((row) => (
                  <div class="col">
                    <div class="card shadow-sm">
                      <div class="image-container">
                        <img src={row.imageUrls} alt={row.title} />
                      </div>
                      {/* <img src={row.imageUrls}></img> */}
                      <div class="card-body">
                        <p className="card-title">{row.title}</p>
                        {row.description && (
                          <p className="card-text">{truncateText(row.description, 60)}</p>
                        )}
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-outline-secondary">
                              Read More
                            </button>
                          </div>
                          <small class="text-body-secondary">{row.sourceName}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="col-md-12 flex mt-50">
            <div class="col-md-4 pd-0">
              <div>
                <Calendar onChange={onChange} value={value} />
              </div>
            </div>
            <div class="col-md-8 flex pd-0"></div>
          </div>
        </div>

        <div id="chat-with-us-card" onClick={handleChatClick} style={{ cursor: "pointer" }}>
          <div class="card-body-bot">
            <ContactSupportIcon style={{ color: "white" }} />
            <p class="card-title-bot">EcoBot</p>
          </div>
        </div>
      </div>
    </div>
  );
}
