import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import { TabView, TabPanel } from "primereact/tabview";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./table.css";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const SCFScope = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Track the active tab (0: Scope 1&2, 1: Scope 3)
  const [formData, setFormData] = useState({
    scope1And2Calculation: "",
    scope3Calculation: "",
  });
  const [errors, setErrors] = useState({});
  const [surveyFromDate, setSurveyFromDate] = useState("");
  const [surveyToDate, setSurveyToDate] = useState("");
  const [validFromDate, setValidFromDate] = useState("");
  const [validToDate, setValidToDate] = useState("");
  const [surveyToken, setSurveyToken] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [emissionFactor, setEmissionFactor] = useState("");
  const [rowData, setRowData] = useState([]);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    const storedScfStep1 = localStorage.getItem('scfStep1');
    const parsedScfStep1 = JSON.parse(storedScfStep1);
    const storedScfStep2 = localStorage.getItem('scfStep2');
    const parsedScfStep2 = JSON.parse(storedScfStep2);
    setEmissionFactor(parsedScfStep2.emissionFactor);
    const tokenFromUrl = parsedScfStep1.token;
    const empEmail =parsedScfStep1.email;
    setEmail(empEmail);
    if (tokenFromUrl) {
      console.log(tokenFromUrl);
      const decodedString = atob(tokenFromUrl);
      const [
        companyid,
        formattedFromDate,
        formattedToDate,
        formattedExpiryDate,
        formattedDate,
        supplierName
      ] = decodedString.split(":");
      console.log("Decoded String:", decodedString);

      const fromParts = formattedFromDate.split("-");
      const fromDate = `${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`;

      const toParts = formattedToDate.split("-");
      const toDate = `${toParts[2]}-${toParts[1]}-${toParts[0]}`;
      setFormData({
        ...formData,
        surveyPeriod: fromDate + " to " + toDate,
        employeeEmail: empEmail, // Set the state based on the selected location
      });
      setCountry(parsedScfStep1.country);
      setRowData(parsedScfStep1.rowData);
      setSurveyFromDate(formattedFromDate);
      setSurveyToDate(formattedToDate);
      setValidFromDate(formattedFromDate);
      setValidToDate(formattedToDate);
      setSurveyToken(tokenFromUrl);
      setSupplierName(supplierName);
      setCompanyId(companyid);
      setToken(tokenFromUrl);
    }
    console.log(formData);
  }, []);

  // Function to validate the form based on the active tab
  const validateForm = () => {
    const newErrors = {};
    if (activeIndex === 0 && !formData.scope1And2Calculation) {
      newErrors.scope1And2Calculation = "Please select if you calculate scope 1&2 emissions.";
    } else if (activeIndex === 1 && !formData.scope3Calculation) {
      newErrors.scope3Calculation = "Please select if you calculate scope 3 emissions.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleNext = () => {
    const newErrors = validateForm();

    // Handle validation for Scope 1&2
    if (activeIndex === 0 && Object.keys(newErrors).length === 0) {
      setActiveIndex(1); // Go to Scope 3 tab if no errors for Scope 1&2
    }
    // Handle validation for Scope 3
    else if (activeIndex === 1 && Object.keys(newErrors).length === 0) {
      console.log(formData);
      handleSave();
      //navigate("/supplier-carbon-footprint"); // Redirect to the next page
    }
  };

  const handleSave = async () => {
    console.log(formData);
    
    setLoading(true);
    let validate = await fetch(`${process.env.REACT_APP_BASE_API_URL}validate-carbon-footprint-survey`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        companyId: companyId,
        token: surveyToken,
        surveyFromDate: validFromDate,
        surveyToDate: validToDate,
      }),
    });

    if (validate.status === 200) {
      const responseJson = await validate.json();
      if (responseJson.success === false) {
        setLoading(false);
        console.log(responseJson);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4><p style="font-size: 16px;">${responseJson.data}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else if (responseJson.status === 400) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
              <p style="font-size: 16px;">${responseJson.title}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else {
        const scope3CalculationBoolean = formData.scope3Calculation.toLowerCase() === "true";
        const scope3VerificationBoolean = formData.scope3Verification.toLowerCase() === "true";
        const scope12VerificationBoolean = formData.scope12Verification.toLowerCase() === "true";
        const scope1And2CalculationBoolean = formData.scope1And2Calculation.toLowerCase() === "true";
        const emissionFactorBoolean = emissionFactor.toLowerCase() === "true";
        let res = await fetch(`${process.env.REACT_APP_BASE_API_URL}supplier-carbon-footprint-survey-form`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            "surveyFromDate":surveyFromDate,
            "surveyToDate":surveyToDate,
            "surveyToken":surveyToken,
            "supplierName":supplierName,
            "supplierLocation":country,
            "productDetails":rowData,
            "calculateSpecificCradleToGetEmissionFactor":emissionFactorBoolean,
            "calculateYourCompanyScope1Scope2Emissions":scope1And2CalculationBoolean,
            "scope1Scope2EmissionNosActualOrEstimated":formData.scope12NumbersType,
            "scope1Scope2EmissionNosExternallyVerified":scope12VerificationBoolean,
            "scope1EmissionstCO2e":formData.scope1Emissions,
            "scope2EmissionstCO2e":formData.scope2Emissions,
            "scope1EmissionIntensityBasedOnRevenue":formData.scope1Intensity,
            "scope1Unit":formData.unit1,
            "scope1Currency":formData.currency1,
            "scope2EmissionIntensityBasedOnRevenue":formData.scope2Intensity,
            "scope2Unit":formData.unit2,
            "scope2Currency":formData.currency2,
            "percentOfTotalOrganizationalEmissionsForScope1Scope2":formData.scope12Percentage,"calculateYourCompanyScope3UpstreamAndWasteEmissions":scope3CalculationBoolean,
            "scope3EmissionNosActualOrEstimated":formData.scope3NumbersType,
            "scope3UpstreamAndWasteEmissionNosExternallyVerified":scope3VerificationBoolean,
            "scope3UpstreamEmissionstCO2e":formData.upstreamEmissions,
            "scope3WasteEmissionstCO2e":formData.wasteEmissions,
            "upstreamEmissionIntensityBasedOnRevenue":formData.upstreamIntensity,
            "upstreamUnit":formData.unit3,
            "upstreamCurrency":formData.currency3,
            "wasteEmissionIntensityBasedOnRevenue":formData.waterIntensity,
            "wasteUnit":formData.unit4,
            "wasteCurrency":formData.currency4,
            "percentOfTotalOrganizationalEmissionsForScope3":formData.scope3Percentage,
            "companyId":companyId
          }),
        });

        if (res.status === 200) {
          const responseJson = await res.json();
          if (responseJson.success === false) {
            setLoading(false);
            console.log(responseJson);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4><p style="font-size: 16px;">${responseJson.data}</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          } else if (responseJson.status === 400) {
            setLoading(false);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                  <p style="font-size: 16px;">${responseJson.title}</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          } else {
            setLoading(false);
            setFormData("");
            Swal.fire({
              icon: "success",
              html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Survey has been saved successfully</p>',
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
            });
          }
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Error in creating site location</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        }
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        html: `<h4 style="color:#CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
        <p style="font-size: 16px;">Error in saving survey</p>`,
        iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
      });
    }
    setLoading(false);
  };

  const handleRadioChange = (e, field) => {
    const value = e.value;
    setFormData({ ...formData, [field]: value });
    // Clear the error for the field once selected
    setErrors({ ...errors, [field]: "" });
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#home">
                <img
                  src="assets/img/logo.png"
                  alt="Company Logo"
                  height="30"
                  className="d-inline-block align-top"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img className="icon mr-3" src="assets/img/User.png" alt="User" />
                      Mathew
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="container survey-form scf p-4 p-lg-5">
          <div className="row mb-4">
            <div className="col">
              <div className="form-header">
                <h1 className="title" style={{ color: "#407775!important" }}>
                  Supplier Carbon Footprint​ Survey
                </h1>
                <h2>Energy And Emissions</h2>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row tabs">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                  <TabPanel header="Scope 1&2">
                    <div className="row gy-4">
                      <div className="col col-md-12">
                        <label className="formlable" htmlFor="scope1And2Calculation">
                          Do you calculate your company's scope 1 and 2 emissions?
                        </label>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="scope1And2Yes"
                              name="scope1And2Calculation"
                              value="true"
                              checked={formData.scope1And2Calculation === "true"}
                              onChange={(e) => handleRadioChange(e, "scope1And2Calculation")}
                            />
                            <label htmlFor="scope1And2Yes" className="ml-2 mb-0 formlable">
                              Yes
                            </label>
                            <RadioButton
                              inputId="scope1And2No"
                              name="scope1And2Calculation"
                              value="false"
                              checked={formData.scope1And2Calculation === "false"}
                              onChange={(e) => handleRadioChange(e, "scope1And2Calculation")}
                              className="ml-4"
                            />
                            <label htmlFor="scope1And2No" className="ml-2 mb-0 formlable">
                              No
                            </label>
                          </div>
                        </div>
                        {errors.scope1And2Calculation && (
                          <p className="text-danger">{errors.scope1And2Calculation}</p>
                        )}
                      </div>
                    </div>
                    {formData.scope1And2Calculation === "true" && (
                      <div className="scope1">
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12NumbersType">
                                Are your emission numbers actual or estimated?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope12NumbersActual"
                                    name="scope12NumbersType"
                                    value="Actual"
                                    checked={formData.scope12NumbersType === "Actual"}
                                    onChange={(e) => handleRadioChange(e, "scope12NumbersType")}
                                  />
                                  <label
                                    htmlFor="scope12NumbersActual"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Actual
                                  </label>
                                  <RadioButton
                                    inputId="scope12NumbersEstimated"
                                    name="scope12NumbersType"
                                    value="Estimated"
                                    checked={formData.scope12NumbersType === "Estimated"}
                                    onChange={(e) => handleRadioChange(e, "scope12NumbersType")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope12NumbersEstimated"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Estimated
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12Verification">
                                Is your scope 1 and 2 emission numbers externally verified?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope12VerificationYes"
                                    name="scope12Verification"
                                    value="true"
                                    checked={formData.scope12Verification === "true"}
                                    onChange={(e) => handleRadioChange(e, "scope12Verification")}
                                  />
                                  <label
                                    htmlFor="scope12VerificationYes"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Yes
                                  </label>
                                  <RadioButton
                                    inputId="scope12VerificationNo"
                                    name="scope12Verification"
                                    value="false"
                                    checked={formData.scope12Verification === "false"}
                                    onChange={(e) => handleRadioChange(e, "scope12Verification")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope12VerificationNo"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope1Emissions">
                                Scope 1 emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="scope1Emissions"
                                value={formData.scope1Emissions}
                                className={`form-control ${
                                  errors.scope1Emissions ? "is-invalid" : ""
                                }`}
                                onChange={(e) => {
                                  setFormData({ ...formData, scope1Emissions: e.target.value });
                                }}
                                placeholder="Enter Scope 1 emissions value"
                              />

                              {errors.scope1Emissions && (
                                <div className="invalid-feedback">{errors.scope1Emissions}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope2Emissions">
                                Scope 2 emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="scope2Emissions"
                                value={formData.scope2Emissions}
                                onChange={(e) => {
                                  setFormData({ ...formData, scope2Emissions: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 2 emissions value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope1Intensity">
                                Scope 1 emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="scope1Intensity"
                                value={formData.scope1Intensity}
                                onChange={(e) => {
                                  setFormData({ ...formData, scope1Intensity: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 1 emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit1">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit1"
                                    value={formData.unit1}
                                    onChange={(e) => {
                                      setFormData({ ...formData, unit1: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Unit"
                                    
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency1">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency1"
                                    value={formData.currency1}
                                    onChange={(e) => {
                                      setFormData({ ...formData, currency1: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Currency"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope2Intensity">
                                Scope 2 emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="scope2Intensity"
                                value={formData.scope2Intensity}
                                onChange={(e) => {
                                  setFormData({ ...formData, scope2Intensity: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 2 emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit2">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit2"
                                    value={formData.unit2}
                                    onChange={(e) => {
                                      setFormData({ ...formData, unit2: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency2">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency2"
                                    value={formData.currency2}
                                    onChange={(e) => {
                                      setFormData({ ...formData, currency2: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Autopopulated"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope12Percentage">
                                What % of total organizational emissions are covered in this
                                estimation?
                              </label>
                              <div className="row">
                                <div className="col-md-5">
                                  <input
                                    type="text"
                                    id="scope12Percentage"
                                    value={formData.scope12Percentage}
                                    onChange={(e) => {
                                      setFormData({ ...formData, scope12Percentage: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Enter % of total organizational emissions"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel header="Scope 3">
                    <div className="row gy-4">
                      <div className="col col-md-12">
                        <label className="formlable" htmlFor="scope3Calculation">
                          Do you calculate your company's scope 3 upstream and waste emissions?
                        </label>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton
                              inputId="scope3Yes"
                              name="scope3Calculation"
                              value="true"
                              checked={formData.scope3Calculation === "true"}
                              onChange={(e) => handleRadioChange(e, "scope3Calculation")}
                            />
                            <label htmlFor="scope3Yes" className="ml-2 mb-0 formlable">
                              Yes
                            </label>
                            <RadioButton
                              inputId="scope3No"
                              name="scope3Calculation"
                              value="false"
                              checked={formData.scope3Calculation === "false"}
                              onChange={(e) => handleRadioChange(e, "scope3Calculation")}
                              className="ml-4"
                            />
                            <label htmlFor="scope3No" className="ml-2 mb-0 formlable">
                              No
                            </label>
                          </div>
                        </div>
                        {errors.scope3Calculation && (
                          <p className="text-danger">{errors.scope3Calculation}</p>
                        )}
                      </div>
                    </div>
                    {formData.scope3Calculation === "true" && (
                      <div className="scope3">
                        <div className="row mt-4">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3NumbersType">
                                Are your emission numbers actual or estimated?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope3NumbersActual"
                                    name="scope3NumbersType"
                                    value="Actual"
                                    checked={formData.scope3NumbersType === "Actual"}
                                    onChange={(e) => handleRadioChange(e, "scope3NumbersType")}
                                  />
                                  <label
                                    htmlFor="scope3NumbersActual"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Actual
                                  </label>
                                  <RadioButton
                                    inputId="scope3NumbersEstimated"
                                    name="scope3NumbersType"
                                    value="Estimated"
                                    checked={formData.scope3NumbersType === "Estimated"}
                                    onChange={(e) => handleRadioChange(e, "scope3NumbersType")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope3NumbersEstimated"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Estimated
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3Verification">
                                Is your scope 3 upstream and waste emission numbers externally
                                verified?
                              </label>
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <RadioButton
                                    inputId="scope3VerificationYes"
                                    name="scope3Verification"
                                    value="true"
                                    checked={formData.scope3Verification === "true"}
                                    onChange={(e) => handleRadioChange(e, "scope3Verification")}
                                  />
                                  <label
                                    htmlFor="scope3VerificationYes"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    Yes
                                  </label>
                                  <RadioButton
                                    inputId="scope3VerificationNo"
                                    name="scope3Verification"
                                    value="false"
                                    checked={formData.scope3Verification === "false"}
                                    onChange={(e) => handleRadioChange(e, "scope3Verification")}
                                    className="ml-4"
                                  />
                                  <label
                                    htmlFor="scope3VerificationNo"
                                    className="ml-2 mb-0 formlable"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="upstreamEmissions">
                                Scope 3 upstream emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="upstreamEmissions"
                                value={formData.upstreamEmissions}
                                onChange={(e) => {
                                  setFormData({ ...formData, upstreamEmissions: e.target.value });
                                }}
                                className={`form-control ${
                                  errors.upstreamEmissions ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Scope 3 upstream emissions value"
                              />

                              {errors.upstreamEmissions && (
                                <div className="invalid-feedback">{errors.upstreamEmissions}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="formlable" htmlFor="wasteEmissions">
                                Scope 3 waste emissions (tCO2e)
                              </label>
                              <input
                                type="text"
                                id="wasteEmissions"
                                value={formData.wasteEmissions}
                                onChange={(e) => {
                                  setFormData({ ...formData, wasteEmissions: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 3 waste emissions value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="upstreamIntensity">
                                Upstream emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="upstreamIntensity"
                                value={formData.upstreamIntensity}
                                onChange={(e) => {
                                  setFormData({ ...formData, upstreamIntensity: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 3 upstream emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit3">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit3"
                                    value={formData.unit3}
                                    onChange={(e) => {
                                      setFormData({ ...formData, unit3: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Unit"
                                    
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency3">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency3"
                                    value={formData.currency3}
                                    onChange={(e) => {
                                      setFormData({ ...formData, currency3: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Currency"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label className="formlable" htmlFor="waterIntensity">
                                Waste emission intensity based on revenue
                              </label>
                              <input
                                type="text"
                                id="waterIntensity"
                                value={formData.waterIntensity}
                                onChange={(e) => {
                                  setFormData({ ...formData, waterIntensity: e.target.value });
                                }}
                                class="form-control"
                                placeholder="Enter Scope 3 waste emissions intensity"
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="unit4">
                                    Unit
                                  </label>
                                  <input
                                    type="text"
                                    id="unit4"
                                    value={formData.unit4}
                                    onChange={(e) => {
                                      setFormData({ ...formData, unit4: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Unit"
                                    
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="formlable" htmlFor="currency4">
                                    Currency
                                  </label>
                                  <input
                                    type="text"
                                    id="currency4"
                                    value={formData.currency4}
                                    onChange={(e) => {
                                      setFormData({ ...formData, currency4: e.target.value });
                                    }}
                                    class="form-control"
                                    placeholder="Currency"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="formlable" htmlFor="scope3Percentage">
                                What % of total organizational emissions are covered in this
                                estimation?
                              </label>
                              <div className="row">
                                <div className="col-md-5">
                                  <input
                                    type="text"
                                    id="scope3Percentage"
                                    value={formData.scope3Percentage}
                                    onChange={(e) => {
                                      setFormData({ ...formData, scope3Percentage: e.target.value });
                                    }}
                                    className={`form-control ${
                                      errors.scope3Percentage ? "is-invalid" : ""
                                    }`}
                                    placeholder="Enter % of total organizational emissions"
                                  />
                                  {errors.scope3Percentage && (
                                    <div className="invalid-feedback">
                                      {errors.scope3Percentage}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPanel>
                </TabView>
              </div>
            </form>
            <div className="forms-buttons-container">
              <Button
                className="outline-btn"
                variant="contained"
                color="primary"
                startIcon={
                  <ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />
                }
                onClick={() => navigate("/energy-emissions")}
              >
                Back
              </Button>
              <Button
                className="next-button"
                variant="contained"
                color="primary"
                endIcon={
                  <ArrowForwardIosIcon sx={{ fontSize: "12px!important", marginLeft: "0" }} />
                }
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCFScope;
