import React from "react";
import clsx from "clsx";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useEffect, useState, useLayoutEffect } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import "./Spinner.css";
import Links from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import TableFooter from "@mui/material/TableFooter";
import { FaSearch } from "react-icons/fa";
import { appInsights } from "./AppInsights";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import DataTables from "datatables.net";
import $ from "jquery";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "./table.css";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "react-select";
import AADHeader from "./components/AADHeader.js";
export default function Home() {
  const username = localStorage.getItem("userName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function createData(name, type, address1, address2, country, state, city, postalcode, lat, long) {
    return { name, type, address1, address2, country, state, city, postalcode, lat, long };
  }

  const [isLoading, setIsLoading] = useState(false);
  const [etities, setEntities] = useState();
  const [activesite, setActiveSite] = useState();
  const [deactivesite, setDeactiveSite] = useState();
  const [deletename, setDeleteName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [oldname, setOldName] = useState("");
  const [curcountry, setCurCountry] = useState("");
  const [sitename, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalcode, setPostalCode] = useState("");
  const [lat, setLatitude] = useState("");
  const [lng, setLongitude] = useState("");
  const [message, setMessage] = useState("");
  const [inputList, setInputList] = useState([{ businessunit: "", businessunitvalue: "" }]);
  const [bunit, setBunit] = useState([{ bunit: "", bunitvalue: "" }]);
  const [businessunit, setBusinessUnit] = useState();
  const [businessunit1, setBusinessUnit1] = useState();
  const [allocation, setAllocation] = useState();
  const [businessallocation, setBusinessAllocation] = useState();
  const [legalentities, setLegalEntities] = useState();
  const [sitetype, setSiteType] = useState("");
  const [getsitetype, setGetSiteType] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDisabled1, setIsButtonDisabled1] = useState(false);
  const [activebu, setActiveBU] = useState("");
  const [activele, setActiveLE] = useState("");
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [inact, setInact] = useState("");
  const [act, setAct] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    siteType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    country: { value: null, matchMode: FilterMatchMode.CONTAINS },
    state: { value: null, matchMode: FilterMatchMode.CONTAINS },
    legalEntityMapping: { value: null, matchMode: FilterMatchMode.CUSTOM },
    businessUnitMapping: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    siteType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    country: { value: null, matchMode: FilterMatchMode.CONTAINS },
    state: { value: null, matchMode: FilterMatchMode.CONTAINS },
    legalEntityMapping: { value: null, matchMode: FilterMatchMode.CUSTOM },
    businessUnitMapping: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters1 };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };

  const header = renderHeader();
  const header1 = renderHeader1();

  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  async function fetchBusinessUnit() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net//list-businessunits",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setBusinessUnit("");
        } else if (responseJson.status == 400) {
          setBusinessUnit("");
        } else {
          setBusinessUnit(responseJson);
        }
      } else {
        setBusinessUnit("");
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchBusinessUnit();
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    var allocate1 = [];
    var result = list.reduce(function (map, obj) {
      map[obj.businessunit] = obj.businessunitvalue;
      allocate1.push(obj.businessunitvalue);
      return map;
    }, {});
    if (inputList.length === 0) {
      console.log("Zero Value");
    }
    const sumList = allocate1.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue);
    }, 0);
    if (sumList >= 100) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target;
    // Business Unit
    const list1 = [...bunit];
    list1[index][name] = value;
    setBunit(list1);
    var allocate11 = [];
    var result = list1.reduce(function (map, obj) {
      map[obj.bunit] = obj.bunitvalue;
      allocate11.push(obj.bunitvalue);
      return map;
    }, {});
    if (bunit.length === 0) {
      console.log("Zero Value");
    }
    const sumList1 = allocate11.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue);
    }, 0);
    if (sumList1 >= 100) {
      setIsButtonDisabled1(true);
    } else {
      setIsButtonDisabled1(false);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    var allocate1 = [];
    var result = list.reduce(function (map, obj) {
      map[obj.businessunit] = obj.businessunitvalue;
      allocate1.push(obj.businessunitvalue);
      return map;
    }, {});
    if (inputList.length === 0) {
      console.log("Zero Value");
    }
    const sumList = allocate1.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue);
    }, 0);
    if (sumList >= 100) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  // const handleRemoveClick1 = (index) => {
  //   // Business Unit
  //   const list1 = [...bunit];
  //   list1.splice(index, 1);
  //   setBunit(list1);
  //   var allocate11 = [];
  //   var result = list1.reduce(function (map, obj) {
  //     map[obj.bunit] = obj.bunitvalue;
  //     allocate11.push(obj.bunitvalue);
  //     return map;
  //   }, {});
  //   if (bunit.length === 0) {
  //     console.log("Zero Value");
  //   }
  //   const sumList1 = allocate11.reduce((accumulator, currentValue) => {
  //     return accumulator + parseFloat(currentValue);
  //   }, 0);

  //   if (sumList1 >= 100) {
  //     setIsButtonDisabled1(true);
  //   } else {
  //     setIsButtonDisabled1(false);
  //   }
  // };

  const handleRemoveClick1 = (index) => {
    const list1 = [...bunit];
    list1.splice(index, 1); // Remove the selected BU at the index
  
    // Check if the list becomes empty
    if (list1.length === 0) {
      setBunit([{ bunit: "", bunitvalue: "" }]); // Ensure at least one empty field remains
    } else {
      setBunit(list1); // Update the bunit state with the new list
    }
  
    // Recalculate the allocation sum
    const allocate11 = list1.map(item => parseInt(item.bunitvalue) || 0);
    const sumList1 = allocate11.reduce((acc, curr) => acc + curr, 0);
  
    setIsButtonDisabled1(sumList1 >= 100); // Enable or disable the "Add More" button based on the total sum
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { businessunit: "", businessunitvalue: "" }]);
  };

  const handleAddClick1 = () => {
    setBunit([...bunit, { bunit: "", bunitvalue: "" }]);
  };

  const showEditModal = (
    name,
    address1,
    address2,
    city,
    state,
    postalcode,
    country,
    lat,
    lng,
    allocation,
    site,
    businessallocation
  ) => {
    setIsButtonDisabled(true);
    setIsButtonDisabled1(true);
    function combine(collections) {
      const entries = [...Object.entries(collections)];
      const length = entries[0]?.[1]?.length ?? 0;
      const result = [];
      for (const [name, array] of entries) {
        if (array.length !== length) throw new Error("Non-uniform array lengths");
        for (let i = 0; i < length; i += 1) {
          const item = (result[i] ??= {});
          item[name] = array[i];
        }
      }
      return result;
    }

    const businessUnitValues = Object.values(allocation);
    const businessUnitKeys = Object.keys(allocation);

    const bUnitValues = Object.values(businessallocation);
    const bUnitKeys = Object.keys(businessallocation);
    const merge = () => {
      const merged = combine({
        businessunitvalue: businessUnitValues,
        businessunit: businessUnitKeys,
      });

      const merged1 = combine({
        bunitvalue: bUnitValues,
        bunit: bUnitKeys,
      });

      setAllocation(merged);
      setBusinessAllocation(merged1);
      setInputList(merged);
      setBunit(merged1);
      if (merged1.length === 0) {
        setBunit([{ bunit: "", bunitvalue: "" }]);
      }
    };
    merge();
    setName(name);
    setOldName(name);
    setAddress1(address1);
    setAddress2(address2);
    setCountry(country);
    setCity(city);
    setPostalCode(postalcode);
    setState(state);
    setLatitude(lat);
    setLongitude(lng);
    setSiteType(site);
    let arr = [];
    setEditIsOpen(true);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  const showDeleteModal = (name) => {
    setDeleteName(name);
    setDeleteMessage("Are you sure you want to deactivate this " + name + "?");
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = async (deletename) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net//delete-sitelocation", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          name: deletename,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else {
          fetchEntities();
          setTimeout(() => {
            setLoading(false);
            setDisplayConfirmationModal(false);
            Swal.fire({
              icon: "success",
              html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Site Location Deactivated successfully</p>',
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
            });
          }, 2000); // 2000 milliseconds (2 seconds)
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error In Deactivate Site Location</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function fetchEntities() {
    setLoading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net//list-sitelocation",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setLoading(false);
          setEntities("");
        } else if (responseJson.status == 400) {
          setLoading(false);
          setEntities("");
        } else {
          setLoading(false);
          setEntities(responseJson);
          setActiveSite(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveSite(responseJson.data.filter((item) => item.isActive === false));
          setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
          setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
        }
      } else {
        setLoading(false);
        setLegalEntities("");
      }
      setIsLoading(false);
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  async function fetchSiteType() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net//list-sitetype",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setGetSiteType("");
        } else if (responseJson.status == 400) {
          setGetSiteType("");
        } else {
          setGetSiteType(responseJson);
        }
      } else {
        setGetSiteType("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSiteType();
    fetchEntities();
  }, []);

  const [countries, setCountries] = useState();

  async function fetchCountries() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net//countries");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchLegalEntities() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net//list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setLegalEntities("");
        } else if (responseJson.status == 400) {
          setLegalEntities("");
        } else {
          setLegalEntities(responseJson);
          setActiveLE(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setLegalEntities("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchBusinessUnit() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net//list-businessunits",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setBusinessUnit1("");
        } else if (responseJson.status == 400) {
          setBusinessUnit1("");
        } else {
          setBusinessUnit1(responseJson);
          setActiveBU(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setBusinessUnit1("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    appInsights.trackPageView();
    fetchLegalEntities();
    fetchBusinessUnit();
    fetchCountries();
  }, []);

  useEffect(() => {}, [activesite]);

  const reactive = async (name) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net//reactive-sitelocation", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          fetchEntities();
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Site location reactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error in reactivate site location</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const saveData = async (
    name,
    address1,
    city,
    state,
    postalcode,
    country,
    lat,
    lng,
    allocation,
    ballocation
  ) => {
    setLoading(true);
    try {
      const hasEmptyBusinessunit = inputList.some((row) => row.businessunit === "");
      if (hasEmptyBusinessunit) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Please select legal entity</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (state == "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">State can not be empty</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (city == "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">City can not be empty</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (country == "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Country can not be empty</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (sitetype == "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Site type can not be empty</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      // Legal Entity
      var allocate = [];
      var result = inputList.reduce(function (map, obj) {
        map[obj.businessunit] = obj.businessunitvalue;
        allocate.push(obj.businessunitvalue);
        return map;
      }, {});

      const sum = allocate.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue);
      }, 0);

      const uniqueBusinessUnits = {};
      const duplicates = {};
      inputList.forEach((item) => {
        const { businessunit } = item;
        if (!uniqueBusinessUnits[businessunit]) {
          uniqueBusinessUnits[businessunit] = true;
        } else {
          duplicates["count"] = (duplicates[businessunit] || 1) + 1;
        }
      });
      if (duplicates.count > 1) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Multiple allocation to same legal entity not allowed</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (sum > 100) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">LE Allocation is greater than 100%</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      } else if (sum < 100) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">LE Allocation is less than 100%</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      } else if (isNaN(sum)) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">LE Allocation is less than 100%</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      } else {
      }

      // Business Unit
      var allocate1 = [];
      var result1 = bunit.reduce(function (map, obj) {
        map[obj.bunit] = obj.bunitvalue;
        allocate1.push(obj.bunitvalue);
        return map;
      }, {});

      const isEmpty = Object.keys(result1).every((key) => key === "");
      if (isEmpty) {
        var result2 = {};
      } else {
        const sum1 = allocate1.reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue);
        }, 0);
        const uniqueBusinessUnits1 = {};
        const duplicates1 = {};
        bunit.forEach((item) => {
          const { bunit } = item;
          if (!uniqueBusinessUnits1[bunit]) {
            uniqueBusinessUnits1[bunit] = true;
          } else {
            duplicates1["count"] = (duplicates1[bunit] || 1) + 1;
          }
        });
        if (duplicates1.count > 1) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Multiple allocation to same business unit not allowed</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          return false;
        }

        if (sum1 > 100) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Business unit allocation is greater than 100%</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          return false;
        } else if (sum1 < 100) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Business unit allocation is less than 100%</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          return false;
        } else if (isNaN(sum1)) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Business unit allocation is less than 100%</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          return false;
        } else {
        }
        var result2 = bunit.reduce(function (map, obj) {
          map[obj.bunit] = obj.bunitvalue;
          return map;
        }, {});
      }
      if (postalcode === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                 <p style="font-size: 16px;">Postal code is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (!validatePostalCode(postalcode)) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                 <p style="font-size: 16px;">Please enter a valid postal code.</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (address1 === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Address field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (country === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Country field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (state === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">State field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }

      if (city === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">City field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (lat === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Latitude field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      if (lng === "") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Longitude field is required</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        return false;
      }
      let res = await fetch("https://ecoprismapi.azurewebsites.net//update-sitelocation", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          old: oldname,
        },
        body: JSON.stringify({
          name: name,
          addressLine1: address1,
          addressLine2: "test",
          city: city,
          state: state,
          postalCode: postalcode,
          country: country,
          latitude: lat,
          longitude: lng,
          numberOfEmployees: "1",
          parentAssociation: "test320",
          siteType: sitetype,
          legalEntityMapping: result,
          businessUnitMapping: result2,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height:20px;">The Site Location has been successfully updated</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        }
        fetchEntities();
        setEditIsOpen(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error in update site location</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activeTable, setActiveTable] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(10);
  const [inactiveRowsPerPage, setInactiveRowsPerPage] = useState(10);
  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [globalFilter, setGlobalFilter] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useLayoutEffect(() => {
    initializeDataTable();
    initializeDataTable1();
    setActive("block");
    setInactive("none");
    setInact("inactive-buttonred");
    setAct("active-buttongreen");
  }, [activesite]);

  const initializeDataTable = () => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      var dataTable = $("#myTable").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();
          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Handle global search
          $("#globalSearch").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().toLowerCase();
                    return (
                      columnString.includes(enteredValue) ||
                      columnString.replace(/,/g, "").includes(enteredValue)
                    );
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const initializeDataTable1 = () => {
    if (!$.fn.DataTable.isDataTable("#myTable1")) {
      var dataTable1 = $("#myTable1").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();

          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Add a global search input
          $("#customSearchDivinactive").append(
            '<input type="text" id="globalSearch1" class="search-input search-container " placeholder="Search all data" />'
          );

          // Handle global search
          $("#globalSearch1").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters1 th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters1 th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().replace(/,/g, "").toLowerCase();
                    return columnString.includes(enteredValue);
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const toggleTable = (name) => {
    // Initialize DataTable instance for the selected tab
    if (name === "active") {
      setActive("block");
      setInactive("none");
      setInact("inactive-buttonred");
      setAct("active-buttongreen");
    } else if (name === "inactive") {
      setInact("active-buttonred");
      setAct("inactive-buttongreen");
      setActive("none");
      setInactive("block");
    }
    setPage(0); // Reset page when switching tables
  };

  FilterService.register("custom_legalEntityMapping", (value, filters) => {
    const entries = Object.entries(value);
    const result = entries.some(([entity, val]) => {
      const formattedEntity = entity.toLowerCase();
      const formattedVal = val.toString().toLowerCase();
      const formattedFilter = filters.trim().toLowerCase();
      return formattedEntity.includes(formattedFilter) || formattedVal.includes(formattedFilter);
    });
    return result;
  });

  FilterService.register("custom_businessUnitMapping", (value, filters) => {
    const entries = Object.entries(value);
    const result = entries.some(([entity, val]) => {
      const formattedEntity = entity.toLowerCase();
      const formattedVal = val.toString().toLowerCase();
      const formattedFilter = filters.trim().toLowerCase();
      return formattedEntity.includes(formattedFilter) || formattedVal.includes(formattedFilter);
    });
    return result;
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      border: "1px solid #ebe9e9",
      minHeight: "30px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      height: "40px",
      width: "100%",
      textAlign: "left",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      textAlign: "left",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };
  const [postalcodeError, setPostalCodeError] = useState("");
  const isNumeric = (value) => /^[0-9]*$/.test(value);
  // Helper function to check if the value is only numeric
  const isAlphanumericWithNumber = (value) => {
    const hasAlphabet = /[a-zA-Z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    return hasAlphabet && hasNumeric;
  };

  // Function to validate the input based on the scenarios
  const validatePostalCode = (value) => {
    if (isNumeric(value) || isAlphanumericWithNumber(value)) {
      setPostalCodeError("");
      return true;
    } else {
      setPostalCodeError("Please enter a valid postal code.");
      return false;
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    const alphanumeric = /^[a-zA-Z0-9]*$/; // Allow only alphanumeric characters
    if (
      alphanumeric.test(value) ||
      value === "" ||
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      setPostalCode(value);
      validatePostalCode(value);
    }
  };
  const renderHeader2 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader2()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9 pd-0">
              <div Class="steps__containt-hader">
                <h3 class="title">Site Locations</h3>
                <div role="presentation">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                  >
                    <Links underline="hover" color="inherit" className="breadcrumbs-font">
                      Company
                    </Links>
                    <Typography color="text.primary">Site Locations</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3 pd-0">
              <div Class="steps__containt-hader btalignleft">
                <Link to="/sitelocation" className="add-cta">
                  + Add Site Location
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="row" style={{ display: active }}>
              <DataTable
                value={activesite}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 25, 50]}
                resizableColumns
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                dataKey="id"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["name", "siteType", "country", "state"]}
                header={header}
                emptyMessage="No data found."
              >
                <Column
                  header=""
                  body={(rowData) => (
                    <div>
                      <EditIcon
                        title="Edit"
                        style={{
                          cursor: "pointer",
                          color: "#407775",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={() =>
                          showEditModal(
                            rowData.name,
                            rowData.addressLine1,
                            rowData.addressLine2,
                            rowData.city,
                            rowData.state,
                            rowData.postalCode,
                            rowData.country,
                            rowData.latitude,
                            rowData.longitude,
                            rowData.legalEntityMapping,
                            rowData.siteType,
                            rowData.businessUnitMapping
                          )
                        }
                      />

                      <img
                        className="action-icon"
                        src="assets/img/deactivate.png"
                        alt="Deactive"
                        style={{ cursor: "pointer", color: "#407775" }}
                        onClick={() => showDeleteModal(rowData.name)}
                      />
                    </div>
                  )}
                  style={{ minWidth: "5rem" ,paddingLeft: "30px" }}
                />
                <Column
                  sortable
                  field="name"
                  header="Site Location"
                  filter
                  filterPlaceholder="Filter"
                  style={{ minWidth: "15rem" }}
                />
                <Column
                  sortable
                  header="Site type"
                  field="siteType"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Country"
                  field="country"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="State"
                  field="state"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />

                <Column
                  header="Legal Entity"
                  field="legalEntityMapping"
                  body={(rowData) => (
                    <ul style={{ listStyle: "none", paddingLeft: "0px", marginBottom: "0" }}>
                      {rowData.legalEntityMapping &&
                      typeof rowData.legalEntityMapping === "object" ? (
                        Object.entries(rowData.legalEntityMapping).map(([entity, value]) => (
                          <li key={entity}>
                            {entity} : {value} %
                          </li>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </ul>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Business Unit"
                  field="businessUnitMapping"
                  sortable
                  body={(rowData) => (
                    <ul style={{ listStyle: "none", paddingLeft: "0px", marginBottom: "0" }}>
                      {rowData.businessUnitMapping &&
                      typeof rowData.businessUnitMapping === "object" ? (
                        Object.entries(rowData.businessUnitMapping).map(([entity, value]) => (
                          <li key={entity}>
                            {entity} : {value} %
                          </li>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </ul>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
              </DataTable>
            </div>
            <div className="row" style={{ display: inactive }}>
              <DataTable
                value={deactivesite}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                dataKey="id"
                filters={filters1}
                filterDisplay="row"
                globalFilterFields={[
                  "name",
                  "siteType",
                  "country",
                  "state",
                  "legalEntityMapping",
                  "businessUnitMapping",
                ]}
                header={header1}
                emptyMessage="No data found."
              >
                <Column
                  header=""
                  body={(rowData) => (
                    <div>
                      <img
                        className="action-icon"
                        src="assets/img/reactivate.png"
                        alt="Reactive"
                        style={{ cursor: "pointer", color: "#407775" }}
                        onClick={() => reactive(rowData.name)}
                      />
                    </div>
                  )}
                  style={{ minWidth: "5rem" ,paddingLeft: "30px"}}
                />
                <Column
                  sortable
                  field="name"
                  header="Site Location"
                  filter
                  filterPlaceholder="Filter"
                  style={{ minWidth: "15rem" }}
                />
                <Column
                  sortable
                  header="Site type"
                  field="siteType"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="Country"
                  field="country"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  sortable
                  header="State"
                  field="state"
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Legal Entity"
                  field="legalEntityMapping"
                  sortable
                  body={(rowData) => (
                    <ul style={{ listStyle: "none", paddingLeft: "0px", marginBottom: "0" }}>
                      {rowData.legalEntityMapping &&
                      typeof rowData.legalEntityMapping === "object" ? (
                        Object.entries(rowData.legalEntityMapping).map(([entity, value]) => (
                          <li key={entity}>
                            {entity} : {value} %
                          </li>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </ul>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                  filterMatchMode="custom" // Set filterMatchMode to custom for custom filtering
                  filterFunction={(value, filter, rowData) => {
                    const filterValue = filter.value.toLowerCase();
                    return (
                      (rowData && rowData.revenueCurrency.toLowerCase().includes(filterValue)) ||
                      rowData.revenue.toLowerCase().includes(filterValue)
                    );
                  }}
                />
                <Column
                  header="Business Unit"
                  field="businessUnitMapping"
                  sortable
                  body={(rowData) => (
                    <ul style={{ listStyle: "none", paddingLeft: "0px", marginBottom: "0" }}>
                      {rowData.businessUnitMapping &&
                      typeof rowData.businessUnitMapping === "object" ? (
                        Object.entries(rowData.businessUnitMapping).map(([entity, value]) => (
                          <li key={entity}>
                            {entity} : {value} %
                          </li>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </ul>
                  )}
                  style={{ minWidth: "15rem" }}
                  filter
                  filterPlaceholder="Filter"
                />
              </DataTable>
            </div>
          </div>
          <div class="col-md-12 pd-0">
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <DeleteConfirmation
              confirmModal={submitDelete}
              showModal={displayConfirmationModal}
              hideModal={hideConfirmationModal}
              message={deleteMessage}
              deletename={deletename}
            />
            <Modal
              show={editIsOpen}
              onHide={hideEditModal}
              className="custom-modal-width-config site-modal"
            >
              <Modal.Header>
                <div Class="steps__containt-hader edit-form">
                  <h3 class="title">Edit Site Location</h3>
                </div>
              </Modal.Header>
              <Modal.Body>
                <form className="edit-form">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="formlable" for="inputcompany">
                        {" "}
                        Name <span className="mark-form">*</span>
                      </label>
                      <input
                        type="hidden"
                        onChange={(e) => setOldName(e.target.value)}
                        value={oldname}
                        disabled
                      />
                      <input
                        type="text"
                        value={sitename}
                        onChange={(e) => setName(e.target.value)}
                        class="form-control"
                        placeholder="Enter entity name"
                        readOnly
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label class="formlable" for="inputState">
                        Site type <span className="mark-form">*</span>
                      </label>
                      <Select
                        id="inputState"
                        className=""
                        value={{ label: sitetype, value: sitetype }} // Set the selected value
                        onChange={(selectedOption) => setSiteType(selectedOption.value)}
                        options={
                          getsitetype &&
                          getsitetype.map((status) => ({ label: status, value: status }))
                        }
                        styles={customStyles}
                        placeholder="Select site type"
                        menuPosition="fixed"
                      />
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label class="formlable" for="inputCity">
                        Address Line1*
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputCity"
                        placeholder=""
                        value={address1}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="formlable" for="inputState">
                        Country <span className="mark-form">*</span>
                      </label>
                      <Select
                        className=""
                        value={country ? { label: country, value: country } : null} // Set the selected value
                        onChange={(selectedOption) => setCountry(selectedOption.value)}
                        options={
                          countries && countries.map((status) => ({ label: status, value: status }))
                        }
                        placeholder="Select country"
                        styles={customStyles}
                        menuPosition="fixed"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="formlable" htmlFor="inputCity">
                        State <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputCity"
                        placeholder=""
                        value={state}
                        onChange={(e) => {
                          // Check if the entered value contains only alphabets
                          const onlyAlphabets = /^[A-Za-z\s]+$/;
                          if (
                            onlyAlphabets.test(e.target.value) ||
                            e.target.value === "" ||
                            e.inputType === "deleteContentBackward"
                          ) {
                            // If it's valid, empty, or a backspace event, update the state
                            setState(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div className="form-group col-md-6">
                      <label className="formlable" htmlFor="inputZip">
                        City <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputZip"
                        placeholder=""
                        value={city}
                        onChange={(e) => {
                          // Check if the entered value contains only alphabets
                          const onlyAlphabets = /^[A-Za-z\s]+$/;
                          if (
                            onlyAlphabets.test(e.target.value) ||
                            e.target.value === "" ||
                            e.inputType === "deleteContentBackward"
                          ) {
                            // If it's valid, empty, or a backspace event, update the state
                            setCity(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="formlable" htmlFor="inputZip">
                        Zip/Postal Code <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${postalcodeError ? "is-invalid" : ""}`}
                        id="inputZip"
                        placeholder=""
                        value={postalcode}
                        onChange={handlePostalCodeChange}
                      />
                      {postalcodeError && <div className="invalid-feedback">{postalcodeError}</div>}
                    </div>
                  </div>
                  <div class="form-row">
                    <div className="form-group col-md-6">
                      <label className="formlable" htmlFor="inputZip">
                        Latitude <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputZip"
                        placeholder=""
                        value={lat}
                        onChange={(e) => {
                          // Check if the entered value contains only numeric and decimal characters, including minus sign
                          const onlyNumericAndDecimal = /^-?[0-9]+(\.[0-9]*)?$/;
                          if (
                            onlyNumericAndDecimal.test(e.target.value) ||
                            e.target.value === "" ||
                            e.inputType === "deleteContentBackward"
                          ) {
                            // If it's valid, empty, or a backspace event, update the state
                            setLatitude(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="formlable" htmlFor="inputZip">
                        Longitude <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputZip"
                        placeholder=""
                        value={lng}
                        onChange={(e) => {
                          // Check if the entered value contains only numeric and decimal characters, including minus sign
                          const onlyNumericAndDecimal = /^-?[0-9]+(\.[0-9]*)?$/;
                          if (
                            onlyNumericAndDecimal.test(e.target.value) ||
                            e.target.value === "" ||
                            e.inputType === "deleteContentBackward"
                          ) {
                            // If it's valid, empty, or a backspace event, update the state
                            setLongitude(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="App">
                    <div Class="steps__containt-hader">
                      <h3 class="title allocation-percent">
                        % Allocation to legal entity <span className="mark-form">*</span>
                      </h3>
                    </div>
                    {inputList.map((x, i) => {
                      return (
                        <div className="box">
                          <div class="form-row">
                            <div class="form-group col-md-4">
                              <Select
                                required
                                className=""
                                name="businessunit"
                                placeholder="Select legal entities"
                                menuPosition="fixed"
                                styles={customStyles}
                                value={
                                  x.businessunit
                                    ? { label: x.businessunit, value: x.businessunit }
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    {
                                      target: { name: "businessunit", value: selectedOption.value },
                                    },
                                    i
                                  )
                                }
                                options={
                                  Array.isArray(activele)
                                    ? activele.map((status) => ({
                                        label: status.name,
                                        value: status.name,
                                      }))
                                    : []
                                }
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <input
                                className="ml10 form-control"
                                name="businessunitvalue"
                                placeholder="Ex.60%..."
                                value={x.businessunitvalue}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <div className="btn-box">
                                {inputList.length !== 1 && (
                                  <button
                                    className="mr10 btn btn-danger"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    Remove
                                  </button>
                                )}
                                {inputList.length - 1 === i && (
                                  <button
                                    onClick={handleAddClick}
                                    class="btn btn-primary"
                                    disabled={isButtonDisabled}
                                  >
                                    Add More
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div Class="steps__containt-hader">
                      <h3 class="title allocation-percent">% Allocation to business unit </h3>
                    </div>
                    {bunit.map((x, i) => {
                      return (
                        <div className="box">
                          <div class="form-row">
                            <div class="form-group col-md-4">
                              <Select
                                className=""
                                name="bunit"
                                placeholder="Select business unit"
                                menuPosition="fixed"
                                styles={customStyles}
                                value={x.bunit ? { label: x.bunit, value: x.bunit } : null}
                                onChange={(selectedOption) =>
                                  handleInputChange1(
                                    { target: { name: "bunit", value: selectedOption.value } },
                                    i
                                  )
                                }
                                options={
                                  Array.isArray(activebu)
                                    ? activebu.map((status) => ({
                                        label: status.name,
                                        value: status.name,
                                      }))
                                    : []
                                }
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <input
                                className="ml10 form-control"
                                name="bunitvalue"
                                placeholder="Ex.60%..."
                                value={x.bunitvalue}
                                onChange={(e) => handleInputChange1(e, i)}
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <div className="btn-box">
                                {x.bunit !== "" && x.bunitvalue !== "" && (
                                  <button
                                    type="button"
                                    className="mr10 btn btn-danger"
                                    onClick={() => handleRemoveClick1(i)}
                                  >
                                    Remove
                                  </button>
                                )}
                                {bunit.length - 1 === i && (
                                  <button
                                    type="button"
                                    id="addMore"
                                    onClick={handleAddClick1}
                                    class="btn btn-primary"
                                    disabled={isButtonDisabled1}
                                  >
                                    Add more
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="edit-form modal-buttons">
                  <Button
                    className="btn btn-primary"
                    disabled={loading}
                    onClick={() =>
                      saveData(sitename, address1, city, state, postalcode, country, lat, lng)
                    }
                  >
                    Update
                  </Button>
                  <Button className="btn btn-cancel" onClick={hideEditModal}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
