import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { BlobServiceClient, ContainerClient, BlobClient } from "@azure/storage-blob";
import CryptoJS from "crypto-js";
import { MultiSelect } from "primereact/multiselect";
import { DatePicker } from "antd";
import { BiSolidCalendar } from "react-icons/bi";
import dayjs from "dayjs";
import AADHeader from "./components/AADHeader.js";
import { data } from "jquery";
import { useLocation } from "react-router-dom";

const steps = ["Start", "Materiality", "Module", "Input", "Report"];

export default function Csrd() {
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  let location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [savedReports, setSavedReports] = useState([]);
  const [formData, setFormData] = useState({
    reportName: "",
    completionTimeline: "",
    legalEntity: "",
    startDate: "",
    endDate: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [isToDateEnabled, setIsToDateEnabled] = useState(false);
  const [minToDate, setMinToDate] = useState(null);
  const [name, setName] = useState("");
  const [timeline, setTimeline] = useState("");
  const [timeline1, setTimeline1] = useState("");
  const [legalEntity, setLegalEntity] = useState("");
  const [activelegalentity, setActiveLegalEntity] = useState("");
  const [errors, setErrors] = useState({});

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const convertDate = (dateString) => {
    const date = dayjs(dateString);

    return {
      $D: date.date(),
      $H: date.hour(),
      $L: "en",
      $M: date.month(),
      $W: date.day(),
      $d: date.toDate(),
      $isDayjsObject: true,
      $m: date.minute(),
      $ms: date.millisecond(),
      $s: date.second(),
      $u: undefined,
      $x: {},
      $y: date.year(),
    };
  };

  const searchParams = new URLSearchParams(location.search);
  const filename1 = searchParams.get("file"); // Assuming ?file=filename in the URL
  const url = searchParams.get("url");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (res.status === 200) {
          const responseJson = await res.json();
          const connectionString = responseJson.data;
          const blobServiceClient = new BlobServiceClient(`${connectionString}`);
          // Get the container client from the BlobServiceClient
          const containerClient = blobServiceClient.getContainerClient("ecoprismfilecontainer"); // Leave container name empty as it is in the container URL already
          const blobClient = containerClient.getBlobClient(`${companyid}/${filename1}`);
          // Download the blob content
          const downloadBlockBlobResponse = await blobClient.download(0);
          // Get readable stream and convert to text
          const blob = await downloadBlockBlobResponse.blobBody;
          const downloadedText = await blob.text(); // .text() for retrieving the string content

          // Parse JSON content from the blob
          const jsonData = JSON.parse(downloadedText);
          console.log(jsonData);
          const startDate = convertDate(jsonData.startDate);
          const endDate = convertDate(jsonData.endDate);
          const timeline = convertDate(jsonData.timeline);
          //console.log(startDate);
          setName(jsonData.name);
          setLegalEntity(jsonData.legalEntity);
          setEndDate1(jsonData.endDate);
          setEndDate(dayjs(jsonData.endDate));
          setStartDate(dayjs(jsonData.startDate));
          setStartDate1(jsonData.startDate);
          setTimeline1(timeline);
          setTimeline(dayjs(jsonData.timeline));
          setSelectedDate(dayjs(jsonData.timeline));
          //console.log(timeline);
          const step1 = JSON.stringify({
            name: jsonData.name,
            timeline: jsonData.timeline,
            legalEntity: jsonData.legalEntity,
            startDate: jsonData.startDate,
            endDate: jsonData.endDate,
          });
          localStorage.setItem("step1", step1);
          let step2 = localStorage.getItem("step2");
          let step3 = localStorage.getItem("step3");
          let step4 = localStorage.getItem("step4");
          let answers = localStorage.getItem("answers");
          let answers1 = localStorage.getItem("answers1");
          let tableAnswers = localStorage.getItem("tableAnswers");
          let selectedOptions = localStorage.getItem("selectedOptions");
          let mdrp = localStorage.getItem("mdrp");
          const nameWithoutExtension = filename1.split(".")[0];
          localStorage.setItem("hash", nameWithoutExtension);
          if (step2 || jsonData.materiality) {
            localStorage.setItem("step2", JSON.stringify(jsonData.materiality));
          }
          if (step3 || jsonData.reportingModule) {
            localStorage.setItem("step3", JSON.stringify(jsonData.reportingModule));
          }
          if (step4 || jsonData.generateReport) {
            localStorage.setItem("step4", JSON.stringify(jsonData.generateReport));
          }
          if (answers || jsonData.answers) {
            localStorage.setItem("answers", JSON.stringify(jsonData.answers));
          }
          if (answers1 || jsonData.answers1) {
            localStorage.setItem("answers1", JSON.stringify(jsonData.answers1));
          }
          if (tableAnswers || jsonData.tableAnswers) {
            localStorage.setItem("tableAnswers", JSON.stringify(jsonData.tableAnswers));
          }
          if (selectedOptions || jsonData.selectedOptions) {
            localStorage.setItem("selectedOptions", JSON.stringify(jsonData.selectedOptions));
          }
          if (mdrp || jsonData.mdrp) {
            localStorage.setItem("mdrp", JSON.stringify(jsonData.mdrp));
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error reading blob:", error);
      }
    };
    // Helper function to convert stream to text
    const streamToText = async (readableStream) => {
      const reader = readableStream.getReader();
      let chunks = "";
      let done, value;

      while (!done) {
        ({ done, value } = await reader.read());
        if (value) {
          chunks += new TextDecoder().decode(value);
        }
      }
      return chunks;
    };
    fetchData();
  }, []);
  useEffect(() => {
    const step1 = localStorage.getItem("step1");
    if (step1 === undefined || step1 === null) {
      console.log("Step1 is empty");
    } else {
      const dataStep1 = JSON.parse(step1);
      setName(dataStep1.name);
      if (dataStep1.timeline1 !== "") {
        setTimeline(dayjs(dataStep1.timeline1));
        setSelectedDate(dayjs(dataStep1.timeline1)); // Convert to dayjs object
      }
      setLegalEntity(dataStep1.legalEntity);
      if (dataStep1.startDate1 !== null) {
        setStartDate(dayjs(dataStep1.startDate1));
      }
      if (dataStep1.endDate1 !== null) {
        setEndDate(dayjs(dataStep1.endDate1));
      }
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  async function fetchLegalEntity() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-entities",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setActiveLegalEntity("");
        } else if (responseJson.status == 400) {
          setActiveLegalEntity("");
        } else {
          setActiveLegalEntity(responseJson.data.filter((item) => item.isActive === true));
        }
      } else {
        setActiveLegalEntity("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCheckboxChange = (label, checked) => {
    const updatedCheckboxes = checked
      ? [...formData.selectedCheckboxes, label]
      : formData.selectedCheckboxes.filter((item) => item !== label);

    setFormData({
      ...formData,
      selectedCheckboxes: updatedCheckboxes,
    });
  };

  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : "";
  };

  async function uploadJsonFile(containerName, folderName, fileName, jsonData) {
    try {
      // Fetch SAS token from your API
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;

        // Create BlobServiceClient using the SAS token
        const blobServiceClient = new BlobServiceClient(connectionString);

        // Get or create container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        //await containerClient.createIfNotExists();

        // Get BlobClient for the file
        const blobName = `${folderName}/${fileName}.json`; // Construct blob name with folder path
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        console.log(blobClient);
        // Convert JSON data to ArrayBuffer
        const arrayBuffer = new TextEncoder().encode(JSON.stringify(jsonData));

        // Upload the file content
        const uploadResponse = await blockBlobClient.uploadBrowserData(arrayBuffer.buffer, {
          blobHTTPHeaders: { blobContentType: "application/json" }, // Set content type as JSON
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20, // Number of concurrent requests
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`JSON file "${fileName}" uploaded successfully.`);
          //console.log(`Blob URL: ${blobClient.url}`);
          localStorage.setItem("hash", fileName);
          localStorage.setItem("bloburl", blobClient.url);
          const timelineDate = timeline.toDate();
          const startDateObj = startDate.toDate();
          const endDateObj = endDate.toDate();
          const year = timelineDate.getFullYear();
          const month = String(timelineDate.getMonth() + 1).padStart(2, "0");
          const day = String(timelineDate.getDate()).padStart(2, "0");
          const formattedTimeline = `${year}-${month}-${day}`;

          const yearstartDate = startDateObj.getFullYear();
          const monthstartDate = String(startDateObj.getMonth() + 1).padStart(2, "0");
          const daystartDate = String(startDateObj.getDate()).padStart(2, "0");
          const formattedstartDate = `${yearstartDate}-${monthstartDate}-${daystartDate}`;

          const yearendDate = endDateObj.getFullYear();
          const monthendDate = String(endDateObj.getMonth() + 1).padStart(2, "0");
          const dayendDate = String(endDateObj.getDate()).padStart(2, "0");
          const formattedendDate = `${yearendDate}-${monthendDate}-${dayendDate}`;
          const organizationValue = localStorage.getItem("organization");
          const bloburl = localStorage.getItem("bloburl");
          const username = localStorage.getItem("userName");
          const hash = localStorage.getItem("hash");
          const requestOptions = {
            reportId: hash,
            reportTitle: name,
            companyName: organizationValue,
            companyId: companyid,
            blobStorageLocation: bloburl,
            updatedBy: username,
            reportStatus: "Active",
            updateComment: "Test Comment",
            completionTimeline: formattedTimeline,
            reportingPeriod: formattedstartDate + " to " + formattedendDate,
          };
          const response = await fetch(`https://ecoprismapi.azurewebsites.net/save-report`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestOptions),
          });
          const data = await response.json();
          if (data.success == false) {
          } else if (data.status == 400) {
          } else {
          }
        }

        // Perform any additional operations after successful upload
        // uploadFileName(fileName); // Assuming this is some function you want to call after upload
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading JSON file:", error);
    }
  }
  const validateForm = () => {
    let formErrors = {};
    if (!name) formErrors.reportName = "Report Name is required";
    if (!timeline) formErrors.completionTimeline = "Completion Timeline is required";
    if (!legalEntity) formErrors.legalEntity = "Legal Entity is required";
    if (!startDate) formErrors.startDate = "Start Date is required";
    if (!endDate) formErrors.endDate = "End Date is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const handleSave = async () => {
    if (!validateForm()) return;

    console.log("timeline before save:", timeline);
    console.log("timeline before save:", timeline1);

    const reportingPeriod = {
      startDate: startDate,
      endDate: endDate,
    };

    const completionTimeline = selectedDate || null;

    const newReport = {
      reportName: formData.reportName,
      framework: "CSRD",
      status: "In Progress",
      completionTimeline: completionTimeline,
      reportingPeriod: reportingPeriod,
      creator: "Unknown",
      checkboxes: formData.selectedCheckboxes,
    };

    const existingReports = JSON.parse(localStorage.getItem("savedReports")) || [];
    const updatedReports = [...existingReports, newReport];
    localStorage.setItem("savedReports", JSON.stringify(updatedReports));

    // Convert Day.js objects to native Date objects
    const timelineDate = timeline.toDate();
    const startDateObj = startDate.toDate();
    const endDateObj = endDate.toDate();
    if (
      timelineDate instanceof Date &&
      startDateObj instanceof Date &&
      endDateObj instanceof Date
    ) {
      const year = timelineDate.getFullYear();
      const month = String(timelineDate.getMonth() + 1).padStart(2, "0");
      const day = String(timelineDate.getDate()).padStart(2, "0");
      const formattedTimeline = `${year}-${month}-${day}`;

      const yearstartDate = startDateObj.getFullYear();
      const monthstartDate = String(startDateObj.getMonth() + 1).padStart(2, "0");
      const daystartDate = String(startDateObj.getDate()).padStart(2, "0");
      const formattedstartDate = `${yearstartDate}-${monthstartDate}-${daystartDate}`;

      const yearendDate = endDateObj.getFullYear();
      const monthendDate = String(endDateObj.getMonth() + 1).padStart(2, "0");
      const dayendDate = String(endDateObj.getDate()).padStart(2, "0");
      const formattedendDate = `${yearendDate}-${monthendDate}-${dayendDate}`;

      let step2 = localStorage.getItem("step2");
      let step3 = localStorage.getItem("step3");
      let step4 = localStorage.getItem("step4");
      let answers = localStorage.getItem("answers");
      let answers1 = localStorage.getItem("answers1");
      let tableAnswers = localStorage.getItem("tableAnswers");
      let selectedOptions = localStorage.getItem("selectedOptions");
      let mdrp = localStorage.getItem("mdrp");
      const logo = localStorage.getItem("logo");
      const rdata = {
        name: name,
        timeline: formattedTimeline,
        timeline1: selectedDate,
        legalEntity: legalEntity,
        startDate: formattedstartDate,
        startDate1: startDateObj,
        endDate: formattedendDate,
        endDate1: endDateObj,
        materiality: JSON.parse(step2),
        reportingModule: JSON.parse(step3),
        generateReport: JSON.parse(step4),
        mdrp: JSON.parse(mdrp),
        answers: JSON.parse(answers),
        answers1: JSON.parse(answers1),
        tableAnswers: JSON.parse(tableAnswers),
        selectedOptions: JSON.parse(selectedOptions),
        logo: logo,
      };
      console.log(rdata);
      const reportName = companyid + "_" + name + "_" + legalEntity + "_" + formattedTimeline;
      const hash = CryptoJS.MD5(reportName).toString();
      uploadJsonFile("ecoprismfilecontainer", companyid, hash, rdata);
      localStorage.setItem("step1", JSON.stringify(rdata));
      setSavedReports(updatedReports);

      setFormData({
        reportName: "",
        completionTimeline: "",
        legalEntity: "",
        reportingPeriod: "",
        selectedCheckboxes: [],
      });

      setSelectedDate(null);
      setStartDate(null);
      setEndDate(null);

      // Log data before navigating
      console.log("Data being saved:", newReport);

      navigate("/editmateriality");
    } else {
      console.error("One or more dates are not valid Date objects:", timeline, startDate, endDate);
    }
  };

  const handleMultiSelectChange = (e) => {
    const value = e;

    // Update errors state if no legal entity is selected
    if (value.length === 0) {
      setLegalEntity("");
      console.log(value);
      setErrors((prevErrors) => ({ ...prevErrors, legalEntity: "Legal entity is required" }));
    } else {
      console.log(value);
      setErrors((prevErrors) => ({ ...prevErrors, legalEntity: "" }));
      setLegalEntity(value);
    }
  };

  const handleDateChange = (date, setter) => {
    console.log(date);
    if (!date) {
      return; // Exit early if date is null or undefined
    }
    if (setter === setStartDate) {
      if (endDate && date > endDate) {
        setEndDate(null);
      }
      setStartDate(date);
      setStartDate1(date);
      setIsToDateEnabled(true);
      setMinToDate(date);
    } else if (setter === setEndDate) {
      setEndDate(date);
      setEndDate1(date);
    } else if (setter === setSelectedDate) {
      setSelectedDate(date);

      setTimeline(date); // Ensure timeline is set as a Date object
      setTimeline1(date);
      setFormData({
        ...formData,
        completionTimeline: date,
      });
    } else {
      setter(date);
    }
  };

  useEffect(() => {
    fetchLegalEntity();
  }, []);

  const legalentity = activelegalentity && activelegalentity.map((status) => status.name);

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex justify-content-between">
            <div className="steps__containt-hader">
              <h1 className="title">CSRD</h1>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Link underline="hover" color="inherit" href="/standards">
                    Standards & Frameworks
                  </Link>
                  <Typography color="text.primary">CSRD</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="stepper-box">
              <Stepper activeStep={0} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-name">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <div>
            <div className="row csrd-top">
              <div className="csrd-text col-md-9">
                <div className="row">
                  <div className="col-md-2">
                    <img src="./assets/img/reports/csrd.png" alt="CSRD" />
                  </div>
                  <div className="col-md-10">
                    <h2>Corporate Sustainability Reporting Directive</h2>
                    <p>
                      The Corporate Sustainability Reporting Directive (CSRD) requires companies to
                      report on the impact of corporate activities on the environment and society
                      and requires the audit (assurance) of reported information.​
                      <a
                        href="https://finance.ec.europa.eu/capital-markets-union-and-financial-markets/company-reporting-and-auditing/company-reporting/corporate-sustainability-reporting_en"
                        target="_blank"
                      >
                        &lt;Read More&gt;
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="repository-box">
                  <a href="/repository" target="_blank">
                    <img src="./assets/img/reports/repository.svg" alt="CSRD" />
                  </a>
                  <a href="/repository" target="_blank">
                    <p>
                      Upload your files and links to let our AI bot simplify your CSRD reporting!
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="csrd-form">
              <form>
                <h2>New Report</h2>
                <div className="row gx-5 gy-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="reportName">
                        Report Name <span className="mark-form">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        className={`form-control ${errors.reportName ? "is-invalid" : ""}`}
                        name="reportName"
                        placeholder="Enter the required report name"
                        value={name}
                        disabled
                        onChange={(e) => {
                          const input = e.target.value;
                          const wordCount = input.trim().split(/\s+/).length;

                          if (wordCount <= 20) {
                            setName(input);
                            setErrors((prevErrors) => ({ ...prevErrors, reportName: "" }));
                          } else {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              reportName: "Report name cannot exceed 20 words",
                            }));
                          }
                        }}
                      />

                      {errors.reportName && (
                        <div className="invalid-feedback">{errors.reportName}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="completionTimeline">
                        Completion Timeline <span className="mark-form">*</span>
                      </label>
                      <div className="custom-date-picker date-picker">
                        <DatePicker
                          id="completionTimeline"
                          value={selectedDate}
                          onChange={(date) => {
                            handleDateChange(date, setSelectedDate);
                            setErrors((prevErrors) => ({ ...prevErrors, completionTimeline: "" }));
                          }}
                          className={`calendar-form form-control ${
                            errors.completionTimeline ? "is-invalid" : ""
                          }`}
                          placeholder="Select Date"
                          format="YYYY-MM-DD"
                          disabledDate={(current) =>
                            current && current < new Date(new Date().setHours(0, 0, 0, 0))
                          }
                          allowClear
                          suffixIcon={<BiSolidCalendar />}
                        />
                        {errors.completionTimeline && (
                          <div className="invalid-feedback">{errors.completionTimeline}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h2>Specify the reporting boundary</h2>
                <div className="row gx-5 gy-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="legalEntity">
                        Legal Entity <span className="mark-form">*</span>
                      </label>
                      <MultiSelect
                        id="legalEntity"
                        name="legalEntity"
                        className={`wd-100 custom-multiselect ${
                          errors.legalEntity ? "is-invalid" : ""
                        }`}
                        value={legalEntity}
                        onChange={(e) => {
                          handleMultiSelectChange(e.value);
                        }}
                        options={legalentity}
                        maxSelectedLabels={1}
                        placeholder="Select legal entity"
                        showClear={false} // Add this line to show the clear icon
                        showSelectAll={true} // Show the "Select All" checkbox
                        selectAllLabel="Select All" // Label for the "Select All" checkbox
                        style={{ "--placeholder-font-size": "16px", height: "40px" }}
                      />
                      {errors.legalEntity && (
                        <div className="invalid-feedback">{errors.legalEntity}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="reportingPeriod">
                        Reporting Period <span className="mark-form">*</span>
                      </label>
                      <div className="row gx-2">
                        <div className="col-md-6">
                          {" "}
                          <div className="custom-start-date-picker date-picker">
                            <DatePicker
                              id="startDate"
                              value={startDate}
                              onChange={(date) => {
                                handleDateChange(date, setStartDate);
                                setErrors((prevErrors) => ({ ...prevErrors, startDate: "" }));
                              }}
                              className={`calendar-form form-control ${
                                errors.startDate ? "is-invalid" : ""
                              }`}
                              placeholder="Start Date"
                              suffixIcon={<BiSolidCalendar />}
                            />
                            {errors.startDate && (
                              <div className="invalid-feedback">{errors.startDate}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="custom-end-date-picker date-picker">
                            <DatePicker
                              id="endDate"
                              value={endDate}
                              onChange={(date) => {
                                handleDateChange(date, setEndDate);
                                setErrors((prevErrors) => ({ ...prevErrors, endDate: "" }));
                              }}
                              className={`calendar-form form-control ${
                                errors.endDate ? "is-invalid" : ""
                              }`}
                              placeholder="End Date"
                              disabled={!isToDateEnabled}
                              showToday={false}
                              format={"YYYY-MM-DD"}
                              suffixIcon={<BiSolidCalendar />}
                              disabledDate={
                                (current) => current && current < startDate // Disable dates before startDate
                              }
                            />
                            {errors.endDate && (
                              <div className="invalid-feedback">{errors.endDate}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row justify-content-end pt-3">
              <div className="col-auto">
                <Button
                  href="standards"
                  variant="outlined"
                  className="outline-btn"
                  size="medium"
                  type="button"
                >
                  Cancel
                </Button>
              </div>
              <div className="col-auto">
                <Button
                  onClick={handleSave}
                  className="next-button"
                  variant="contained"
                  size="medium"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
