import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tooltip } from "@mui/material";
import "./table.css";
import { format } from 'date-fns';

const SCFData = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); // State to hold table data
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    supplierName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surveyInitiatedOn: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surveyPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surveyExpiryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surveyStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch or initialize your data here
    const fetchData = async () => {
      try {
        setLoading(true);
        const companyid = localStorage.getItem("companyid");
        var myHeaders = new Headers();
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}listSupplierFootprintSurvey`, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            companyId: companyid,
            includeExpired: false
          },
        });
        if (response.status === 200) {
          const responseJson = await response.json();
          if (responseJson.success === false || responseJson.status === 400) {
            setLoading(false);
          } else {
            setData(responseJson.data);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
      
    };

    fetchData();
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
    setGlobalFilterValue(value);
  };

  const renderHeader1 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <img src="assets/img/search.png" className="search-i" alt="search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return `${rowIndex + 1}.`; // Adds a dot to the serial number
  };

  const formatSurveyPeriod = (rowData) => {
    const fromDate = new Date(rowData.surveyFromDate);
    let toDate = new Date(rowData.surveyToDate);
    if (isNaN(fromDate) || isNaN(toDate)) {
        console.error("Invalid date detected", { surveyFromDate: rowData.surveyFromDate, surveyToDate: rowData.surveyToDate });
        return "Invalid date";
    }
    toDate = new Date(toDate.toISOString().split('T')[0]); // Strip the time part
    const formattedFromDate = format(fromDate, 'MMM-yyyy');
    const formattedToDate = format(toDate, 'MMM-yyyy');
    return `${formattedFromDate} to ${formattedToDate}`;
  };

  const formatSurveyFromDate = (rowData) => {
    let formattedFromDate = new Date(rowData.surveyFromDate);
    formattedFromDate = new Date(formattedFromDate.toISOString().split('T')[0]);
    if (isNaN(formattedFromDate.getTime())) {
        return 'Invalid date';
    }
    return format(formattedFromDate, 'dd MMM yyyy');
  };

  const formatExpiryDate = (rowData) => {
    let expiryDate = new Date(rowData.expirationDate);
    if (isNaN(expiryDate.getTime())) {
        return 'Invalid date';
    }
    expiryDate = new Date(expiryDate.toISOString().split('T')[0]);
    return format(expiryDate, 'dd MMM yyyy');
  };

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader1()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="row mb-4">
            <div className="col-md-8">
              <div className="d-flex flex-column steps__containt-hader">
                <h1 className="title">Supplier Carbon Footprint​</h1>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/forms-apps">
                    Forms & Apps
                  </Link>
                  <Typography color="text.primary">Supplier Carbon Footprint​</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <div className="steps__containt-hader">
                <a className="add-cta" href="/supplier-carbon-footprint-survey">
                  + Start new survey
                </a>
              </div>
            </div>
          </div>
          <div className="survey-content">
            <div className="data-responsive">
              <DataTable
                value={data}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={[
                  "supplierName",
                  "surveyInitiatedOn",
                  "surveyPeriod",
                  "surveyExpiryDate",
                  "surveyStatus",
                  "responseDashboard",
                ]}
                header={header}
                emptyMessage="No data found."
              >
                <Column
                  body={serialNumberBodyTemplate}
                  style={{ width: "3rem", textAlign: "center" }}
                />
                <Column
                  header="Supplier name"
                  field="supplierName"
                  sortable
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Survey initiated on"
                  field="surveyInitiatedOn"
                  sortable
                  filter
                  filterPlaceholder="Filter"
                  body={formatSurveyFromDate}
                />
                <Column
                  header="Survey period"
                  field="surveyPeriod"
                  sortable
                  filter
                  filterPlaceholder="Filter"
                  body={formatSurveyPeriod}
                />

                <Column
                  header="Survey expiry date"
                  field="surveyExpiryDate"
                  sortable
                  filter
                  filterPlaceholder="Filter"
                  body={formatExpiryDate}
                />
                <Column
                  header="Survey status"
                  field="surveyStatus"
                  sortable
                  filter
                  filterPlaceholder="Filter"
                />
                <Column
                  header="Share email reminder"
                  body={(rowData) => (
                    <div className="icon-container">
                      <Tooltip title="Share email reminder" arrow>
                        <img
                          src="./assets/img/formsapps/email-reminder.png"
                          alt="Share email reminder"
                          className="actions-icon"
                          style={{ fontSize: "16px" }}
                        />
                      </Tooltip>
                    </div>
                  )}
                />
                <Column
                  header="View responses"
                  body={(rowData) => (
                    <div className="icon-container">
                      <Tooltip title="View Responses" arrow>
                        <Link to="/employee-commute">
                          <img
                            src="./assets/img/formsapps/view.svg"
                            alt="View Responses"
                            className="actions-icon"
                            style={{ fontSize: "12px", margin: "0", marginTop: "-2px" }}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCFData;
