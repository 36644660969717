import React, { useState, useEffect, useRef } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./table.css";
import Select from "react-select";
import { components } from "react-select";
import { MdRefresh } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import { TabView, TabPanel } from "primereact/tabview";
import { MdNotificationsActive } from "react-icons/md";
import { BlobServiceClient, ContainerClient, BlobClient } from "@azure/storage-blob";


const ESGBenchmarking = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyName: null,
    sector: null,
    country: null,
    competitors: null,
    year: null,
  });
  const [data, setData] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [competitorsOptions, setCompetitorsOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [tab3total, setTab3Total] = useState();
  const [tab2total, setTab2Total] = useState();
  const [searchText, setSearchText] = useState("");
  const [fontSize, setFontSize] = useState(window.innerWidth >= 1400 ? "15px" : "13px");

  const yearOptions = [
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
  ];

  const resetSelections = () => {
    setFormData({
      companyName: null,
      sector: null,
      country: null,
      competitors: [],
      year: null,
    });
    setSearchText(""); // Reset search text
  };

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    // Access the uploaded files
    const files = event.target.files;
    if (files.length > 0) {
      console.log("Files selected:", files);
      // Add logic to handle file upload
    }
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    company: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sector: { value: null, matchMode: FilterMatchMode.CONTAINS },
    country: { value: null, matchMode: FilterMatchMode.CONTAINS },
    year: { value: null, matchMode: FilterMatchMode.CONTAINS },
    revenue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    employees: { value: null, matchMode: FilterMatchMode.CONTAINS },
    scope1: { value: null, matchMode: FilterMatchMode.CONTAINS },
    scope2: { value: null, matchMode: FilterMatchMode.CONTAINS },
    scope3: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalEmissions: { value: null, matchMode: FilterMatchMode.CONTAINS },
    economicEmissionIntensity: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [emissionsData, setEmissionsData] = useState([]);
  const [targetsData, setTargetsData] = useState([]);
  const [reportingData, setReportingData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  // Determine if filters are applied
  const isFilterApplied = Object.values(formData).some(value => value);

  // Filter logic
  const applyFilters1 = (data) => {
    return data.filter(item => {
      const matchCompany = formData.companyName ? item.company_name === formData.companyName : true;
      const matchCountry = formData.country ? item.country === formData.country : true;
      const matchSector = formData.sector ? item.sector === formData.sector : true;
      const matchCompetitors = formData.competitors && formData.competitors.length > 0
      ? formData.competitors.includes(item.company_name)
      : true;
      const matchYear = formData.year ? item.year === formData.year : true;

      // Return item only if it matches all selected filters
      return matchCompany && matchCountry && matchSector && matchYear && matchCompetitors;
    });
  };

  const applyFilters2 = (data) => {
    return data.filter(item => {
      const matchCompany = formData.companyName ? item.company_name === formData.companyName : true;
      const matchCountry = formData.country ? item.Location === formData.country : true;
      const matchSector = formData.sector ? item.Sector === formData.sector : true;
      const matchCompetitors = formData.competitors && formData.competitors.length > 0
      ? formData.competitors.includes(item.company_name)
      : true;
      const matchYear = formData.year ? item.year === formData.year : true;

      // Return item only if it matches all selected filters
      return matchCompany && matchCountry && matchSector && matchYear && matchCompetitors;
    });
  };

  const applyFilters3 = (data) => {
    return data.filter(item => {
      const matchCompany = formData.companyName ? item.company_name === formData.companyName : true;
      const matchCountry = formData.country ? item.countries.replace(/["\[\]\s]/g, '') === formData.country : true;
      const matchSector = formData.sector ? item.sector === formData.sector : true;
      const matchCompetitors = formData.competitors && formData.competitors.length > 0
      ? formData.competitors.includes(item.company_name)
      : true;
      const matchYear = formData.year ? item.year === formData.year : true;

      // Return item only if it matches all selected filters
      return matchCompany && matchCountry && matchSector && matchYear && matchCompetitors;
    });
  };

  // Filtered data for each tab
  const filteredEmissionsData = isFilterApplied ? applyFilters1(emissionsData) : emissionsData;
  const filteredTargetsData = isFilterApplied ? applyFilters2(targetsData) : targetsData;
  const filteredReportingData = isFilterApplied ? applyFilters3(reportingData) : reportingData;

  useEffect(() => {
    // Emissions Benchmarking Data
    const sampleEmissionsData = [
      {
        company: "ABC",
        sector: "Energy",
        country: "USA",
        year: 2023,
        revenue: 120000,
        employees: 50000,
        scope1: 8000000,
        scope2: 4500000,
        scope3: 30000000,
        totalEmissions: 42500000,
        economicEmissionIntensity: 354.17,
      },
      {
        company: "XYZ",
        sector: "Manufacturing",
        country: "Germany",
        year: 2022,
        revenue: 95000,
        employees: 40000,
        scope1: 5000000,
        scope2: 3000000,
        scope3: 25000000,
        totalEmissions: 33000000,
        economicEmissionIntensity: 347.37,
      },
    ];
    const fetchData = async () => {
      let res = await fetch(`${process.env.REACT_APP_BASE_API_URL}emissions-benchmarking`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        console.log(responseJson);
        if (responseJson.success === false) {
          setLoading(false);
          console.log(responseJson);
        } else if (responseJson.status === 400) {
          setLoading(false);
        } else {
          setLoading(false);
          setEmissionsData(responseJson.data);

        }
      } else {
        setLoading(false);
      }
    }
   
    const fetchDatas = async () => {
      setLoading(true);
      try {
        let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (res.status === 200) {
          const responseJson = await res.json();
          const connectionString = responseJson.data;
          
          // Target Tab
          const blobServiceClient = new BlobServiceClient(`${connectionString}`);
          // Get the container client from the BlobServiceClient
          const containerClient = blobServiceClient.getContainerClient("ecoprismfilecontainer"); // Leave container name empty as it is in the container URL already
          const blobClient = containerClient.getBlobClient(`emission_target.json`);
          // Download the blob content
          const downloadBlockBlobResponse = await blobClient.download(0);
          // Get readable stream and convert to text
          const blob = await downloadBlockBlobResponse.blobBody;
          const downloadedText = await blob.text(); // .text() for retrieving the string content
          // Parse JSON content from the blob
          const jsonData = JSON.parse(downloadedText);
          console.log(jsonData);
          setTargetsData(jsonData);

          
          // Emission Tab
          const blobServiceClient1 = new BlobServiceClient(`${connectionString}`);
          // Get the container client from the BlobServiceClient
          const containerClient1 = blobServiceClient1.getContainerClient("ecoprismfilecontainer"); // Leave container name empty as it is in the container URL already
          const blobClient1 = containerClient1.getBlobClient(`esg_emission_data.json`);
          // Download the blob content
          const downloadBlockBlobResponse1 = await blobClient1.download(0);
          // Get readable stream and convert to text
          const blob1 = await downloadBlockBlobResponse1.blobBody;
          const downloadedText1 = await blob1.text(); // .text() for retrieving the string content
          // Parse JSON content from the blob
          const jsonData1 = JSON.parse(downloadedText1);
          console.log(jsonData1);
          setEmissionsData(jsonData1.data);


          // ESG Tab
          const blobServiceClient3 = new BlobServiceClient(`${connectionString}`);
          // Get the container client from the BlobServiceClient
          const containerClient3 = blobServiceClient3.getContainerClient("ecoprismfilecontainer"); // Leave container name empty as it is in the container URL already
          const blobClient3 = containerClient3.getBlobClient(`esg_reporting_data.json`);
          // Download the blob content
          const downloadBlockBlobResponse3 = await blobClient3.download(0);
          // Get readable stream and convert to text
          const blob3 = await downloadBlockBlobResponse3.blobBody;
          const downloadedText3 = await blob3.text(); // .text() for retrieving the string content
          // Parse JSON content from the blob
          const jsonData3 = JSON.parse(downloadedText3);
          console.log(jsonData3);
          setReportingData(jsonData3.data);
          const companyNames1 = jsonData1.data.map(item => item.company_name);
          const companyNames2 = jsonData.map(item => item.company_name);
          const companyNames3 = jsonData3.data.map(item => item.company_name);
          // Merge all company names arrays and remove duplicates
          const allCompanyNames = [...new Set([...companyNames1, ...companyNames2, ...companyNames3])];
          // Get distinct company names by filtering out duplicates
          const distinctCompanyNames = allCompanyNames.filter((name, index) => {
            return allCompanyNames.indexOf(name) === index;
          });
          // Convert to format needed for Select component
          const companyOptionsData = distinctCompanyNames.map(name => ({
            label: name,
            value: name
          }));

          // Update company options state
          setCompanyOptions(companyOptionsData);
          setCompetitorsOptions(companyOptionsData);

          const companySector1 = jsonData1.data.map(item => item.sector);
          const companySector2 = jsonData.map(item => item.Sector);
          const companySector3 = jsonData3.data.map(item => item.sector);
          // Merge all company names arrays and remove duplicates
          const allSector = [...new Set([...companySector1, ...companySector2, ...companySector3])];
          // Get distinct company names by filtering out duplicates
          const distinctSector = allSector.filter((name, index) => {
            return allSector.indexOf(name) === index;
          });
          // Convert to format needed for Select component
          const sectorData = distinctSector.map(name => ({
            label: name,
            value: name
          }));

          // Update company options state
          setSectorOptions(sectorData);


          const companyCountry1 = jsonData1.data.map(item => item.country);
          const companyCountry2 = jsonData.map(item => item.Location);
          const companyCountry3 = jsonData3.data.map(item => item.countries.replace(/["\[\]\s]/g, ''));
          // Merge all company names arrays and remove duplicates
          const allCountry = [...new Set([...companyCountry1, ...companyCountry2, ...companyCountry3])];
          // Get distinct company names by filtering out duplicates
          const distinctCountry = allCountry.filter((name, index) => {
            return allCountry.indexOf(name) === index;
          });
          // Convert to format needed for Select component
          const countryData = distinctCountry.map(name => ({
            label: name,
            value: name
          }));

          // Update company options state
          setCountryOptions(countryData);


          
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error reading blob:", error);
      }
    };
    // Emissions Targets Data
    const sampleTargetsData = [
      {
        company: "ABC",
        sector: "Energy",
        country: "USA",
        netZeroTarget: null,
        netZeroYear: "1993", // Default year value
        nearTermTarget: null,
        nearTermTargetYear: "1993", // Default year value
        targetStatement: "",
        updateDate: "22-11-24", // Default date value
      },
      {
        company: "XYZ",
        sector: "Manufacturing",
        country: "Germany",
        netZeroTarget: null,
        netZeroYear: "1993", // Default year value
        nearTermTarget: null,
        nearTermTargetYear: "1993", // Default year value
        targetStatement: "",
        updateDate: "22-11-24", // Default date value
      },
    ];
    
    const fetchData3 = async (limit = 100, skip = 0) => {
      
      try {
        const url = `${process.env.REACT_APP_BASE_API_URL}emissions-reporting-standards`;
        
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "skip":skip,
            "limit":limit
          },
        });

        if (res.status === 200) {
          const responseJson = await res.json();
          console.log(responseJson);
          if (responseJson.success === false) {
            console.log(responseJson);
          } else if (responseJson.status === 400) {
            console.log(responseJson);
          } else {
            setReportingData(prevData => [...prevData, ...responseJson.data]);
            setTab3Total(responseJson.total_count);
            
            if (responseJson.data.length === limit && (skip + limit) < responseJson.total_count) {
              // If there's more data, fetch the next batch
              setTimeout(() => fetchData3(limit, skip + limit), 100);
            } else {
            }
  
          }
          
        } else {
        }
      } catch (error) {
        console.error("Error fetching emissions benchmarking data:", error);
      }
    };

    // Start fetching data in the background
    fetchDatas();
    
  }, []);

  const onDropdownChange = (e, rowData, field) => {
    const updatedData = targetsData.map((row) => {
      if (row === rowData) {
        return { ...row, [field]: e.value };
      }
      return row;
    });
    setTargetsData(updatedData);
  };

  const onInputChange = (e, rowData, field) => {
    const updatedData = targetsData.map((row) => {
      if (row === rowData) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setTargetsData(updatedData);
  };

  const netZeroTargetTemplate = (rowData) => {
    return (
      <Dropdown
        value={rowData.netZeroTarget}
        options={yesNoOptions}
        onChange={(e) => onDropdownChange(e, rowData, "netZeroTarget")}
        placeholder="Yes/No"
      />
    );
  };

  const nearTermTargetTemplate = (rowData) => {
    return (
      <InputNumber
        value={rowData.nearTermTarget}
        onValueChange={(e) => onInputChange(e, rowData, "nearTermTarget")}
        placeholder="Enter Value"
      />
    );
  };

  const targetStatementTemplate = (rowData) => {
    return (
      <InputText
        value={rowData.targetStatement}
        onChange={(e) => onInputChange(e, rowData, "targetStatement")}
        placeholder="Enter statement"
      />
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
    setGlobalFilterValue(value);
  };

  const onTabChange = (e) => {
    setActiveIndex(e.index); // Update active index on tab change
  };

  const getTabTitle = () => {
    switch (activeIndex) {
      case 0:
        return "Emissions Benchmarking";
      case 1:
        return "Emissions Targets";
      case 2:
        return "ESG Reporting Standards/Frameworks​";
      default:
        return "Emissions Benchmarking";
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <img src="assets/img/search.png" className="search-i" alt="search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#00366D26",
      background: "#F5F5F5",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#407775", // Change border color on hover
      },
      fontSize: fontSize, // Set font size for control (including selected option)
      color: "#707070!important", // Set default text color for the control
      paddingLeft:
        state.selectProps.name === "companyName" && !formData.companyName
          ? "30px"
          : state.selectProps.name === "sector" && !formData.sector
          ? "30px"
          : state.selectProps.name === "region" && !formData.region
          ? "30px"
          : state.selectProps.name === "country" && !formData.country
          ? "30px"
          : state.selectProps.name === "competitors" &&
            (!formData.competitors || formData.competitors.length === 0)
          ? "30px"
          : state.selectProps.name === "year" && !formData.year
          ? "30px"
          : "0px", // Default padding if the field is filled
      position: "relative",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",
      width: "32px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        color: "#707070", // Change border color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the separator
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu is above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#eef1f5" : "white", // Hover and selected background color
      color: state.isSelected ? "#407775" : "#707070", // Change text color for selected options
      fontSize: fontSize, // Use fontSize state variable for options
      "&:active": {
        backgroundColor: "#eef1f5", // Change color on active (click)
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: fontSize, // Set font size for the placeholder
      color: "#707070", // Set placeholder color
    }),
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid  mx-4 my-2">
              <div className="d-flex align-items-center">
                <a className="navbar-brand d-flex align-items-center p-0" href="#home">
                  <img src="assets/img/logo.png" alt="Company Logo" height="30" />
                </a>
                <div
                  className="mx-2"
                  style={{
                    borderLeft: "1px solid #fff",
                    height: "30px",
                    paddingLeft: "20px",
                    opacity: "0.5",
                  }}
                ></div>
                <span className="text-white" style={{ opacity: "0.7", fontSize: "14px" }}>
                  Organization Name
                </span>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-center">
                  <li className="nav-item">
                    <a className="nav-link  text-white" href="#notifications">
                      <MdNotificationsActive
                        className="icon"
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img className="icon mr-2" src="assets/img/User.png" alt="User" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <span className="text-white" style={{ opacity: "0.7", fontSize: "14px" }}>
                      XYZ ltd
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div className="container survey-form scf p-4 p-lg-5">
          <div className="row">
            <div className="col">
              <a href="/" style={{ color: "#407775", fontSize: "14px" }}>
                Home
              </a>
            </div>
          </div>
          <div className="row my-4">
            <div className="col">
              <div className="form-header d-flex justify-content-between">
                <h1 className="title" style={{ color: "#494949" }}>
                  {getTabTitle()}
                </h1>
                <Button
                  className="refresh-cta"
                  onClick={resetSelections}
                  variant="text"
                  startIcon={<MdRefresh />}
                >
                  Reset Filters
                </Button>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row gx-2 gy-3">
                <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">Company Name</label>
                  <div className="input-group position-relative">
                    {!formData.companyName && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}
                    <Select
                      name="companyName"
                      value={
                        formData.companyName
                          ? companyOptions.find((option) => option.value === formData.companyName)
                          : null
                      }
                      options={companyOptions}
                      onChange={(e) => setFormData({ ...formData, companyName: e.value })}
                      placeholder="Search" // Placeholder text
                      isSearchable // Enables search
                      styles={customStyles} // Apply custom styles
                      className="custom-select-with-icon wd-100"
                    />
                  </div>
                </div>

                <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">Sector</label>
                  <div className="input-group position-relative">
                    {!formData.sector && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}
                    <Select
                      name="sector"
                      value={
                        formData.sector
                          ? sectorOptions.find((option) => option.value === formData.sector)
                          : null
                      }
                      options={sectorOptions}
                      onChange={(e) => setFormData({ ...formData, sector: e.value })}
                      placeholder="Search"
                      isSearchable
                      styles={customStyles}
                      className="custom-select-with-icon wd-100"
                    />
                  </div>
                </div>
                {/* <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">Region</label>
                  <div className="input-group position-relative">
                    {!formData.region && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}
                    <Select
                      name="region"
                      value={
                        formData.region
                          ? regionOptions.find((option) => option.value === formData.region)
                          : null
                      }
                      options={regionOptions}
                      onChange={(e) => setFormData({ ...formData, region: e.value })}
                      placeholder="Search"
                      isSearchable
                      styles={customStyles}
                      className="custom-select-with-icon wd-100"
                    />
                  </div>
                </div> */}
                <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">Country</label>
                  <div className="input-group position-relative">
                    {!formData.country && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}
                    <Select
                      name="country"
                      value={
                        formData.country
                          ? countryOptions.find((option) => option.value === formData.country)
                          : null
                      }
                      options={countryOptions}
                      onChange={(e) => setFormData({ ...formData, country: e.value })}
                      placeholder="Search"
                      isSearchable
                      styles={customStyles}
                      className="custom-select-with-icon wd-100"
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">
                    Select Competitors <span style={{ fontSize: "10px" }}>(Upto 5)</span>
                  </label>
                  <div className="input-group position-relative">
                    {(!formData.competitors || formData.competitors.length === 0) && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}

                    <Select
                      name="competitors"
                      value={competitorsOptions.filter((option) =>
                        formData.competitors?.includes(option.value)
                      )}
                      options={competitorsOptions}
                      onChange={(selectedOptions) => {
                        if (selectedOptions.length <= 5) {
                          setFormData({
                            ...formData,
                            competitors: selectedOptions.map((opt) => opt.value),
                          });
                        }
                      }}
                      placeholder="Search"
                      isMulti
                      isSearchable
                      styles={customStyles}
                      className="custom-select-with-icon wd-100"
                      maxMenuHeight={200} // Limit dropdown height if needed
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-4 col-md-6">
                  <label className="formlable">Year</label>
                  <div className="input-group position-relative">
                    {!formData.year && ( // Conditionally render the search icon
                      <span className="search-icon">
                        <img src="assets/img/search.png" alt="Search Icon" className="search-i" />
                      </span>
                    )}
                    <Select
                      name="year"
                      value={
                        formData.year
                          ? yearOptions.find((option) => option.value === formData.year)
                          : null
                      }
                      options={yearOptions}
                      onChange={(e) => setFormData({ ...formData, year: e.value })}
                      placeholder="Search"
                      isSearchable
                      styles={customStyles}
                      className="custom-select-with-icon wd-100"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row py-4">
                <div className="col">
                  <div
                    className="upload-esg"
                    onClick={handleUploadClick}
                    style={{ cursor: "pointer" }}
                  >
                    <IoCloudUploadOutline className="upload-icon" />
                    <span className="formlable">Upload your company reports for analysis​</span>
                  </div>
                  
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the input
                  />
                </div>
              </div> */}
              <div className="row tabs">
                <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                  <TabPanel header="Emissions Benchmarking​">
                    <div className="data-responsive">
                      <DataTable
                        value={filteredEmissionsData}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 25, 50]}
                        scrollable
                        scrollDirection="horizontal"
                        tableStyle={{ minWidth: "120rem" }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        filters={filters}
                        filterDisplay="row"
                        globalFilterFields={[
                          "company_name",
                          "sector",
                          "country",
                          "revenue_reported_year",
                          "revenue",
                          "employees",
                          "scope1_emissions",
                          "scope2_emissions",
                          "scope3_emissions",
                          "total_emissions",
                          "economic_emission_intensity",
                        ]}
                        header={header}
                        emptyMessage="No data found."
                      >
                        <Column
                          field="company_name"
                          header="Company"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="sector"
                          header="Sector"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="country"
                          header="Country"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="revenue_reported_year"
                          header="Year"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "8%" }}
                        />
                        <Column
                          field="revenue"
                          header="Revenue (in million USD)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                          body={(rowData) => {
                            const roundedRevenue = Math.round(rowData.revenue);
                            return roundedRevenue.toLocaleString();
                          }}
                        />
                        {/* <Column
                          field="revenue"
                          header="Revenue (in million USD)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        /> */}
                        <Column
                          field="employees"
                          header="Employees"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="scope1_emissions"
                          header="Scope 1 (tCO2e)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "8%" }}
                        />
                        <Column
                          field="scope2_emissions"
                          header="Scope 2 (tCO2e)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "8%" }}
                        />
                        <Column
                          field="scope3_emissions"
                          header="Scope 3 (tCO2e)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "8%" }}
                        />
                        <Column
                          field="total_emissions"
                          header="Total emissions (tCO2e)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="economic_emission_intensity"
                          header="Economic emission intensity (tCO2e/mn USD)"
                          sortable
                          filter
                          filterPlaceholder="Filter"
                          style={{ width: "20%" }}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                  <TabPanel header="Emissions Targets">
                    <div className="data-responsive">
                      <DataTable
                        value={filteredTargetsData}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 25, 50]}
                        scrollable
                        scrollDirection="horizontal"
                        tableStyle={{ minWidth: "50rem" }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        emptyMessage="No data found."
                      >
                        <Column
                          field="company_name"
                          header="Company"
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column field="Sector" header="Sector" sortable style={{ width: "10%" }} />
                        <Column
                          field="Location"
                          header="Country"
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="net_zero_committed"
                          header="Net Zero target"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="net_zero_year"
                          header="Net Zero year"
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="near_term_target_status"
                          header="Near-term target"
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="near_term_target_year"
                          header="Near-term target year"
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="target_classification"
                          header="Target statement"
                          style={{ width: "20%" }}
                        />
                        <Column
                          field="updateDate"
                          header="Update date"
                          sortable
                          style={{ width: "10%" }}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                  <TabPanel header="ESG Reporting Standards/Frameworks​​">
                    <div className="data-responsive">
                      <DataTable
                        value={filteredReportingData}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 25, 50]}
                        scrollable
                        scrollDirection="horizontal"
                        tableStyle={{ minWidth: "50rem" }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        emptyMessage="No data found."
                      >
                        <Column
                          field="company_name"
                          header="Company"
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column field="sector" header="Sector" sortable style={{ width: "10%" }} />
                        {/* <Column
                          field="countries"
                          header="Country"
                          sortable
                          style={{ width: "10%" }}
                        /> */}
                        <Column
                          field="countries"
                          header="Country"
                          body={(rowData) => rowData.countries.replace(/["\[\]\s]/g, '')}
                          sortable
                          style={{ width: "10%" }}
                        />
                        <Column
                          field="reporting_standards"
                          header="Reporting Standards/Regulations Followed"
                          sortable
                          style={{ width: "35%" }}
                        />
                        <Column
                          field="sustainability_focus_areas"
                          header="Key Sustainability Focus Areas"
                          sortable
                          style={{ width: "35%" }}
                        />
                      </DataTable>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESGBenchmarking;
