import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Paper from "@mui/material/Paper";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import { useEffect, useState } from "react";
import "./Spinner.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "react-bootstrap";
import { DatePicker } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { appInsights } from "./AppInsights";
import moment from "moment";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Paginator } from "primereact/paginator";
import Select from "react-select";
import { BiSolidCalendar } from "react-icons/bi";
import AADHeader from "./components/AADHeader.js";
import dayjs from 'dayjs';

export default function Home() {
  const username = localStorage.getItem("userName");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const slParam = urlSearchParams.get("sl");
  const esg = urlSearchParams.get("esg");
  var myHeaders = new Headers();
  myHeaders.append("username", username);
  myHeaders.append("sl", slParam);
  myHeaders.append("esg", esg);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [reporthistory, setReportHistory] = React.useState("");
  const [reporthistoryheader, setReportHistoryHeader] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [supplier, setSupplier] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [startdate, setStartDate] = React.useState("");
  const [enddate, setEndDate] = React.useState("");
  const [sup, setSup] = React.useState("");
  const [own, setOwn] = React.useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const reset = async () => {
    setOwn("");
    setSup("");
    setSelectedDate("");
    setSelectedDate1("");
    const data = localStorage.getItem("report");
    const parsedData = JSON.parse(data);
    setReportHistory(parsedData);
  };
  // Handle the search of the item
  const searchData = async () => {
    const data = localStorage.getItem("report");
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup === "" &&
      own === ""
    ) {
      const parsedData = JSON.parse(data);
      setReportHistory(parsedData);
    }
    if (selectedDate !== "" && selectedDate1 !== "" && sup === "" && own === "") {
      
      const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
      const endDate = dayjs(selectedDate1).format('YYYY-MM-DD');
      if (data) {
        const parsedData = JSON.parse(data);
        
        const filteredData = parsedData.filter((item) => {
          console.log(item.StartDate);
          const reportStartDate = dayjs(item.StartDate).format('YYYY-MM-DD');
          const reportEndDate = dayjs(item.EndDate).format('YYYY-MM-DD');
          return reportStartDate >= startDate && reportEndDate <= endDate;
        });
        setReportHistory(filteredData);
      }
    }
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup !== "" &&
      own === ""
    ) {
      if (data) {
        console.log("Sup");
        const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
        const endDate = dayjs(selectedDate1).format('YYYY-MM-DD');
        const parsedData = JSON.parse(data);
        const filteredData = parsedData.filter((item) => {
          const reportStartDate = dayjs(item.StartDate).format('YYYY-MM-DD');
          const reportEndDate = dayjs(item.EndDate).format('YYYY-MM-DD');
          return (
            reportStartDate >= startDate &&
            reportEndDate <= endDate &&
            item.SupplierName.toLowerCase() === sup.toLowerCase()
          );
        });
        setReportHistory(filteredData);
      }
    }
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup !== "" &&
      !own == ""
    ) {
      if (data) {
        console.log("ALL");
        const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
        const endDate = dayjs(selectedDate1).format('YYYY-MM-DD');
        const parsedData = JSON.parse(data);
        const filteredData = parsedData.filter((item) => {
          const reportStartDate = dayjs(item.StartDate).format('YYYY-MM-DD');
          const reportEndDate = dayjs(item.EndDate).format('YYYY-MM-DD');

          return (
            reportStartDate >= startDate &&
            reportEndDate <= endDate &&
            item.SupplierName.toLowerCase() === sup.toLowerCase() &&
            item.email === own
          );
        });
        setReportHistory(filteredData);
      }
    }
    
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup === "" &&
      own !== ""
    ) {
      console.log(own);
      console.log("Own 5");
      if (data) {
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        const filteredData = parsedData.filter((item) => {
          return item.email === own;
        });
        setReportHistory(filteredData);
      }
    }
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup !== "" &&
      own === ""
    ) {
      console.log(sup);
      if (data) {
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        const filteredData = parsedData.filter((item) => {
          return item.SupplierName.toLowerCase() === sup.toLowerCase();
        });
        setReportHistory(filteredData);
      }
    }
    if (
      (selectedDate === "" || selectedDate === null) &&
      (selectedDate1 === "" || selectedDate1 === null) &&
      sup !== "" &&
      own !== ""
    ) {
      console.log("Supplier=" + sup);
      if (data) {
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        const filteredData = parsedData.filter((item) => {
          return item.SupplierName.toLowerCase() === sup.toLowerCase() && item.email === own;
        });
        setReportHistory(filteredData);
      }
    }
    if (
      selectedDate !== "" &&
      selectedDate !== null &&
      selectedDate1 !== "" &&
      selectedDate1 !== null &&
      sup !== "" &&
      own !== ""
    ) {
      console.log(sup);
      console.log(selectedDate);
      if (data) {
        const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
        const endDate = dayjs(selectedDate1).format('YYYY-MM-DD');
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        const filteredData = parsedData.filter((item) => {
          const reportStartDate = dayjs(item.StartDate).format('YYYY-MM-DD');
          const reportEndDate = dayjs(item.EndDate).format('YYYY-MM-DD');
          return (
            reportStartDate >= startDate &&
            reportEndDate <= endDate &&
            item.SupplierName.toLowerCase() === sup.toLowerCase() &&
            item.email === own
          );
        });
        setReportHistory(filteredData);
      }
    }
    if (
      selectedDate !== "" &&
      selectedDate !== null &&
      selectedDate1 !== "" &&
      selectedDate1 !== null &&
      sup === "" &&
      own !== ""
    ) {
      console.log(sup);
      console.log(selectedDate);
      if (data) {
        const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
        const endDate = dayjs(selectedDate1).format('YYYY-MM-DD');
        const parsedData = JSON.parse(data);
        console.log(parsedData);
        const filteredData = parsedData.filter((item) => {
          const reportStartDate = dayjs(item.StartDate).format('YYYY-MM-DD');
          const reportEndDate = dayjs(item.EndDate).format('YYYY-MM-DD');
          return (
            reportStartDate >= startDate &&
            reportEndDate <= endDate &&
            item.email === own
          );
        });
        setReportHistory(filteredData);
      }
    }
  };

  async function fetchHistory() {
    setLoading(true);

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/report-history-dynamic",
        requestOptions
      );
      const data = await response.json();
      if (data.success == false) {
        setLoading(false);
      } else if (data.status == 400) {
        setLoading(false);
      } else {
        setLoading(false);
        setReportHistory(data.data.records);
        setReportHistoryHeader(data.data.headers);
        localStorage.setItem("report", JSON.stringify(data.data.records));
        setIsLoading(false);
        
        //Filter Unique Owner
        const uniqueOwner = new Set();
        const uniqueSupplier = new Set();
        const cdata = data.data.records;
        console.log(cdata);
        data.data.records.forEach((data) => {
          uniqueOwner.add(data.email);
          // Check if SupplierName exists before adding
          if (data.SupplierName) {
            uniqueSupplier.add(data.SupplierName.toLowerCase());
          }
        });

        const distinctOwner = Array.from(uniqueOwner);
        setOwner(distinctOwner);
        const distinctSupplier = Array.from(uniqueSupplier);
        const capitalizedArray = distinctSupplier.map(
          (value) => value.charAt(0).toUpperCase() + value.slice(1)
        );
        setSupplier(capitalizedArray);
      }
    } catch (error) {
      setLoading(false);
      setIsLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    appInsights.trackPageView();
    fetchHistory();
  }, []);

  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
  };

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input
        type="text"
        value={value}
        readOnly
        placeholder="Start date"
        className="date-input reporthistory-date"
      />
      <img src="assets/img/reports/calendar.png" className="calendar-icon" />
    </div>
  );
  const CustomDatePickerInput1 = ({ value, onClick }) => (
    <div className="date-input-container" onClick={onClick}>
      <input
        type="text"
        value={value}
        readOnly
        placeholder="End date"
        className="date-input reporthistory-date"
      />
      <img src="assets/img/reports/calendar.png" className="calendar-icon" />
    </div>
  );
  const onPageChange = (event) => {
    setPage(event.page);
    setRowsPerPage(event.rows); // Update rows per page
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      borderRadius: "5px",
      minHeight: "35px",
      fontFamily: "'Poppins', sans-serif",
      border: "1px solid #ebe9e9",
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0px",
      color: "#707070",
      background: "#f5f5f5",
      alignItems: "flex-start",
      height: "35px",
      width: "100%",
      boxShadow: state.isFocused ? "0 0 0 0px #fff" : null,
      "&:hover": {
        borderColor: "#40777526",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#707070",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#707070",

      "&:hover": {
        color: "#707070",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#ADA9A9" : state.isFocused ? "#e9ecef" : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#707070" : "black",
      "&:active": {
        backgroundColor: "#00000029",
      },
    }),
  };
  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9">
              <div Class="steps__containt-hader">
                <h3 class="title">Report History</h3>
                <div role="presentation">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                  >
                    <Link
                      to="/allconfiguration"
                      underline="hover"
                      color="inherit"
                      className="breadcrumbs-font"
                    >
                      Configuration
                    </Link>
                    <Typography color="text.primary">Report History</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div Class="steps__containt-hader btalignleft">
                <button className="btn btn-search" onClick={reset}>
                  Reset filter
                </button>
              </div>
            </div>
          </div>
          <div class="container">{message}</div>
          <div class="flex  mb-10">
            <div Class="col-md-2">
              <DatePicker
                id="startDate"
                className="calendar-form1"
                value={selectedDate}
                onChange={handleDateChange}
                placeholder="Start Date"
                suffixIcon={<BiSolidCalendar />}
              />
              {/* <Calendar
                id="startDate"
                className="calendar-form1"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="Start Date"
                customInput={<CustomDatePickerInput />}
              />
              <img
                src="./assets\img\reports\calendar.png"
                className="calendar-icon calendar-report"
                alt="Calendar Icon"
                onClick={() => document.getElementById("startDate").querySelector("input").focus()}
              /> */}
              {/* <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDatePickerInput />}
              /> */}
            </div>
            <div Class="col-md-2">
              <DatePicker
                id="endDate"
                value={selectedDate1}
                className="calendar-form1"
                onChange={handleDateChange1}
                placeholder="End Date"
                // disabled={!isToDateEnabled}
                showToday={false}
                format={"YYYY-MM-DD"}
                suffixIcon={<BiSolidCalendar />}
                disabledDate={(current) => current && current < selectedDate}
              />
              {/* <Calendar
                id="endDate"
                className="calendar-form1"
                value={selectedDate1}
                onChange={(e) => setSelectedDate1(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="End Date"
              />
              <img
                src="./assets\img\reports\calendar.png"
                className="calendar-icon calendar-report"
                alt="Calendar Icon"
                onClick={() => document.getElementById("endDate").querySelector("input").focus()}
              /> */}
              {/* <DatePicker
                selected={selectedDate1}
                onChange={handleDateChange1}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDatePickerInput1 />}
              /> */}
            </div>
            <div class="col-md-2">
              <Select
                className=""
                value={sup ? { label: sup, value: sup } : null}
                onChange={(selectedOption) => setSup(selectedOption.value)}
                options={supplier && supplier.map((datas) => ({ label: datas, value: datas }))}
                placeholder="Supplier"
                styles={customStyles}
              />
            </div>
            <div class="col-md-4">
              <Select
                className=""
                value={own ? { label: own, value: own } : null}
                onChange={(selectedOption) => setOwn(selectedOption.value)}
                options={owner && owner.map((datas) => ({ label: datas, value: datas }))}
                placeholder="Owner"
                styles={customStyles}
              />
            </div>

            <div Class="col-md-2">
              <button className="btn btn-search1" onClick={searchData}>
                {" "}
                <img src="assets/img/search.png" className="search-i-reporthistory" />
                Search
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {reporthistoryheader &&
                      reporthistoryheader.map((row, index) => (
                        <TableCell key={index} align="left">
                          {row}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reporthistory &&
                    reporthistory
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, rowIndex) => (
                        <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          key={rowIndex}
                        >
                          {Object.values(row).map((cell, cellIndex) => (
                            <TableCell key={cellIndex} align="left" component="th" scope="row">
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Paginator
                first={page * rowsPerPage}
                rows={rowsPerPage}
                totalRecords={reporthistory.length}
                rowsPerPageOptions={[10, 20, 25, 50]}
                onPageChange={onPageChange}
                template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
