import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import DeleteCsrd from "./components/DeleteCsrd";
import "./table.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

function ReportTable({ savedReports }) {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportTitle: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    completionTimeline: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportingPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updatedBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deletename, setDeleteName] = useState(null);
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");

  useEffect(() => {
    fetchReports();
  }, []);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "";
  };

  const handleDelete = (index) => {
    const updatedReports = [...reports];
    updatedReports.splice(index, 1);
    setReports(updatedReports);
    localStorage.setItem("savedReports", JSON.stringify(updatedReports));
  };

  const showDeleteModal = (id,name) => {
    console.log(name);
    setDeleteName(id);
    setDeleteMessage("Are you sure you want to delete this report " + name + "?");
    setDisplayConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };


  // Handle the actual deletion of the item
  const submitDelete = async (deletename,name) => {
    setLoading(true);
    console.log(deletename);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/deleteReport", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          companyid: companyid,
          reportid: deletename
        },
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                   <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
          setDisplayConfirmationModal(false);
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;line-height: 20px;">Report deleted successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
          fetchReports();
          setDisplayConfirmationModal(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error in delete report</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <img src="assets/img/search.png" className="search-i" alt="search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  async function fetchReports() {
    const companyid = localStorage.getItem("companyid");
    var myHeaders = new Headers();
    myHeaders.append("companyid", companyid);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setLoading(true);
    try {
      setLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/allreports",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success === false) {
          console.log(responseJson);
          setReports([]);
          setLoading(false);
        } else if (responseJson.status === 400) {
          setReports([]);
          setLoading(false);
        } else {
          setReports(responseJson.data);
          setLoading(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Something Went Wrong</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setReports([]);
        setLoading(false);
      }

      
    } catch (error) {
      setLoading(false);
      setLoading(false);
      console.error(error);
    }
  }

  return (
    <div>
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div className="datatable-responsive">
        <DataTable
          value={reports}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          dataKey="id"
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            "reportTitle",
            "reportStatus",
            "completionTimeline",
            "reportingPeriod",
            "updatedBy",
          ]}
          header={header}
          emptyMessage="No data found."
        >
          <Column
            header=""
            body={(rowData, rowIndex) => (
              <div>
                <Tooltip title="Edit" arrow>
                <Link to={`/editcsrd?url=${rowData.blobStorageLocation}&file=${rowData.fileName}`}>
                  <img
                    src="./assets/img/reports/edit.png"
                    alt="Edit Icon"
                    className="edit-icon actions-icon"
                  />
                </Link>
                </Tooltip>
                <Tooltip title="Status" arrow>
                  <Link to="/reportDashboard">
                    <img
                      src="./assets/img/reports/Status.svg"
                      alt="Reports Icon"
                      className="reports-icon actions-icon"
                    />
                  </Link>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <img
                    src="./assets/img/reports/delete.png"
                    alt="Delete Icon"
                    className="delete-icon actions-icon"
                    onClick={() => showDeleteModal(rowData.rowKey,rowData.reportTitle)}
                  />
                </Tooltip>
              </div>
            )}
            style={{ minWidth: "8rem", paddingLeft: "30px" }}
          />
          <Column
            sortable
            field="reportTitle"
            header="Reports"
            filter
            filterPlaceholder="Filter"
            style={{ minWidth: "8rem" }}
          />
          
          <Column
            sortable
            field="reportStatus"
            header="Status"
            filter
            filterPlaceholder="Filter"
            style={{ minWidth: "8rem",color:'#FAB430' }}
          />

          <Column
            sortable
            field="completionTimeline"
            header="Completion Timeline"
            filter
            filterPlaceholder="Filter"
            style={{ minWidth: "8rem" }}
          />

          <Column
            sortable
            field="reportingPeriod"
            header="Reporting Period"
            filter
            filterPlaceholder="Filter"
            style={{ minWidth: "8rem" }}
          />
          
          <Column
            sortable
            field="updatedBy"
            header="Creator"
            filter
            filterPlaceholder="Filter"
            style={{ minWidth: "10rem" }}
          />
        </DataTable>
      </div>
      
      <div class="container pd-0">
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <DeleteCsrd
          confirmModal={submitDelete}
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          message={deleteMessage}
          deletename={deletename}
        />
      </div>
    </div>
  );
}

export default ReportTable;
