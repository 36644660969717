import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BlobServiceClient } from "@azure/storage-blob";
import { useNavigate } from "react-router-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "./components/pdf.js";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Viewer,Worker  } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pdf } from '@react-pdf/renderer';




const steps = ["Start", "Materiality", "Module", "Input", "Report"];

export default function PreviewReport() {
  
  const username = localStorage.getItem("userName");
  const companyid = localStorage.getItem("companyid");
  const organizationValue = localStorage.getItem("organization");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [pdffileUrl, setPdfFileUrl] = useState(null);
  const [formData, setFormData] = useState({
    reportTitle: "",
    companyName: "",
    reportingYear: "",
    fileUrl: "",
  });
  const step1 = localStorage.getItem("step1");
  const reportName = JSON.parse(step1);
  const [showPDF, setShowPDF] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [reportData, setReportData] = useState(JSON.parse(localStorage.getItem("step4")) || []);
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      fileUrl: URL.createObjectURL(file),
    });
    setSelectedFile(file);
    setFileUrl(URL.createObjectURL(file));
    console.log(URL.createObjectURL(file));
    uploadFile("ecoprismfilecontainer", companyid, file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFormData({
        ...formData,
        fileUrl: URL.createObjectURL(file),
      });
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      uploadFile("ecoprismfilecontainer", companyid, file);
      setFileUploaded(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  async function uploadJsonFile(containerName, folderName, fileName, jsonData) {
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;

        const blobServiceClient = new BlobServiceClient(connectionString);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobName = `${folderName}/${fileName}.json`;
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const arrayBuffer = new TextEncoder().encode(JSON.stringify(jsonData));
        const uploadResponse = await blockBlobClient.uploadBrowserData(arrayBuffer.buffer, {
          blobHTTPHeaders: { blobContentType: "application/json" },
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`JSON file "${fileName}" uploaded successfully.`);
          console.log(`Blob URL: ${blobClient.url}`);
          localStorage.setItem("bloburl", blobClient.url);
        }
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading JSON file:", error);
    }
  }

  async function uploadFile(containerName, folderName, file) {
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status === 200) {
        const responseJson = await res.json();
        const connectionString = responseJson.data;

        const blobServiceClient = new BlobServiceClient(connectionString);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobName = `${folderName}/${file.name}`;
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const uploadResponse = await blockBlobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type },
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          onProgress: (ev) => console.log(ev.loadedBytes),
        });
        if (uploadResponse) {
          console.log(`File "${file.name}" uploaded successfully.`);
          console.log(`Blob URL: ${blobClient.url}`);
          localStorage.setItem("bloburl", blobClient.url);
          localStorage.setItem("logo", file.name);
        }
      } else {
        console.error("Failed to fetch SAS token.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  const validateForm = () => {
    const newErrors = {};
    if (!formData.reportTitle) newErrors.reportTitle = "Report Title is required";
    if (!formData.companyName) newErrors.companyName = "Company Name is required";
    if (!formData.reportingYear) newErrors.reportingYear = "Reporting Year is required";
    if (!formData.fileUrl) newErrors.fileUrl = "Logo is required";

    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);

    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const step4 = localStorage.getItem("step4");
    const answers = localStorage.getItem("answers");
    const answers1 = localStorage.getItem("answers1");
    const tableanswers = localStorage.getItem("tableAnswers");
    const selectedOptions = localStorage.getItem("selectedOptions");
    const hash = localStorage.getItem("hash");
    const organizationValue = localStorage.getItem("organization");
    const username = localStorage.getItem("userName");
    const logo = localStorage.getItem("logo");
    const parseData = JSON.parse(step1);

    const rdata = {
      name: parseData.name,
      timeline: parseData.timeline,
      legalEntity: parseData.legalEntity,
      startDate: parseData.startDate,
      endDate: parseData.endDate,
      materiality: JSON.parse(step2),
      reportingModule: JSON.parse(step3),
      generateReport: JSON.parse(step4),
      answers: JSON.parse(answers),
      answers1: JSON.parse(answers1),
      tableAnswers: JSON.parse(tableanswers),
      selectedOptions: JSON.parse(selectedOptions),
      logo: logo,
    };
    

    
    uploadJsonFile("ecoprismfilecontainer", companyid, hash, rdata);
    // if (selectedFile) {
    //   await uploadFile("ecoprismfilecontainer", companyid, selectedFile);
    // }

    setSuccessMessage("Data Updated Successfully");
    setLoading(false);
    setShowPDF(true);
  };

  const handleRemoveImage = () => {
    setFileUrl(null); // Assuming you are using useState to manage fileUrl
    setFileUploaded(false);
  };

  

  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div className="container formpd">
          <div className="flex justify-content-between">
            <div className="steps__containt-hader">
              <h3 className="title">{reportName.name}</h3>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Link underline="hover" color="inherit" href="/standards">
                    Standards & Frameworks
                  </Link>
                  <Link underline="hover" color="inherit" href="/csrd">
                    CSRD
                  </Link>
                  <Link underline="hover" color="inherit" href="/GenerateReport">
                    {reportName.name}
                  </Link>
                  <Typography color="text.primary">Preview Report</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="stepper-box">
              <Stepper activeStep={4} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-name">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <div>
            <div className="button-box d-flex justify-content-end">
              <Button
                onClick={() => navigate("/GenerateReport")}
                variant="outlined"
                className="module-btn"
                size="medium"
                sx={{ marginRight: "15px" }}
              >
                <WestIcon fontSize="small" sx={{ marginRight: "4px" }} />
                Input
              </Button>
              <Button
                onClick={() => navigate("/reportDashboard")}
                variant="outlined"
                className="module-btn"
                size="medium"
                sx={{ marginRight: "15px" }}
              >
                <img src="/assets/img/reports/Status.svg" style={{ marginRight: "4px" }} />
                Status
              </Button>
              <Button onClick={handleSave} variant="outlined" className="module-btn" size="medium">
                Generate Report
                <EastIcon fontSize="small" sx={{ marginLeft: "4px" }} />
              </Button>
            </div>
            <div className="col-md-12 mt-4 pd-0">
              <form action="">
                <div className="form-row">
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                </div>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="reportTitle">
                        Report Title
                      </label>
                      <input
                        type="text"
                        required
                        className={`form-control wd-85 ${errors.reportTitle ? "is-invalid" : ""}`}
                        name="reportTitle"
                        placeholder="Enter the report title"
                        value={formData.reportTitle}
                        onChange={(e) => {
                          const input = e.target.value;
                  
                          if (input.length <= 20) {
                            handleFormChange(e)
                          } else {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              reportTitle: "Report title cannot exceed 20 characters",
                            }));
                          }
                        }}
                      />
                      {errors.reportTitle && (
                        <div className="text-danger">{errors.reportTitle}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="formlable" htmlFor="companyName">
                        Company Name
                      </label>
                      <input
                        type="text"
                        required
                        className={`form-control wd-85 ${errors.companyName ? "is-invalid" : ""}`}
                        name="companyName"
                        placeholder="Enter Company name"
                        value={formData.companyName}
                        onChange={handleFormChange}
                      />
                      {errors.companyName && (
                        <div className="text-danger">{errors.companyName}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="formlable" htmlFor="reportingYear">
                        Reporting Year
                      </label>
                      <input
                        type="text"
                        required
                        className={`form-control wd-85 ${errors.reportingYear ? "is-invalid" : ""}`}
                        name="reportingYear"
                        placeholder="Enter year"
                        value={formData.reportingYear}
                        onChange={handleFormChange}
                      />
                      {errors.reportingYear && (
                        <div className="text-danger">{errors.reportingYear}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="formlable" htmlFor="companyLogo">
                        Company Logo
                      </label>
                      <div className="dropzone">
                        <input
                          type="file"
                          id="companyLogo"
                          accept="image/*"
                          name="fileUrl"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="companyLogo"
                          className="upload-box"
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                        >
                          <div className="upload-cont col-md-7">
                            <img src="/assets/img/reports/Upload.svg" width="50" />
                            <span className="upload-text">
                              Upload logo or drag and drop the file here
                            </span>
                          </div>
                          <div className={`file-cont ${fileUploaded ? "col-md-5" : ""}`}>
                            {fileUrl && (
                              <div className="file-preview d-flex align-items-center">
                                <img src={fileUrl} alt="Company Logo" style={{ width: "100%" }} />
                                <IconButton
                                  aria-label="remove"
                                  onClick={handleRemoveImage}
                                  style={{
                                    position: "absolute",
                                    background: "#fff",
                                    opacity: "0.7",
                                    top: "5px",
                                    right: "5px",
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </label>
                        <div>
                          <p style={{ color: "#707070", fontWeight: "500", fontSize: "12px" }}>
                            Max file size: 2 MB
                          </p>
                        </div>
                        {errors.fileUrl && <div className="text-danger">{errors.fileUrl}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row justify-content-end pt-3">
              <div className="col-auto">
                <Button
                  onClick={handleSave}
                  className="next-button"
                  variant="contained"
                  size="medium"
                  sx={{ width: "auto", padding: " 0 30px !important", marginBottom: "30px" }}
                >
                  Preview Report
                </Button>
                {showPDF && (
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        reportData={reportData}
                        reportTitle={formData.reportTitle}
                        reportingYear={formData.reportingYear}
                        companyLogo={fileUrl}
                      />
                    }
                    fileName={formData.reportTitle}
                  >
                    <Button
                      className="next-button"
                      variant="contained"
                      size="medium"
                      sx={{
                        width: "auto",
                        padding: " 0 30px !important",
                        marginBottom: "30px",
                        marginLeft: "10px",
                      }}
                    >
                      Download Report
                    </Button>
                  </PDFDownloadLink>
                )}
              </div>
            </div>
            {showPDF && (
              <div style={{ width: "100%", height: "100vh" }}>
                <PDFViewer 
                  style={{ width: "100%", height: "100%" }} 
                  showToolbar={false}
                  iframeAttributes={{
                    frameBorder: "0",
                    scrolling: "no",
                  }}
                >
                  <MyDocument
                    reportData={reportData}
                    reportTitle={formData.reportTitle}
                    reportingYear={formData.reportingYear}
                    companyLogo={fileUrl}
                  />
                </PDFViewer>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
