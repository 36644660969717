import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { RadioButton } from "primereact/radiobutton";

import "./table.css";

const SCFEnergyEmissions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const scfStep1 = JSON.parse(localStorage.getItem("scfStep1"));
  const [data, setData] = useState([
    {
      productOrService: null,
      carbonFootprint: "",
      source: null,
      unit: "",
      accuracy: null,
    },
  ]);

  const [formData, setFormData] = useState({
    country: "",
    emissionFactor: "",
  });

  const [errors, setErrors] = useState({});

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productOrService: { value: null, matchMode: FilterMatchMode.CONTAINS },
    carbonFootprint: { value: null, matchMode: FilterMatchMode.CONTAINS },
    source: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    accuracy: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // Initialize your data with empty strings instead of null
      // Retrieve data from localStorage
      const scfStep1 = JSON.parse(localStorage.getItem("scfStep1"));
      // Check if scfStep2 exists in localStorage
      const scfStep2 = JSON.parse(localStorage.getItem("scfStep2"));
      
      // If scfStep2 exists and has rowData, use it to populate the data
      if (scfStep2 && scfStep2.rowData && scfStep2.rowData.length > 0) {
        setData(scfStep2.rowData);
        // Also set the emissionFactor if it exists
        if (scfStep2.emissionFactor) {
          setFormData(prevData => ({
            ...prevData,
            emissionFactor: scfStep2.emissionFactor
          }));
        }
      } 
      else{ 
        if (scfStep1 && scfStep1.rowData) {
          const sampleData = scfStep1.rowData.map(item => ({
            productOrService: item.productOrServiceName,
            carbonFootprint: "",
            source: "",
            unit: "",
            accuracy: "",
          }));
          setData(sampleData);
        } else {
          // Fallback to empty data if no localStorage data found
          const sampleData = [
            {
              productOrService: "",
              carbonFootprint: "",
              source: "",
              unit: "",
              accuracy: "",
            }
          ];
          setData(sampleData);
        }
      }
    };

    fetchData();
  }, []);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
    setGlobalFilterValue(value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.emissionFactor) newErrors.emissionFactor = "Please select an option";
    setErrors(newErrors);
    return newErrors;
  };

  const handleSave = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    console.log(formData.emissionFactor);
    if(formData.emissionFactor === "true"){
      
      console.log(data);
    }
    const scfStep2 = {
      rowData: data,
      emissionFactor:formData.emissionFactor
    };
    
    localStorage.setItem("scfStep2",JSON.stringify(scfStep2)); 
    navigate(`/energy-emissions-scope`);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <img src="assets/img/search.png" className="search-i" alt="search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const productOrServiceOptions = [
    { label: "Product", value: "Product" },
    { label: "Service", value: "Service" },
  ];

  const sourceOptions = [
    { label: "EPD", value: "EPD" },
    { label: "Calculated", value: "Calculated" },
  ];

  const accuracyOptions = [
    { label: "Estimated", value: "Estimated" },
    { label: "Actual", value: "Actual" },
  ];

  const onProductOrServiceChange = (e, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].productOrService = e.value;
    setData(updatedData);
  };

  const onSourceChange = (e, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].source = e.value;
    setData(updatedData);
  };

  const onAccuracyChange = (e, rowIndex) => {
    const updatedData = [...data];
    updatedData[rowIndex].accuracy = e.value;
    setData(updatedData);
  };

  const textInputBodyTemplate = (rowData, field, { rowIndex }) => {
    if (field === "carbonFootprint") {
      return (
        <InputNumber
          value={rowData[field]}
          onValueChange={(e) => {
            const updatedData = [...data];
            updatedData[rowIndex][field] = e.value;
            setData(updatedData);
          }}
          placeholder="Enter Carbon Footprint"
          mode="decimal"
          useGrouping={false} // Disables grouping (e.g., thousands separator)
          minFractionDigits={2} // Ensures at least two decimal places
          maxFractionDigits={2} // Limits to two decimal places
        />
      );
    }

    if (field === "unit") {
      return (
        <InputText
          value={rowData[field]}
          onChange={(e) => {
            const updatedData = [...data];
            updatedData[rowIndex][field] = e.target.value;
            setData(updatedData);
          }}
          placeholder="Enter Unit"
        />
      );
    }

    let placeholderText = "";
    switch (field) {
      case "carbonFootprint":
        placeholderText = "Enter Carbon Footprint";
        break;
      case "unit":
        placeholderText = "Enter Unit";
        break;
      default:
        placeholderText = "";
    }

    return (
      <InputText
        value={rowData[field]}
        onChange={(e) => {
          const updatedData = [...data];
          updatedData[rowIndex][field] = e.target.value;
          setData(updatedData);
        }}
        placeholder={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
      />
    );
  };
  const productOrServiceBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <InputText
        value={rowData.productOrService}
        onChange={(e) => onProductOrServiceChange(e, rowIndex)}
        placeholder=""
      />
    );
  };

  const sourceBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <Dropdown
        value={rowData.source}
        options={sourceOptions}
        onChange={(e) => onSourceChange(e, rowIndex)}
        placeholder="Select" // Set placeholder here
      />
    );
  };

  const accuracyBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <Dropdown
        value={rowData.accuracy}
        options={accuracyOptions}
        onChange={(e) => onAccuracyChange(e, rowIndex)}
        placeholder="Select" // Set placeholder here
      />
    );
  };

  const handleRadioChange = (e) => {
    const value = e.value;
    setFormData({ ...formData, emissionFactor: value });

    // Clear the error for emissionFactor when a valid option is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      emissionFactor: "", // Clear the error
    }));

    setShowTable(value === "true"); // Show table if "Yes" is selected
  };

  return (
    <div id="wrapper">
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div id="">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light" style={{ background: "#407775" }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="#home">
                <img
                  src="assets/img/logo.png"
                  alt="Company Logo"
                  height="30"
                  className="d-inline-block align-top"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#profile">
                      <img class="icon mr-3" src="assets/img/User.png" alt="User" />
                      Mathew
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="container survey-form scf p-4 p-lg-5">
          <div className="row mb-4">
            <div className="col">
              <div className="form-header">
                <h1 className="title" style={{ color: "407775!important" }}>
                  Supplier Carbon Footprint​ Survey
                </h1>
                <h2>Energy And Emissions</h2>
              </div>
            </div>
          </div>
          <div className="form-part">
            <form>
              <div className="row mt-4">
                <div className="col energyEmissions">
                  <label className="formlable" htmlFor="emissionFactor">
                    Do you calculate a specific cradle-to-gate emission factor for your products and
                    services?
                  </label>
                  <div className="flex flex-wrap gap-3 mt-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="emissionFactorYes"
                        name="emissionFactor"
                        value="true"
                        checked={formData.emissionFactor === "true"}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="emissionFactorYes" className="ml-2 mb-0 formlable">
                        Yes
                      </label>
                      <RadioButton
                        inputId="emissionFactorNo"
                        name="emissionFactor"
                        value="false"
                        checked={formData.emissionFactor === "false"}
                        onChange={handleRadioChange}
                        className="ml-4"
                      />
                      <label htmlFor="emissionFactorNo" className="ml-2 mb-0 formlable">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.emissionFactor && (
                    <div className="error-message">{errors.emissionFactor}</div>
                  )}
                </div>
              </div>

              {showTable && (
                <div className="row">
                  <div className="data-responsive">
                    <DataTable
                      value={data}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[10, 20, 25, 50]}
                      tableStyle={{ minWidth: "50rem" }}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      filters={filters}
                      filterDisplay="row"
                      globalFilterFields={[
                        "productOrService",
                        "carbonFootprint",
                        "source",
                        "unit",
                        "accuracy",
                      ]}
                      header={header}
                      emptyMessage="No data found."
                    >
                      <Column
                        field="productOrService"
                        header="Product/Service"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={productOrServiceBodyTemplate}
                        style={{ width: "20%" }}
                      />
                      <Column
                        header="Product/service carbon footprint per unit"
                        field="carbonFootprint"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) =>
                          textInputBodyTemplate(rowData, "carbonFootprint", props)
                        }
                        style={{ width: "20%" }} // Adjusted width
                      />
                      <Column
                        header="Unit"
                        field="unit"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={(rowData, props) => textInputBodyTemplate(rowData, "unit", props)}
                        style={{ width: "15%" }} // Adjusted width
                      />
                      <Column
                        header="Source"
                        field="source"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={sourceBodyTemplate}
                        style={{ width: "15%" }}
                      />

                      <Column
                        header="Accuracy"
                        field="accuracy"
                        sortable
                        filter
                        filterPlaceholder="Filter"
                        body={accuracyBodyTemplate}
                        style={{ width: "15%" }}
                      />
                      <Column
                        header="Source File"
                        body={(rowData) => (
                          <div className="icon-container">
                            <Tooltip title="Source File" arrow>
                              <img
                                src="./assets/img/formsapps/attachment.svg"
                                alt="Share email reminder"
                                className="actions-icon"
                                style={{ fontSize: "16px" }}
                              />
                            </Tooltip>
                          </div>
                        )}
                        style={{ width: "15%" }}
                      />
                    </DataTable>
                  </div>
                </div>
              )}
            </form>
            <div className="forms-buttons-container">
              <Button
                className="outline-btn"
                variant="contained"
                color="primary"
                startIcon={
                  <ArrowBackIosIcon sx={{ fontSize: "12px!important", marginRight: "0" }} />
                }
                onClick={() => navigate(`/supplier-carbon-footprint-survey-form?token=${scfStep1.token}&email=${scfStep1.email}`)}
              >
                Back
              </Button>
              <Button
                className="next-button"
                variant="contained"
                color="primary"
                endIcon={
                  <ArrowForwardIosIcon sx={{ fontSize: "12px!important", marginLeft: "0" }} />
                }
                onClick={handleSave}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SCFEnergyEmissions;
