import React from "react";
import clsx from "clsx";
// import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import containerTheme from "./theme/containerTheme.js";
import { useEffect, useState, useLayoutEffect } from "react";
import LoadingSpinner from "./components/LoadingSpinner";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./Spinner.css";
import * as XLSX from "xlsx";
import _ from "lodash";
import Dropzone from "react-dropzone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Row, Col, Card, Table, Alert } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import DeleteConfirmation from "./components/DeleteConfirmation";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { FaSearch } from "react-icons/fa";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net";
import DataTables from "datatables.net";
import $ from "jquery";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Paginator } from "primereact/paginator";
import "./table.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AADHeader from "./components/AADHeader.js";

const modal = {
  widthCustom: {
    width: 800,
  },
};

export default function Home() {
  const username = localStorage.getItem("userName");
  var myHeaders = new Headers();
  myHeaders.append("username", username);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  // const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [sloading, setSloading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const steps = [
    {
      label: "Select data type",
      description: ``,
    },
    {
      label: "Choose data connector",
      description: "",
    },
    {
      label: "Create connection",
      description: "",
    },
    {
      label: "Map fields",
      description: "",
    },
    {
      label: "Review and finish",
      description: "",
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setMatrics(matrics);
    setMyModule(mymodule);
    console.log(activeStep);
    if (activeStep === 1) {
      fetchFields();
    }
    if (activeStep === 3) {
      var inps = document.getElementsByName("destination[]");
      let destination = [];
      for (var i = 0; i < inps.length; i++) {
        var inp = inps[i];
        destination.push(inp.value);
      }
      console.log(destination);
      setRightHeader(destination);

      var inps1 = document.getElementsByName("excelHeaders[]");
      console.log(inps1);
      let excelHeaders1 = [];
      for (var i = 0; i < inps1.length; i++) {
        var inp1 = inps1[i];
        excelHeaders1.push(inp1.value);
      }

      setLeftHeader(excelHeaders1);
      console.log(excelHeaders1);
    }
  };

  const handleBackConfig = () => {
    setShowTable(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  async function fetchFields() {
    try {
      var myH = new Headers();
      myH.append("username", username);
      myH.append("modulename", mymodule);
      myH.append("pattern", selectedExcel);
      myH.append("ESGMetric", matrics);
      var requestO = {
        method: "GET",
        headers: myH,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/get-fields", requestO);
      const data = await response.json();
      setFields(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log(leftheader);
    console.log(rightheader);
    var newArray = leftheader.map((e, i) => e + ":" + rightheader[i]);
    console.log(newArray);
    const inputArray = newArray;

    const outputObject = inputArray.reduce((result, item) => {
      const [key, value] = item.split(":");
      result[key] = value;
      return result;
    }, {});

    console.log(outputObject);

    var str = JSON.stringify(newArray);
    var final1 = str
      .replace(/{|},|}/g, "")
      .replace(/\[|]|"/g, "")
      .replace(/,/g, ",");
    console.log(final1);
    var result = newArray.reduce(function (map, obj) {
      console.log(obj);
      map[obj] = obj;
      return map;
    }, {});
    console.log(result);
    console.log(
      Object.entries(newArray).map(
        ([key, value]) => `My key is ${key} and my value is ${JSON.stringify(value)}`
      )
    );
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/UpdateConnectorConfig", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({
          siteLocationId: site,
          fileFormat: selectedExcel,
          module: mymodule,
          esgmetric: matrics,
          fieldMapping: outputObject,
        }),
      });
      if (res.status === 200) {
        setEditIsOpen(false);
        const responseJson = await res.json();
        if (responseJson.success == false) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Connector Config Updated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
      } else {
        setEditIsOpen(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Error In Update Connector Config</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const excelH = async (e, i) => {
    document.getElementById("excel" + i).value = e;
  };

  const saveData = async () => {
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/update-businessunit", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          fetchConnectorConfig();
          Swal.fire({
            icon: "success",
            title: "Business Unit Updated successfully",
          });
        }
        setEditIsOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error In Update Business Unit",
        });
        setEditIsOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState("");
  const [activeconfig, setActiveConfig] = useState("");
  const [deactiveconfig, setDeactiveConfig] = useState("");
  const [importfile, setImportFile] = React.useState("");
  const [headerfields, setHeaderFields] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [site, setSite] = React.useState("");
  const [file, setFile] = React.useState("");
  const [esg, setESG] = React.useState("");
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deletename, setDeleteName] = useState(null);
  const [modules, setModules] = React.useState(false);
  const [mymodule, setMyModule] = useState("");
  const [module, setModule] = useState("");
  const [excelfields, setFields] = React.useState(false);
  const [pattern, setPattern] = useState("");
  const [sitelocation, setSiteLocation] = useState("");
  const [sitelocation1, setSiteLocation1] = useState("");
  const [leftheader, setLeftHeader] = useState("");
  const [rightheader, setRightHeader] = useState("");
  const [matrics, setMatrics] = useState("");
  const [mymatrics, setMyMatrics] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedExcel, setSelectedExcel] = useState("");
  const [showTable, setShowTable] = React.useState(false);
  const [searchsite, setSearchSite] = useState("");
  const [searchesg, setSearchESG] = useState("");
  const [activeCounter, setActiveCounter] = useState();
  const [inactiveCounter, setInActiveCounter] = useState();
  const [active, setActive] = useState("");
  const [inactive, setInactive] = useState("");
  const [inact, setInact] = useState("");
  const [act, setAct] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    siteLocationId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    esgMetric: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileFormat: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportFrequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updatedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filters1, setFilters1] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    siteLocationId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    esgMetric: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileFormat: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportFrequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updatedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const renderHeader2 = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading=""/>;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-end">
        <div class="col-md-6 pd-0">
          <button id="active" className={act} onClick={() => toggleTable("active")}>
            <span className="record-count">{activeCounter}</span>
            <span className="record-title">Active</span>
          </button>
          <button id="inactive" className={inact} onClick={() => toggleTable("inactive")}>
            <span className="record-count">{inactiveCounter}</span>{" "}
            <span className="record-title">Inactive </span>{" "}
          </button>
        </div>
        <div class="col-md-6 pd-0 mt-top10 txt-end">
          <span className="p-input-icon-left">
            <img src="assets/img/search.png" className="search-i" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters1 };

    _filters["global"].value = value;

    setFilters1(_filters);
    setGlobalFilterValue1(value);
  };

  const header = renderHeader();
  const header1 = renderHeader1();

  const showEditModal = (module, esg, site, file) => {
    const type = localStorage.setItem("type", file);
    setActiveStep(0);
    setModule(esg);
    setMyModule(esg);
    setESG(esg);
    setMatrics(esg);
    setSite(site);
    setSiteLocation1(site);
    setFile(file);
    setPattern(file);
    setSelectedExcel(file);
    console.log(file);
    setEditIsOpen(true);
  };

  const hideEditModal = () => {
    setEditIsOpen(false);
  };

  async function fetchSiteLocation() {
    try {
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-sitelocation",
        requestOptions
      );
      const data = await response.json();
      setSiteLocation(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchESGMatrics(value) {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      myHeaderss.append("param", value);
      var requestOptionss = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch("https://ecoprismapi.azurewebsites.net/ESG", requestOptionss);
      const data = await response.json();
      setMyMatrics(data);
    } catch (error) {
      console.error(error);
    }
  }

  const showDeleteModal = (site, file, esg) => {
    setSite(site);
    setFile(file);
    setESG(esg);
    setDeleteName(site);
    setDeleteMessage(
      "Are you sure you want to deactivate this connection for " + esg + " - " + site + "?"
    );
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the search of the item
  const searchData = async () => {
    console.log(searchsite);
    var site = searchsite;
    var esg = searchesg;
    const data = localStorage.getItem("allconfig");
    console.log(data);
    if (searchsite === "" && searchesg === "") {
      if (data) {
        const parsedData = JSON.parse(data);
        setActiveConfig(parsedData);
      }
    }
    if (searchsite !== "" && searchesg !== "") {
      if (data) {
        const parsedData = JSON.parse(data);
        const filteredData = parsedData.filter((item) => {
          return item.siteLocationId === searchsite && item.esgMetric === searchesg;
        });
        console.log(filteredData);
        setActiveConfig(filteredData);
      }
    }
    if (searchsite === "" && searchesg !== "") {
      if (data) {
        const parsedData = JSON.parse(data);
        const filteredData = parsedData.filter((item) => {
          return item.esgMetric === searchesg;
        });
        setActiveConfig(filteredData);
        console.log(filteredData);
      }
    }
    if (searchsite !== "" && searchesg === "") {
      if (data) {
        const parsedData = JSON.parse(data);
        const filteredData = parsedData.filter((item) => {
          return item.siteLocationId === searchsite;
        });
        console.log(filteredData);
        setActiveConfig(filteredData);
      }
    }
  };

  const reactive = async (name, esg, file) => {
    setLoading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/reactive-configuration", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          username: username,
          file: file,
          esg: esg,
          name: name,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            text: responseJson.data,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Configuration reactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        fetchConnectorConfig();
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">Error in reactivate configuration</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  // Handle the actual deletion of the item
  const submitDelete = async () => {
    console.log(deletename);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/delete-connector-cofig", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          site: site,
          file: file,
          esg: esg,
          username: username,
        },
        body: JSON.stringify({}),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        fetchConnectorConfig();
        const responseJson = await res.json();
        if (responseJson.success == false) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Connector Config Deactivated successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        setDisplayConfirmationModal(false);
      } else {
        toast.error("Error In Deactivate Connector Config");
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function fetchConnectorConfig() {
    setSloading(true);
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/list-connector-config",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          console.log(responseJson);
          setSloading(false);
          setConfig("");
        } else if (responseJson.status == 400) {
          setSloading(false);
          setConfig("");
        } else {
          setSloading(false);
          localStorage.setItem("allconfig", JSON.stringify(responseJson.data));
          setConfig(responseJson.data);
          setActiveConfig(responseJson.data.filter((item) => item.isActive === true));
          setDeactiveConfig(responseJson.data.filter((item) => item.isActive === false));
          setActiveCounter(responseJson.data.filter((item) => item.isActive === true).length);
          setInActiveCounter(responseJson.data.filter((item) => item.isActive === false).length);
        }
      } else {
        setSloading(false);
        setConfig("");
      }
      setIsLoading(false);
    } catch (error) {
      setSloading(false);
      setIsLoading(false);
      console.error(error);
    }
  }

  async function fetchModules() {
    try {
      const response = await fetch("https://ecoprismapi.azurewebsites.net/list-modules");
      const data = await response.json();
      setModules(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchConnectorConfig();
    fetchModules();
    fetchSiteLocation();
  }, []);

  const onDrop = useCallback((acceptedFiles, site, type, esgmetric) => {
    setSloading(true);
    console.log("Run reoprt started");
    setImportFile(acceptedFiles[0].name);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
        const columnsArray = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          header: 1,
        })[0];
        setHeaderFields(columnsArray);
        const rowData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
        const newdatas = [];
        const convertedData = rowData.map((row) => {
          const convertedRow = {};
          for (let key in row) {
            if (typeof row[key] === "number") {
              const regex = /[dD][aA][tT][eE]/i;
              const match = regex.exec(key);
              const unit = /^[Uu][Nn][Ii][Tt]$/i;
              const unitMatch = regex.exec(key);
              if (match) {
                console.log(key + " Date");
                const excelToDate = (excelDate) => {
                  const MS_PER_DAY = 86400000;
                  const EPOCH_DATE = 25569; // January 1, 1970 is the 25569th day in the Excel date system
                  const epoch = (excelDate - EPOCH_DATE) * MS_PER_DAY;
                  return new Date(epoch);
                };
                convertedRow[key] = excelToDate(row[key]).toLocaleDateString("en-US");
              } else if (unitMatch) {
                convertedRow[key] = "";
              } else {
                convertedRow[key] = row[key].toString();
              }
            } else {
              convertedRow[key] = row[key];
            }
          }
          newdatas.push(convertedRow);
          return convertedRow;
        });

        console.log(newdatas);
        // Api for run report

        let res = fetch("https://ecoprismapi.azurewebsites.net/RunReport", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            username: username,
            site: site,
            type: type,
            esgmetric: esgmetric,
          },
          body: JSON.stringify(newdatas),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success == false) {
              setSloading(false);
              Swal.fire({
                icon: "error",
                html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;">${data.data}</p>`,
                iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
              });
            } else {
              setSloading(false);
              if (data.data.totalRecords === data.data.successfulRecords) {
                Swal.fire({
                  icon: "success",
                  iconHtml:
                    '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
                  text: "Successfully rows added is " + data.data.successfulRecords,
                  html:
                    '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Report run successfully</p><div class="col-md-12 flex1"><div class="col-md-9 wd-70">Total Records: </div><div class="col-md-3 wd-20">' +
                    data.data.totalRecords +
                    '</div></div><div class="col-md-12 flex1"><div class="col-md-9 wd-70 pd-left45">Success Records: </div><div class="col-md-3 wd-20">' +
                    data.data.successfulRecords +
                    "</div></div>",
                });
              }
              if (data.data.totalRecords == data.data.failedRecords) {
                Swal.fire({
                  icon: "error",
                  html:
                    '<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4><p style="font-size:16px;">Report Import Failed</p><div class="col-md-12 flex1"><div class="col-md-9 wd-70">Total Records: </div><div class="col-md-3 wd-20">' +
                    data.data.totalRecords +
                    '</div></div><div class="col-md-12 flex1"> <div class="col-md-9 wd-70 pd-left35">Invalid Records: </div><div class="col-md-3 wd-20">' +
                    data.data.failedRecords +
                    "</div></div>",
                  iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
                });
              }
              if (
                data.data.failedRecords >= 1 &&
                data.data.totalRecords !== data.data.failedRecords
              ) {
                Swal.fire({
                  icon: "warning",
                  text: "Number of failed rows is " + data.data.failedRecords,
                  html:
                    '<h4 style="color: #f8bb86; font-size: 20px; font-weight: bold;">Warning</h4><p style="font-size:16px;">Report run successfully</p><div class="col-md-12 flex1"><div class="col-md-9 wd-70">Total Records: </div><div class="col-md-3 wd-20">' +
                    data.data.totalRecords +
                    '</div></div><div class="col-md-12 flex1"><div class="col-md-9 wd-70 pd-left45">Success Records: </div><div class="col-md-3 wd-20">' +
                    data.data.successfulRecords +
                    '</div></div><div class="col-md-12 flex1"><div class="col-md-9 wd-70 pd-left35">Invalid Records: </div><div class="col-md-3 wd-20">' +
                    data.data.failedRecords +
                    "</div></div>",
                });
              }
            }
          })
          .catch((error) => {
            setSloading(false);
            console.log(error);
            Swal.fire({
              icon: "error",
              html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;">Error In Run Report</p>`,
              iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
            });
          });

        var result = columnsArray
          .map(function (val) {
            return val;
          })
          .join(",");
        var item = "";
        var results = {};

        if (result !== null) {
          result = result.split(",");

          for (var i = 0; i < result.length; i++) {
            item = result[i].trim();

            results[item] = item;
          }
        }
        /* Update state */
        const jsonData = JSON.parse(JSON.stringify(data));
        setLoading(false);
      };
      reader.readAsBinaryString(file);
    });
  }, []);

  const excel = {
    width: "50px",
  };

  async function uploadFileName(filename) {
    const res = await fetch("https://ecoprismapi.azurewebsites.net/GetPdfParameter", {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        filename: filename,
        username: username,
      },
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      if (responseJson.success == false) {
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
      } else {
        console.log(responseJson.data);
        function combine(collections) {
          const entries = [...Object.entries(collections)];
          const length = entries[0]?.[1]?.length ?? 0;
          const result = [];
          for (const [name, array] of entries) {
            if (array.length !== length) throw new Error("Non-uniform array lengths");
            for (let i = 0; i < length; i += 1) {
              const item = (result[i] ??= {});
              item[name] = array[i];
            }
          }
          return result;
        }
        const businessUnitValues = Object.values(responseJson.data);
        const businessUnitKeys = Object.keys(responseJson.data);
        const merge = () => {
          const merged = combine({
            businessunitvalue: businessUnitValues,
            businessunit: businessUnitKeys,
          });
          console.log(merged);
          const businessunitvalues = merged.map((item) => item.businessunit);
          const commaSeparatedArray = businessunitvalues.join(",");
          const dataArray = commaSeparatedArray.split(",");
          setHeaderFields(dataArray);
          Swal.fire({
            icon: "success",
            title: filename + " uploaded successfully",
          });
          console.log(dataArray);
        };

        merge();
        setImportFile(filename);
      }
    } else {
      Swal.fire({
        icon: "error",
        html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">Pdf not uploaded</p>`,
        iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
      });
    }
  }

  //Run Report for other app names
  async function runReport(esgM, siteL, connector) {
    setSloading(true);
    try {
      let res = await fetch("https://ecoprismapi.azurewebsites.net/run-report-other", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          userEmail: username,
          esgMetric: esgM,
          siteLocation: siteL,
          ConnectorName: connector,
          forceRunReport: true,
        }),
      });
      //let resJson = await res.json();
      if (res.status === 200) {
        const responseJson = await res.json();
        if (responseJson.success == false) {
          setSloading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.data}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else if (responseJson.status == 400) {
          setSloading(false);
          Swal.fire({
            icon: "error",
            html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
            <p style="font-size: 16px;">${responseJson.title}</p>`,
            iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
          });
        } else {
          setSloading(false);
          Swal.fire({
            icon: "success",
            html: '<h4 style="color:#407775;font-size: 20px;">Success!</h4><p style="font-size:16px;">Report run successfully</p>',
            iconHtml: '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
          });
        }
        setDisplayConfirmationModal(false);
      } else {
        setSloading(false);
        toast.error("Error In report run");
        setDisplayConfirmationModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //PDF Code
  async function uploadImage(containerName, file) {
    console.log(file);
    let res = await fetch("https://ecoprismapi.azurewebsites.net/GetSASToken", {
      method: "GET",
    });
    if (res.status === 200) {
      const responseJson = await res.json();
      const connectionString = responseJson.data;
      const blobServiceClient = new BlobServiceClient(connectionString);
      console.log(connectionString);
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(file.name);
      console.log(blobClient);
      const blockBlobClient = blobClient.getBlockBlobClient();
      console.log(blockBlobClient);
      const result = await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: (ev) => console.log(ev),
      });
      console.log(result);
      if (result) {
        console.log(file.name);
        uploadFileName(file.name);
      }

      //console.log(`Upload of file '${file.name}' completed`);
    } else {
    }
  }

  const onDrops = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    const type = localStorage.getItem("type");
    setImportFile(acceptedFiles[0].name);
    const name = acceptedFiles[0].name;
    const extension = name.split(".").pop();
    let sendObj = {
      startDate: "",
      endDate: "",
      energyCons: 0,
    };
    if (type === "Excel") {
      if (extension === "xls" || extension === "xlsx") {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
            //const workbookHeaders = XLSX.readFile(bstr, { sheetRows: 1 });
            console.log(data);
            if (data.length === 0) {
              Swal.fire({
                icon: "error",
                html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
                <p style="font-size: 16px;">Excel File is not proper or header is not present</p>`,
                iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
              });
              console.log("The array is empty.");
              return false;
              // Perform actions for an empty array
            }
            setImportFile(acceptedFiles[0].name);
            Swal.fire({
              icon: "success",
              iconHtml:
                '<img src="assets/img/successicon.png" style="width: 55px; height: 55px;" />',
              html: `<h4 style="color: #407775; font-size: 20px;">Success!</h4>
                ${acceptedFiles[0].name} uploaded successfully`,
            });
            const columnsArray = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames], {
              header: 1,
            })[0];
            console.log(columnsArray);
            setHeaderFields(columnsArray);
            var result = columnsArray
              .map(function (val) {
                return val;
              })
              .join(",");
            var item = "";
            var results = {};

            if (result !== null) {
              result = result.split(",");

              for (var i = 0; i < result.length; i++) {
                item = result[i].trim();

                results[item] = item;
              }
            }
            /* Update state */
            const jsonData = JSON.parse(JSON.stringify(data));
            const newdata = [];
            _.forEach(jsonData, (jd) => {
              sendObj.energyCons = jd.EnergyConsumption;
              sendObj.startDate = jd.StartDate;
              sendObj.endDate = jd.EndDate;
              newdata.push({
                email: "sudies@ecoprism.com",
                energy: sendObj.energyCons,
                region: "DK",
                reportPeriod: 2,
                endTimeEpoch: sendObj.endDate,
                unit: "kWh",
              });
              //console.log(jd, 'hsdajshd')
            });
            setLoading(false);
          };
          reader.readAsBinaryString(file);
        });
      } else {
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">File format is not valid</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setImportFile();
        return false;
      }
    }
    if (type === "PDF") {
      if (extension === "pdf") {
        uploadImage("ecoprismfilecontainer", acceptedFiles[0]);
      } else {
        Swal.fire({
          icon: "error",
          html: `<h4 style="color: #CC2229; font-size: 20px; font-weight: bold;">Error!</h4>
          <p style="font-size: 16px;">File format is not valid</p>`,
          iconHtml: '<img src="assets/img/error.png" style="width: 55px; height: 55px;" />',
        });
        setImportFile();
        return false;
      }
    }
  }, []);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [activeTable, setActiveTable] = useState(true);
  const [inactiveTable, setInActiveTable] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [activeRowsPerPage, setActiveRowsPerPage] = useState(10);
  const [inactiveRowsPerPage, setInactiveRowsPerPage] = useState(10);
  // Active Table Page Change Handler
  const handleActivePageChange = (event, newPage) => {
    setActivePage(newPage);
  };

  // Inactive Table Page Change Handler
  const handleInactivePageChange = (event, newPage) => {
    setInactivePage(newPage);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  useLayoutEffect(() => {
    initializeDataTable();
    initializeDataTable1();
    setActive("block");
    setInactive("none");
    setInact("inactive-buttonred");
    setAct("active-buttongreen");
  }, [activeconfig]);

  const initializeDataTable = () => {
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      var dataTable = $("#myTable").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();
          console.log(api);
          console.log("DataTables initialization complete");

          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Handle global search
          $("#globalSearch").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().toLowerCase();
                    return (
                      columnString.includes(enteredValue) ||
                      columnString.replace(/,/g, "").includes(enteredValue)
                    );
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const initializeDataTable1 = () => {
    if (!$.fn.DataTable.isDataTable("#myTable1")) {
      var dataTable1 = $("#myTable1").DataTable({
        orderCellsTop: true,
        fixedHeader: false,
        initComplete: function () {
          var api = this.api();
          console.log(api);
          console.log("DataTables initialization complete");

          // Array to store original data
          var originalData = api.rows().data().toArray();

          // Add a global search input
          $("#customSearchDivinactive").append(
            '<input type="text" id="globalSearch1" class="search-input search-container " placeholder="Search all data" />'
          );

          // Handle global search
          $("#globalSearch1").on("keyup change", function () {
            var enteredValue = $(this).val().toLowerCase();

            // Filter the data manually
            var filteredData = originalData.filter(function (value) {
              return Object.values(value).some(function (columnValue) {
                var columnString = columnValue.toString().toLowerCase();
                return (
                  columnString.includes(enteredValue) ||
                  columnString.replace(/,/g, "").includes(enteredValue)
                );
              });
            });

            // Clear the table and redraw with filtered data
            api.clear().rows.add(filteredData).draw();
          });

          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $(".filters1 th").eq($(api.column(colIdx).header()).index());
              var title = $(cell).text();
              $(cell).html(
                '<input type="text" class="search-input" placeholder="' + title + '" />'
              );
              $("input", $(".filters1 th").eq($(api.column(colIdx).header()).index()))
                .off("keyup change")
                .on("keyup change", function (e) {
                  e.stopPropagation();
                  $(this).attr("title", $(this).val());

                  // Remove commas from the entered value
                  var enteredValue = this.value.replace(/,/g, "").toLowerCase();

                  // Filter the data manually
                  var filteredData = originalData.filter(function (value) {
                    var columnString = value[colIdx].toString().replace(/,/g, "").toLowerCase();
                    return columnString.includes(enteredValue);
                  });

                  // Clear the table and redraw with filtered data
                  api.clear().rows.add(filteredData).draw();
                });
            });
        },
      });
    }
  };

  const toggleTable = (name) => {
    // Initialize DataTable instance for the selected tab
    if (name === "active") {
      setActive("block");
      setInactive("none");
      setInact("inactive-buttonred");
      setAct("active-buttongreen");
    } else if (name === "inactive") {
      setInact("active-buttonred");
      setAct("inactive-buttongreen");
      setActive("none");
      setInactive("block");
    }
    setPage(0); // Reset page when switching tables
  };

  // New Datable start
  const data =
    activeconfig &&
    activeconfig.map((row, rowIndex) => [
      <div className="actions">
        <div className="mr-20">
          <EditIcon
            style={{ cursor: "pointer", color: "#407775", width: "20px", height: "20px" }}
            onClick={() =>
              showEditModal(row.module, row.esgMetric, row.siteLocationId, row.fileFormat)
            }
          />
          <img
            className="action-icon"
            src="assets/img/deactivate.png"
            alt="Deactivate"
            style={{ cursor: "pointer", color: "#407775" }}
            onClick={() => showDeleteModal(row.siteLocationId, row.fileFormat, row.esgMetric)}
          />
        </div>
        {" " + row.siteLocationId}
      </div>,
      row.esgMetric,
      row.reportFrequency,
      row.fileFormat === "Excel" ? (
        <img src="assets/img/2.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "API" ? (
        <img src="assets/img/api.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "Azure Table Storage" ? (
        <img src="assets/img/azure.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "PDF" ? (
        <img src="assets/img/pdf.png" style={{ width: "40px" }} />
      ) : (
        <span>{row.fileFormat}</span>
      ),
      new Date(row.updatedAt).toLocaleString("en-GB"),
      row.fileFormat === "Excel" ? (
        <Dropzone
          onDrop={(acceptedFiles) =>
            onDrop(acceptedFiles, row.siteLocationId, row.fileFormat, row.esgMetric)
          }
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone__section">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone1">
                  <label for="files" class="dropzone-container1">
                    <img className="run-icon" src="assets/img/import.png" alt="" />
                  </label>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      ) : (
        <button
          className="ml-15"
          onClick={() => runReport(row.esgMetric, row.siteLocationId, row.fileFormat)}
        >
          <img className="run-icon" src="assets/img/import.png" alt="" />
        </button>
      ),
      <a href={`/reporthistory?sl=${row.siteLocationId}&esg=${row.esgMetric}`}>
        <RemoveRedEyeIcon className="run-icon" style={{ cursor: "pointer", color: "#407775" }} />
      </a>,
    ]);

  const ddata =
    deactiveconfig &&
    deactiveconfig.map((row, rowIndex) => [
      <div className="actions">
        <div className="mr-20">
          <img
            className="action-icon"
            src="assets/img/deactivate.png"
            alt="Deactivate"
            style={{ cursor: "pointer", color: "#407775" }}
            onClick={() => reactive(row.siteLocationId, row.esgMetric, row.fileFormat)}
          />
        </div>
        {" " + row.siteLocationId}
      </div>,
      row.esgMetric,
      row.reportFrequency,
      row.fileFormat === "Excel" ? (
        <img src="assets/img/2.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "API" ? (
        <img src="assets/img/api.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "Azure Table Storage" ? (
        <img src="assets/img/azure.png" style={{ width: "40px" }} />
      ) : row.fileFormat === "PDF" ? (
        <img src="assets/img/pdf.png" style={{ width: "40px" }} />
      ) : (
        <span>{row.fileFormat}</span>
      ),
      new Date(row.updatedAt).toLocaleString("en-GB"),
      row.fileFormat === "Excel" ? (
        <Dropzone
          onDrop={(acceptedFiles) =>
            onDrop(acceptedFiles, row.siteLocationId, row.fileFormat, row.esgMetric)
          }
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone__section">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone1">
                  <label for="files" class="dropzone-container1">
                    <img className="run-icon" src="assets/img/import.png" alt="" />
                  </label>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      ) : (
        <button
          className="ml-15"
          onClick={() => runReport(row.esgMetric, row.siteLocationId, row.fileFormat)}
        >
          <img className="run-icon" src="assets/img/import.png" alt="" />
        </button>
      ),
      <a href={`/reporthistory?sl=${row.siteLocationId}&esg=${row.esgMetric}`}>
        <RemoveRedEyeIcon className="run-icon" style={{ cursor: "pointer", color: "#407775" }} />
      </a>,
    ]);

  const [globalFilter, setGlobalFilter] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <div id="wrapper">
      <div>
        {sloading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {renderHeader2()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex">
            <div class="col-md-9 pd-0">
              <div Class="steps__containt-hader">
                <h3 class="title">All Configurations</h3>
                <div role="presentation">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" href="">
                      Configuration
                    </Link>
                    <Typography color="text.primary">All Configurations</Typography>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div class="col-md-3 pd-0">
              <div Class="steps__containt-hader btalignleft">
                <a href="/configuration" className="add-cta">
                  + Setup New Configuration
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12 flex pd-0"></div>

          {/* <div class="col-md-9 pd-0 mb-10">
          <button id="active" className={act} onClick={() => toggleTable('active')}><span className="record-count">{activeCounter}</span><span>Active</span></button>
          <button id="inactive" className={inact} onClick={() => toggleTable('inactive')}><span className="record-count">{inactiveCounter}</span> <span>Inactive </span> </button>
        </div> */}
          <div className="row" style={{ display: active }}>
            <DataTable
              value={activeconfig}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              dataKey="id"
              filters={filters}
              filterDisplay="row"
              globalFilterFields={[
                "siteLocationId",
                "esgMetric",
                "reportFrequency",
                "fileFormat",
                "updatedAt",
              ]}
              header={header}
              emptyMessage="No data found."
            >
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    <EditIcon
                      title="Edit"
                      style={{ cursor: "pointer", color: "#407775", width: "20px", height: "20px" }}
                      onClick={() =>
                        showEditModal(
                          rowData.module,
                          rowData.esgMetric,
                          rowData.siteLocationId,
                          rowData.fileFormat
                        )
                      }
                    />

                    <img
                      className="action-icon"
                      src="assets/img/deactivate.png"
                      alt="Deactive"
                      style={{ cursor: "pointer", color: "#407775" }}
                      onClick={() =>
                        showDeleteModal(
                          rowData.siteLocationId,
                          rowData.fileFormat,
                          rowData.esgMetric
                        )
                      }
                    />
                  </div>
                )}
                style={{ minWidth: "8rem",paddingLeft: "30px" }}
              />
              <Column
                sortable
                field="siteLocationId"
                header="Site Location"
                filter
                filterPlaceholder="Filter"
                style={{ minWidth: "15rem" }}
              />
              <Column
                sortable
                header="ESG parameter"
                field="esgMetric"
                style={{ minWidth: "15rem" }}
                filter
                filterPlaceholder="Filter"
              />
              <Column
                header="Data connector"
                body={(rowData) => (
                  <div>
                    {rowData.fileFormat === "Excel" ? (
                      <img src="assets/img/2.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "API" ? (
                      <img src="assets/img/api.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "Azure Table Storage" ? (
                      <img src="assets/img/azure.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "PDF" ? (
                      <img src="assets/img/pdf.png" style={{ width: "30px" }} />
                    ) : (
                      <span>{rowData.fileFormat}</span>
                    )}
                  </div>
                )}
                style={{ minWidth: "5rem" }}
              />
              <Column
                header="Run"
                body={(rowData) => (
                  <div>
                    {rowData.fileFormat === "Excel" ? (
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          onDrop(
                            acceptedFiles,
                            rowData.siteLocationId,
                            rowData.fileFormat,
                            rowData.esgMetric
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone__section">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone1">
                                <label htmlFor="files" className="dropzone-container1">
                                  <img className="run-icon" src="assets/img/import.png" alt="" />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <button
                        className="ml-15"
                        onClick={() =>
                          runReport(rowData.esgMetric, rowData.siteLocationId, rowData.fileFormat)
                        }
                      >
                        <img className="run-icon" src="assets/img/import.png" alt="" />
                      </button>
                    )}
                  </div>
                )}
                style={{
                  width: "4rem",
                  minWidth: "3rem",
                  display: "inline-flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              />
              <Column
                header="Report history"
                body={(rowData) => (
                  <div>
                    <a
                      href={`/reporthistory?sl=${rowData.siteLocationId}&esg=${rowData.esgMetric}`}
                    >
                      <RemoveRedEyeIcon
                        className="run-icon"
                        style={{ cursor: "pointer", color: "#407775" }}
                      />
                    </a>
                  </div>
                )}
                style={{ minWidth: "5rem", textAlign: "center" }}
              />
              <Column
                sortable
                header="Data frequency"
                field="reportFrequency"
                style={{ minWidth: "10rem" }}
                filter
                filterPlaceholder="Filter"
              />
              <Column
                sortable
                header="Last Refresh"
                field="updatedAt"
                body={(rowData) => <div>{new Date(rowData.updatedAt).toLocaleString("en-GB")}</div>}
                style={{ minWidth: "10rem" }}
                filter
                filterPlaceholder="Filter"
              />
            </DataTable>
          </div>
          <div className="row" style={{ display: inactive }}>
            <DataTable
              value={deactiveconfig}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 20, 25, 50]}
              tableStyle={{ minWidth: "50rem" }}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              dataKey="id"
              filters={filters1}
              filterDisplay="row"
              globalFilterFields={[
                "siteLocationId",
                "esgMetric",
                "reportFrequency",
                "fileFormat",
                "updatedAt",
              ]}
              header={header1}
              emptyMessage="No data found."
            >
              <Column
                header=""
                body={(rowData) => (
                  <div>
                    <img
                      className="action-icon"
                      src="assets/img/reactivate.png"
                      alt="Reactive"
                      style={{ cursor: "pointer", color: "#407775" }}
                      onClick={() =>
                        reactive(rowData.siteLocationId, rowData.esgMetric, rowData.fileFormat)
                      }
                    />
                  </div>
                )}
                style={{ minWidth: "5rem" ,paddingLeft: "30px" }}
              />
              <Column
                sortable
                field="siteLocationId"
                header="Site Location"
                filter
                filterPlaceholder="Filter"
                style={{ minWidth: "15rem" }}
              />
              <Column
                sortable
                header="ESG parameter"
                field="esgMetric"
                style={{ minWidth: "15rem" }}
                filter
                filterPlaceholder="Filter"
              />
              <Column
                header="Data connector"
                body={(rowData) => (
                  <div>
                    {rowData.fileFormat === "Excel" ? (
                      <img src="assets/img/2.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "API" ? (
                      <img src="assets/img/api.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "Azure Table Storage" ? (
                      <img src="assets/img/azure.png" style={{ width: "30px" }} />
                    ) : rowData.fileFormat === "PDF" ? (
                      <img src="assets/img/pdf.png" style={{ width: "30px" }} />
                    ) : (
                      <span>{rowData.fileFormat}</span>
                    )}
                  </div>
                )}
                style={{ minWidth: "5rem" }}
              />

              <Column
                header="Run"
                body={(rowData) => (
                  <div>
                    {rowData.fileFormat === "Excel" ? (
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          onDrop(
                            acceptedFiles,
                            rowData.siteLocationId,
                            rowData.fileFormat,
                            rowData.esgMetric
                          )
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone__section">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone1">
                                <label for="files" class="dropzone-container1">
                                  <img className="run-icon" src="assets/img/import.png" alt="" />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <button
                        className="ml-15"
                        onClick={() =>
                          runReport(rowData.esgMetric, rowData.siteLocationId, rowData.fileFormat)
                        }
                      >
                        <img className="run-icon" src="assets/img/import.png" alt="" />
                      </button>
                    )}
                  </div>
                )}
                style={{
                  width: "4rem",
                  minWidth: "3rem",
                  display: "inline-flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              />

              <Column
                header="Report history"
                body={(rowData) => (
                  <div>
                    <a
                      href={`/reporthistory?sl=${rowData.siteLocationId}&esg=${rowData.esgMetric}`}
                    >
                      <RemoveRedEyeIcon
                        className="run-icon"
                        style={{ cursor: "pointer", color: "#407775" }}
                      />
                    </a>
                  </div>
                )}
                style={{ minWidth: "5rem", textAlign: "center" }}
              />
              <Column
                sortable
                header="Data frequency"
                field="reportFrequency"
                style={{ minWidth: "10rem" }}
                filter
                filterPlaceholder="Filter"
              />
              <Column
                sortable
                header="Last Refresh"
                field="updatedAt"
                body={(rowData) => <div>{new Date(rowData.updatedAt).toLocaleString("en-GB")}</div>}
                style={{ minWidth: "10rem" }}
                filter
                filterPlaceholder="Filter"
              />
            </DataTable>
          </div>

          <DeleteConfirmation
            confirmModal={submitDelete}
            showModal={displayConfirmationModal}
            hideModal={hideConfirmationModal}
            message={deleteMessage}
            deletename={deletename}
          />

          <Modal show={editIsOpen} onHide={hideEditModal} className="custom-modal-width-config">
            <Modal.Header closeButton>
              <div Class="steps__containt-hader">
                <h3 class="title">Edit Connector Config</h3>
              </div>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="steps__containt-body">
                  {loading && <div class="loading">Loading&#8230;</div>}
                  <div className="steps__containt-left">
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      sx={{
                        color: "#37517e",
                        "& .MuiSlider-thumb": {
                          borderRadius: "1px",
                        },
                        padding: "35px",
                        paddingTop: "22px",
                        paddingLeft: "104px",
                      }}
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            sx={{
                              "& .MuiStepLabel-root .Mui-completed": {
                                color: "secondary.dark", // circle color (COMPLETED)
                              },
                              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                color: "grey.500", // Just text label (COMPLETED)
                              },
                              "& .MuiStepLabel-root .Mui-active": {
                                color: "secondary.main", // circle color (ACTIVE)
                              },
                              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                                color: "common.white", // Just text label (ACTIVE)
                              },
                              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                fill: "black", // circle's number (ACTIVE)
                              },
                            }}
                          >
                            {step.label}
                          </StepLabel>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div></div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div className="steps__containt-right">
                    {activeStep === 0 && (
                      <div>
                        <div className="steps__containt-hader">
                          <h3 className="title">Select data type</h3>
                          <p>Select the data parameter to set up the new connection.</p>
                        </div>
                        <hr className="hr-style" />
                        <div className="steps__container">
                          <form>
                            <div className="row">
                              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="mb-3">
                                  <label className="formlable">Select ESG parameter</label>
                                  <input type="text" readOnly value={module} class="form-control" />
                                </div>
                                <div className="mb-3">
                                  <label className="formlable">Site location</label>
                                  <input type="text" readOnly value={site} class="form-control" />
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="next__previous-buttons">
                            <div className="d-flex">
                              <Button
                                onClick={handleNext}
                                className="next-button"
                                variant="contained"
                                size="medium"
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#00366D",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeStep === 1 && (
                      <div>
                        <div className="steps__containt-hader">
                          <h3 className="title">Choose data connector</h3>
                          <p>
                            Simplify data collection using import options such as excel sheets, XML
                            files and API integration.
                          </p>
                        </div>
                        <hr />
                        <div className="steps__container">
                          <div>
                            <label className="form-label">Choose connector</label>
                            <div className="mb-3">
                              <label className="formlable">File Format</label>
                              <input type="text" readOnly value={file} class="form-control" />
                            </div>
                          </div>
                          <div className="next__previous-buttons">
                            <div className="d-flex">
                              <Button
                                onClick={handleBack}
                                variant="outlined"
                                size="medium"
                                className="outline-btn"
                                sx={{
                                  color: "#000",
                                  marginRight: "15px",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                onClick={handleNext}
                                className="next-button"
                                variant="contained"
                                size="medium"
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#00366D",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Next
                              </Button>
                            </div>
                            <Button
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                position: "fixed",
                                right: "10px",
                                width: "120px",
                                float: "right",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeStep === 2 && (
                      <div>
                        <div className="steps__containt-hader">
                          <h3 className="title">Create connection</h3>
                          <p>Upload the excel sheet with the data for the selected ESG parameter</p>
                        </div>
                        <hr />
                        <div className="steps__container">
                          {/* <h6 className='form-label'>Import File</h6> */}
                          <div style={{ color: "#1E4B7A" }}>
                            {importfile ? <p>{importfile} Uploaded successfully</p> : null}
                          </div>
                          <Dropzone onDrop={(acceptedFiles) => onDrops(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone__section">
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="dropzone">
                                    <label for="files" class="dropzone-container">
                                      <div className="file-icon">
                                        <CloudUploadTwoToneIcon
                                          sx={{ color: "#1E4B7A", fontSize: "55px" }}
                                        />
                                      </div>
                                      <div className="dropzone-title">
                                        <span className="browse">Browse</span> or drag and drop
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div className="next__previous-buttons">
                            <div className="d-flex">
                              <Button
                                onClick={handleBack}
                                variant="outlined"
                                size="medium"
                                className="outline-btn"
                                sx={{
                                  color: "#000",
                                  marginRight: "15px",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                onClick={handleNext}
                                className="next-button"
                                variant="contained"
                                size="medium"
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#00366D",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                                disabled={!importfile}
                              >
                                Next
                              </Button>
                            </div>
                            <Button
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                position: "fixed",
                                right: "10px",
                                width: "120px",
                                float: "right",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeStep === 3 && (
                      <div className="table__section">
                        <div className="data__table">
                          <div className="steps__containt-hader">
                            <h3 className="title">Map the fields</h3>
                            <p>
                              Map the fields from the data source to the attributes of the standard
                              Ecoprism data requirement.
                            </p>
                          </div>
                          <hr />
                          <div className="steps__container">
                            <div className="table-responsive">
                              <table className="table custom-table ">
                                <thead>
                                  <tr>
                                    <th scope="col">Data field</th>
                                    <th scope="col">Field in Data source</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(excelfields).map((keyName, i) => (
                                    <tr>
                                      <td>
                                        <div class="form-group col-md-12">
                                          <select
                                            id="inputState"
                                            class="Ecodropdown"
                                            onChange={(e) => excelH(e.target.value, i)}
                                            name="excelHeaderss[]"
                                          >
                                            <option value="None">None</option>
                                            {headerfields.map((header) => (
                                              <option value={header}>{header}</option>
                                            ))}
                                          </select>
                                          <input
                                            type="hidden"
                                            id={"excel" + i}
                                            value="None"
                                            name="excelHeaders[]"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {keyName}
                                        <input type="hidden" value={keyName} name="destination[]" />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* <h5>Result</h5>
                              <p>co2e : {result.co2e} {result.co2eUnit}</p>
                              <p> co2e Calculation Method : {result.co2eCalculationMethod}</p> */}
                        </div>
                        <div className="next__previous-buttons">
                          <div className="d-flex">
                            <Button
                              onClick={handleBack}
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                width: "120px",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              onClick={handleNext}
                              className="next-button"
                              variant="contained"
                              size="medium"
                              sx={{
                                color: "#fff",
                                backgroundColor: "#00366D",
                                width: "120px",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Next
                            </Button>
                          </div>
                          <div class="ab">
                            <Button
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                right: "10px",
                                width: "120px",
                                float: "right",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 4 && (
                      <div>
                        <div className="steps__containt-hader">
                          <h3 className="title">Review data connection</h3>
                          <p>
                            Review your data connection ensuring seamless integration and optimal
                            performance
                          </p>
                        </div>
                        <hr />
                        <div className="steps__container">
                          <div class="col-md-3"></div>
                          <div class="finish">
                            <p>ESG parameter : {mymodule}</p>
                            <p>Site : {sitelocation1}</p>
                            <p>Data connector : {selectedExcel}</p>
                            <p>Owner : {username}</p>
                          </div>
                          <div class="col-md-3"></div>
                          <div className="next__previous-buttons">
                            <div className="d-flex">
                              <Button
                                onClick={handleBack}
                                variant="outlined"
                                size="medium"
                                className="outline-btn"
                                sx={{
                                  color: "#000",
                                  marginRight: "15px",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                onClick={handleSubmit}
                                variant="contained"
                                size="medium"
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#00366D",
                                  width: "120px",
                                  height: "41px",
                                  borderRadius: "10px",
                                }}
                              >
                                Save
                              </Button>
                            </div>
                            <Button
                              variant="outlined"
                              size="medium"
                              className="outline-btn"
                              sx={{
                                color: "#000",
                                marginRight: "15px",
                                position: "fixed",
                                right: "10px",
                                width: "120px",
                                float: "right",
                                height: "41px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 5 && (
                      <div>
                        <div className="steps__containt-hader">
                          <h3 className="title">Data connection</h3>
                          <p>
                            The connection has been established and is now accessible in the
                            configuration list
                          </p>
                        </div>
                        <hr />
                        <div className="steps__container">
                          <div class="col-md-3"></div>
                          <div class="finish1">
                            <p>Connection created successfully</p>
                            {activeStep === steps.length && (
                              <Paper square elevation={0} sx={{ p: 3 }}>
                                {/* <Typography>All steps completed - you&apos;re finished</Typography> */}
                                <Button
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "#00366D",
                                    width: "120px",
                                    height: "41px",
                                    borderRadius: "10px",
                                  }}
                                  onClick={handleReset}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <Link to="/allconfigrations" className="btn btn-primary">
                                    All configurations
                                  </Link>
                                </Button>
                              </Paper>
                            )}
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <div class="container">{message}</div>
        </div>
      </div>
    </div>
  );
}
